import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

SubTitle.propTypes = {
    children : PropTypes.string
}

// style定義
const StyleSubTitle = styled.div`
    text-align: right;
    margin-top:0.25rem;
    font-size: 14px;
    letter-spacing: 0.05em;
`

/**
 * サブタイトル
 * @param {string} children サブタイトルのテキスト 
 * @returns {JSX.Element}
 */
export default function SubTitle(props){
    // 引数を取得
    const {children} = props
    return (
        <StyleSubTitle>{children}</StyleSubTitle>
    )
}
