import React from 'react'

import { StyleSelecter, StyleMenuItem } from '../../style/select'

/**
 * プルダウン
 * @param {array} menuArray メニューリスト
 * @param {string} menu 選択された値
 * @param {func} updateMenu プルダウンの値が変更されたときに状態を更新する関数
 * @returns {JSX.Element}
 */
export default function Selecter(props){

    // プルダウンが変更されたときに発火する関数
    const handleChange = (event) => {
        props.updateMenu(props.menuArray.find(p => p.category === event.target.value))
        // props.updateMenu(event.target.value)
    }

    return(
        <StyleSelecter
            MenuProps={{
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: "left"
                },            
                PaperProps: {
                  sx: {
                    backgroundColor: "#152451",
                  }
                }
              }}
            value={props.menu}
            name='index'
            inputProps={{ 'aria-label' : 'age' }}
            onChange={handleChange}
        >
            {props.menuArray.map((elem, i) =>
                <StyleMenuItem value={elem.category}>{elem.name}</StyleMenuItem>
            )}
        </StyleSelecter>
    )
}
