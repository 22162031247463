import React from "react";
import styled, { keyframes } from 'styled-components'
import axios from 'axios';
import { HoverChip } from '../../style/chip.js'
import { BoxFlexStart, BoxCenterStart } from '../../style/box.js'
import { SubmitButton } from '../../style/button.js'
import { TypographyNomal } from '../../style/typography.js'
import { GetDataIntervalFiveMin } from '../../func/get_data_swr.js';
import { ARC_DISASTER_STATUS } from '../../const/general';
import { config } from '../../config.jsx'

import ErrorMsg from '../../atoms/errormsg/index.jsx'
import {
    dateToStrYYYYMMDD,
    dateToStrHHMM,
    dateToYYYYMMDDHHMM,
    utcDateToStrYYYYMMDDHHmmss,
    descTimeSort
} from '../../func/datetoview.jsx'
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent, {
    timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';

import TimelineDot from '@mui/lab/TimelineDot';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

import { Link } from 'react-router-dom'

/**
 * アーカイブした災害のチェックポイントタイムライン
 * @returns {JSX.Element}
 */
export default function ArchiveTimeLine(props) {

    // 災害アーカイブチェックポイント取得(災害ID指定)
    const url = `/get_disaster_checkpoints/?disaster_id=${props.selectedDisasterId}`
    const { data, loading, error } = GetDataIntervalFiveMin(url);
    
    // 災害アーカイブ完了処理
    const onClickDone = async () => {
        const now = new Date();
        const utc = new Date(
            Date.UTC(now.getUTCFullYear(),
                now.getUTCMonth(), now.getUTCDate(),
                now.getUTCHours(), now.getUTCMinutes(),
                now.getUTCSeconds(), now.getUTCMilliseconds()));

        console.log(utc)
        let data = {}
        data.disaster_ended_at = utc
        data.archive_until = utc
        data.archive_progress = ARC_DISASTER_STATUS.DONE
        data.updated_at = utc

        console.log(data)

        axios.patch(`${config.restUrl}/disaster/${props.selectedIndex}/`, data)
            .then(response => {
                console.log(response);
                props.setSelectedDisasterStatus(ARC_DISASTER_STATUS.DONE)
                props.reload()
            })
            .catch(errors => {
                console.log(errors)
            });
    }
    if (loading) {
        return (
            <Wrapper>
                {/* <TitleWrapper>直近のアーカイブ一覧</TitleWrapper> */}
                {/* {[...Array(5)].map((e, i) =>
                    <DisasterWrapper key={`disaster-list-wrapper-${i}`}>
                        <DisasterHead key={`disaster-list-head-${i}`}>
                            <DisasterIcons key={`disaster-list-icon-${i}`} />
                            <DisasterPrefName key={`disaster-list-pref-name-${i}`}>都道府県名</DisasterPrefName>
                        </DisasterHead>
                        <LoadingDisasterTitle key={`disaster-list-title-${i}`}>読み込み中...</LoadingDisasterTitle>
                        <DisasterDate key={`disaster-list-period-${i}`}>期間：</DisasterDate>
                        <DisasterDate key={`disaster-list-update-date-${i}`}>最終更新日：</DisasterDate>
                    </DisasterWrapper>
                )} */}
            </Wrapper>
        )
    }
    else if (error) {
        return (
            <Wrapper>
                <ErrorMsg msg={'データがありませんでした'} />
            </Wrapper>
        )
    }
    console.log(data)

    let msg = ''
    if (data.length < 1) {
        msg = '災害アーカイブのチェックポイントはまだ作成されていません'
    } else {
        if (props.selectedDisasterStatus < ARC_DISASTER_STATUS.DONE) {
            // 記録進行中の場合
            msg = `${dateToYYYYMMDDHHMM(data[0].checkpoint_date)} より災害継続中です`
        } else {
            // 記録完了の場合
            msg = `${dateToYYYYMMDDHHMM(data[0].checkpoint_date)} 
            から ${dateToYYYYMMDDHHMM(data[data.length - 1].checkpoint_date)} 
            まで記録しました`
        }
    }

    if (data.length === 0) {
        return (
            <BoxCenterStart>
                <TypographyNomal>{msg}</TypographyNomal>
            </BoxCenterStart>
        )
    }

    function recentList() {
        let objList = []
        let obj = {}
        let array = []
        for (let i = 0; i < data.length; i++) {
            const checkpoint_org = data[i].checkpoint_date
            const checkpoint = utcDateToStrYYYYMMDDHHmmss(data[i].checkpoint_date)
            const str_checkpoint_date = dateToStrYYYYMMDD(data[i].checkpoint_date)
            const str_checkpoint_time = dateToStrHHMM(data[i].checkpoint_date)

            if (i === 0) {
                array.push({ 'checkpoint': checkpoint, 'checkpoint_time': str_checkpoint_time })
                obj = { 'checkpoint_org': checkpoint_org, 'checkpoint_date': str_checkpoint_date, 'time_list': array }
            }

            if (i !== 0 &&
                str_checkpoint_date !== dateToStrYYYYMMDD(data[i - 1].checkpoint_date)) {
                // 1番目の要素ではなく、日付が変わった場合
                obj.time_list = array
                objList.push(obj)

                obj = {}
                array = []
                array.push({ 'checkpoint': checkpoint, 'checkpoint_time': str_checkpoint_time })
                obj = { 'checkpoint_org': checkpoint_org, 'checkpoint_date': str_checkpoint_date, 'time_list': array }
            }
            if (i !== 0
                && str_checkpoint_date === dateToStrYYYYMMDD(data[i - 1].checkpoint_date)) {
                // 1番目の要素ではなく、日付が変わっていない場合
                array.push({ 'checkpoint': checkpoint, 'checkpoint_time': str_checkpoint_time })
            }
            if (i === data.length - 1) {
                obj.time_list = array
                objList.push(obj)
            }
        }
        const sortedObjList = objList.sort((a, b) => descTimeSort(a, b))
        return sortedObjList
    }
    return (
        <Timeline sx={{ overflowY: 'auto', display: 'block' }}>
            <BoxCenterStart>
                <TypographyNomal>{msg}</TypographyNomal>
                {props.selectedDisasterStatus < ARC_DISASTER_STATUS.DONE &&
                    // 記録進行中の場合
                    <SubmitButton sx={{ ml: 2 }}
                    onClick={onClickDone}>
                        終了
                    </SubmitButton>
                }
            </BoxCenterStart>
            {recentList().map((e, i) => (
                <>
                    <TimelineItem
                        sx={{
                            [`& .${timelineOppositeContentClasses.root}`]: {
                                flex: 0.3,
                            },
                        }}
                    >
                        <TimelineOppositeContent
                            sx={{ m: '27px auto 0 0', minHeight: '50px' }}
                            color="white">
                            {e.checkpoint_date}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <TimelineConnector />
                            <TimelineDot color="primary" sx={{ margin: '6px 0px' }}>
                                <CalendarTodayIcon />
                            </TimelineDot>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent
                            sx={{ m: '25px auto 0 0' }}>
                            <BoxFlexStart>
                                {e.time_list.map((f) => (
                                    <Link to={`/archive/${data[0].disaster_id}/${f.checkpoint}`}>
                                        <HoverChip label={f.checkpoint_time} />
                                    </Link>
                                ))}
                            </BoxFlexStart>
                        </TimelineContent>
                    </TimelineItem>
                </>
            ))}
        </Timeline>
    )
}

// 災害一覧ラッパー ///////////////////////////////////////////////////
const LoadingAnimation = keyframes`
    to {
        clip-path: inset(0 -0.5ch 0 0)
    }
`
const Wrapper = styled.div`
    margin: 5vh 20vw;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
`
