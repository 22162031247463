import React from 'react'
import styled from 'styled-components'
// contents component
import Login from '../../contents/login'

/**
 * ヘッダーなし画面テンプレート
 * @returns {JSX.Element}
 */
export default function NoHeader(props) {

    return (
        <Wrapper>
            <Login
                setPrfCode={props.setPrfCode}
                // TODO　後日削除予定
                // EDiSONからの遷移の場合、ログイン時の遷移先をアーカイブに固定するための仮の対応
                isFromEdison={props.isFromEdison}
            />
        </Wrapper>
    )
}

const Wrapper = styled.div`
    width: 100vw;
    height: 100dvh;
    display: flex;
    flex-direction: column;
    justify-content: center;
`

