import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import axios from 'axios'
import useSWR from 'swr'
import { config } from '../../config.jsx'

// Contents Component
import MstShelterList from '../../contents/mstshelterlist'
import ShelterGraph from '../../contents/sheltergraph'

// Atom Component
import ErrorMsg from '../../atoms/errormsg'

// Item Component
import Meter from '../../items/meter'
import LoadingMeter from '../../items/loadingmeter'

// function
import { JSTToMMDDHHmm } from '../../func/datetoview'

ShelterBoard.propTypes = {
    // 選択中の市町村コード
    ctvCode: PropTypes.number,
    // 選択中の市町村名
    ctvName: PropTypes.string
}

const fetcher = url => axios.get(url).then(res => res.data)


/**
 * 避難所画面を構成するコンポーネント
 * @param {number} CtvCode 市町村コード
 * @param {string} ctvName 選択中の市町村名
 * @returns {JSX.Element}
 */
export default function ShelterBoard(props){
    const {ctvCode, ctvName} = props

    // 選択中の避難所名
    const [shelterName, setShelterName] = useState('')
    // 選択中の避難所修理
    const [shelterType, setShelterType] = useState('')

    const { data, error } = useSWR(
        () => ctvCode !== 0 && ctvCode &&
            `${config.restUrl}/pref/shelter/ctv/sum/${ctvCode}/`,
            fetcher,
            {
                onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
                    // 再試行は5回まで
                    if(retryCount >= 2) return
                    // 5秒後に再試行
                    setTimeout(() => revalidate({ retryCount : retryCount + 1}), 50000)
                },
            refreshInterval: 300000
            }
    )

    function updateChooseShelter(name, type){
        setShelterName(name)
        setShelterType(type)
    }

    if(ctvCode === 0 && ctvName === 'NotFound'){
        return(
        <ErrorMsg msg={'現在，開設中の避難所はありません'} />
        )
    }

    const isLoading = !data && !error

    if(isLoading){
        return(
        <React.Fragment>
            <ListWrapper>
                <LoadingMeter key={`loading-mtr-ctv`} />
                <MstShelterList
                    ctvCode={ctvCode}
                    ctvName={ctvName}
                    shelterName={shelterName}
                    shelterType={shelterType}
                    updateChooseShelter={updateChooseShelter}
                />
            </ListWrapper>
            <GraphWrapper>
                <ShelterGraph
                    shelterName={shelterName}
                    shelterType={shelterType}
                />
            </GraphWrapper>
        </React.Fragment>
        )
    }

    if(error){
        return(
        <React.Fragment>
            <ListWrapper>
                <MstShelterList
                    ctvCode={ctvCode}
                    ctvName={ctvName}
                    shelterName={shelterName}
                    shelterType={shelterType}
                    updateChooseShelter={updateChooseShelter}
                />
            </ListWrapper>
            <GraphWrapper>
                <ShelterGraph
                    shelterName={shelterName}
                    shelterType={shelterType}
                />
            </GraphWrapper>
        </React.Fragment>
        )
    }

    return(
        <React.Fragment>
            <ListWrapper>
                <Meter
                    key={'shelter-mtr-ctv-mst'}
                    headtitle={`${ctvName}合計避難者数`}
                    subtitle={data.report_date ? JSTToMMDDHHmm(data.report_date) : ''}
                    value={data.hdcnt_num && data.hhs_num ? `${data.hdcnt_num}人 - ${data.hhs_num}世帯` : '避難者なし'}
                    colorclass={''}
                    is_btn={false}
                    isChoosed={false}
                    unit={''}
                    changes={data.change_flag}
                    />
                    <MstShelterList
                    ctvCode={ctvCode}
                    ctvName={ctvName}
                    shelterName={shelterName}
                    shelterType={shelterType}
                    updateChooseShelter={updateChooseShelter}
                />
            </ListWrapper>
            <GraphWrapper>
                <ShelterGraph
                    shelterName={shelterName}
                    shelterType={shelterType}
                />
            </GraphWrapper>
        </React.Fragment>
    )
}

const ListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 50vw;
    padding-left: 5vw;
    padding-right: 1rem;
    margin: 0 0.5rem 0.5rem 0.5rem;
    overflow-y: scroll;
    height: 100%
`

// グラフラッパー
const GraphWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 50vw;
    padding-right: 5vw;
    padding-left: 1rem;
    margin: 0.5rem 0;
    height: auto;
    overflow-y: scroll;
`
