import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'
import { config } from '../../config.jsx'

// Contents Component
import PrfSelector from '../prfselecter/index.jsx'

// Atomic Components
import DateClock from '../../atoms/dateclock/index.jsx'
import LinkBtn from '../../atoms/linkbtn/index.jsx'
import StatusBadge from '../../atoms/statusbadge/index.jsx'
import { TypographyNomal } from '../../style/typography.js';
import { Stack } from '@mui/material'

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    height: 48px;
    width: 90vw;
    padding: 0 5vw;
    border-bottom: thin ridge #15335f;
    justify-content: space-between;
    background-color: ${(props) => props.backColor};
`
const BtnWrapper = styled.div`
    display: flex;
`

// メニューボタンリスト
const btnArray = [
    {
        category: 'archive',
        text: 'アーカイブ',
        url: '/archive',
        isOpen: true,
    }
]

// 大分県領域 funcType : prefにアクセスできるIDリスト
const ACCESSIBLE_ID = config['accessibleId']

/**
 * ヘッダー
 * @param {int} prfCode 都道府県コード
 * @param {func} setPrfCode 選択した都道府県コードを更新する関数 
 * @returns {JSX.Element}
 */
export default function FromEdisonHeader(props) {
    const location = useLocation()

    useEffect(() => {
        props.setIsFromEdison(true)
    }, [])

    const header = (isLoading, isDisasterRespound) => (
        <Wrapper
            backColor={isDisasterRespound ? '#c00000cc' : '#061027'}
        >
            <BtnWrapper>
                {
                    btnArray.map((elem, i) => (
                        (isPrefUser || elem.isOpen) &&
                        <LinkBtn
                            key={i.toString()}
                            text={elem.text}
                            url=''
                            isChoosed={true}
                            choosedBackColod={'#223C6D'}
                        />
                    ))
                }
                {/* <LinkBtn
                    key={'logout'}
                    text={'ログアウト'}
                    url={'/login'}
                    isChoosed={false}
                    choosedBackColod={'#223C6D'}
                /> */}
            </BtnWrapper>
            {/* {
                !isLoading &&
                <StatusBadge
                    text={isDisasterRespound ? '災害対応中' : '平常運用中'}
                    color={'white'}
                    size={'20px'}
                />
            }
            {/* <PrfSelector prfCode={props.prfCode} setPrfCode={props.setPrfCode} /> */}
            {/* <DateClock /> */}
            {/* {props.lastUpdateTime !== null && props.lastUpdateTime !== '' &&
                <Stack>
                    < TypographyNomal >最終更新日時</TypographyNomal>
                    < TypographyNomal >{props.lastUpdateTime}</TypographyNomal>
                </Stack>
            }  */}
        </Wrapper >
    )

    // // 大塚　以下のコメントアウトはEDiSONと統合する際に有効になります。削除しないでください。
    // ユーザー情報取得
    const userName = JSON.parse(localStorage.getItem('user'))
    const isPrefUser = userName ? ACCESSIBLE_ID.includes(userName['id']) : false
    // if (isPrefUser) {
    //     const { data, loading, error } = GetDataIntervalFiveMin(`/api/pref/is/disaster/respound/`);

    //     const isLoading = !data && !error
    //     if (isLoading || error) {
    //         return header(isLoading, false)
    //     }

    //     const isDisasterRespound = data.results
    //     return header(false, isDisasterRespound)
    // } else {
    return header(true, false)
    // }
}
