import React from 'react'

// Item component
import LongSliders from '../../items/longsliders'

/**
 * 雨雲や雨量のスライダー
 * @param {func} setChooseDate 日付を変更する関数
 * @param {Array} data スライダーに表示する日時リスト
 * @returns {JSX.Element}
 */
export default function MeterSlider(props){

    // データリスト
    const dataArray = props.data.results
    // 最新の更新時間
    const newestDate =  props.data.newest_date
    // フィルターするキーリスト
    const keyArray = dataArray.map((elem) => elem.dbdate) 
    // 日付リスト
    const dateArray = dataArray.map((elem) => elem.date)
    // 時間リスト
    const timeArray = dataArray.map((elem) => elem.time) 

    // 初期設定時間
    const chooseFirstNum = dataArray.findIndex((elem) => elem.dbdate === newestDate)

    // console.log('result_date_data')
    // console.log(result_date_data)

    // console.log('dataArray')
    // console.log(dataArray)
    // console.log('newestDate')
    // console.log(newestDate)
    // console.log('keyArray')
    // console.log(keyArray)
    // console.log('dateArray')
    // console.log(dateArray)
    // console.log('timeArray')
    // console.log(timeArray)
    // console.log('chooseFirstNum')
    // console.log(chooseFirstNum)

    return(
        <React.Fragment>
            <LongSliders
                chooseFirstNum={chooseFirstNum}
                chooseDate={newestDate}
                intervals={500}
                dateArray={dateArray}
                timeArray={timeArray}
                keyArray={keyArray}
                setChooseDate={props.setChooseDate}
            />
        </React.Fragment>
    )
}

