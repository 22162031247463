const MapLegendData = {
    h1_msh : {
      title : '1時間雨量[mm/h]',
      labels: [
        {"color":"#b40069","text":"80~"},
        {"color":"#ff2600","text":"50~80"},
        {"color":"#ff9900","text":"30~50"},
        {"color":"#faf600","text":"20~30"},
        {"color":"#0040ff","text":"10~20"},
        {"color":"#218cff","text":"5~10"},
        {"color":"#a0d3ff","text":"1~5"},
        {"color":"#f2f2ff","text":"0~1"},
        {"color": "#ff2600", "text": "クロノロジー", "radius": "50%", "border": "thin ridge white"},
      ],
    },
    h24_msh : {
      title: '24時間雨量[mm]',
      labels: [
        {"color":"#b40069","text":"300~"},
        {"color":"#ff2600","text":"250~300"},
        {"color":"#ff9900","text":"200~250"},
        {"color":"#faf600","text":"150~200"},
        {"color":"#0040ff","text":"100~150"},
        {"color":"#218cff","text":"80~100"},
        {"color":"#a0d3ff","text":"50~80"},
        {"color":"#f2f2ff","text":"1~50"},
        {"color": "#ff2600", "text": "クロノロジー", "radius": "50%", "border": "thin ridge white"},
      ]
    },
    h48_msh : {
      title : '48時間雨量[mm]',
      labels: [
        {"color":"#b40069","text":"400~"},
        {"color":"#ff2600","text":"300~400"},
        {"color":"#ff9900","text":"250~300"},
        {"color":"#faf600","text":"200~250"},
        {"color":"#0040ff","text":"150~200"},
        {"color":"#218cff","text":"100~150"},
        {"color":"#a0d3ff","text":"50~100"},
        {"color":"#f2f2ff","text":"1~50"},
        {"color": "#ff2600", "text": "クロノロジー", "radius": "50%", "border": "thin ridge white"},
      ]
    },
    sr_msh : {
      title : '土壌雨量指数',
      labels: [
        {"color":"#0a0412","text":"特別警報相当"},
        {"color":"#b40069","text":"土砂警相当"},
        {"color":"#ff2600","text":"警報相当"},
        {"color":"#faf600","text":"注意報相当"},
        {"color": "#ff2600", "text": "クロノロジー", "radius": "50%", "border": "thin ridge white"},
      ]
    },
    rsk_msh : {
      title : '土砂災害危険リスク',
      labels: [
        {"color":"#b40069","text":"レベル4"},
        {"color":"#ff2600","text":"レベル3"},
        {"color":"#ff9900","text":"レベル2"},
        {"color":"#faf600","text":"レベル1"},
        {"color": "#ff2600", "text": "クロノロジー", "radius": "50%", "border": "thin ridge white"},
      ]
    },
    rfs_msh : {
      title : '河川洪水発生リスク',
      labels: [
        {"color":"#b40069","text":"レベル4"},
        {"color":"#ff2600","text":"レベル3"},
        {"color":"#ff9900","text":"レベル2"},
        {"color":"#faf600","text":"レベル1"},
        {"color": "#ff2600", "text": "クロノロジー", "radius": "50%", "border": "thin ridge white"},
      ]
    },
    h1_bsn : {
      title : '1時間雨量[mm/h]',
      labels: [
        {"color":"#b40069","text":"80~"},
        {"color":"#ff2600","text":"50~80"},
        {"color":"#ff9900","text":"30~50"},
        {"color":"#faf600","text":"20~30"},
        {"color":"#0040ff","text":"10~20"},
        {"color":"#218cff","text":"5~10"},
        {"color":"#a0d3ff","text":"1~5"},
        {"color":"#f2f2ff","text":"0~1"},
        {"color": "#ff2600", "text": "クロノロジー", "radius": "50%", "border": "thin ridge white"},
      ],
    },
    h24_bsn : {
      title : '24時間雨量[mm]',
      labels: [
        {"color":"#b40069","text":"300~"},
        {"color":"#ff2600","text":"250~300"},
        {"color":"#ff9900","text":"200~250"},
        {"color":"#faf600","text":"150~200"},
        {"color":"#0040ff","text":"100~150"},
        {"color":"#218cff","text":"80~100"},
        {"color":"#a0d3ff","text":"50~80"},
        {"color":"#f2f2ff","text":"1~50"},
        {"color": "#ff2600", "text": "クロノロジー", "radius": "50%", "border": "thin ridge white"},
      ]
    },
    h48_bsn : {
      title : '48時間雨量[mm]',
      labels: [
        {"color":"#b40069","text":"400~"},
        {"color":"#ff2600","text":"300~400"},
        {"color":"#ff9900","text":"250~300"},
        {"color":"#faf600","text":"200~250"},
        {"color":"#0040ff","text":"150~200"},
        {"color":"#218cff","text":"100~150"},
        {"color":"#a0d3ff","text":"50~100"},
        {"color":"#f2f2ff","text":"1~50"},
        {"color": "#ff2600", "text": "クロノロジー", "radius": "50%", "border": "thin ridge white"},
      ]
    },
    of_bsn : {
      title : '流出量',
      labels: [
        {"color":"#b40069","text":"15~"},
        {"color":"#ff2600","text":"8~15"},
        {"color":"#ff9900","text":"5~8"},
        {"color":"#faf600","text":"2~5"},
        {"color":"#0040ff","text":"1~2"},
        {"color":"#a0d3ff","text":"0~1"},
        {"color": "#ff2600", "text": "クロノロジー", "radius": "50%", "border": "thin ridge white"},
      ]
    },
    tel : {
      title : '河川水位計',
      labels: [
        {'color':'#0a0412', 'text':'計画高', "radius": "50%", "border": "thin ridge white"},
        {'color':'#b40069', 'text':'氾濫危険', "radius": "50%", "border": "thin ridge white"},
        {'color':'#ff2600', 'text':'避難判断', "radius": "50%", "border": "thin ridge white"},
        {'color':'#faf600', 'text':'氾濫注意', "radius": "50%", "border": "thin ridge white"},
        {'color':'#3aab68', 'text':'水防団待機', "radius": "50%", "border": "thin ridge white"},
        {'color':'#40b2e8', 'text':'平常', "radius": "50%", "border": "thin ridge white"},
        {'color':'#f2f2ff', 'text':'基準なし', "radius": "50%", "border": "thin ridge white"},
        {'color':'#4d4d4e', 'text':'欠測', "radius": "50%", "border": "thin ridge white"},
      ]
    },
    ////// PREIN版はクロノロジーに対応していないのでコメントアウト
    ////// いずれEDiSONと統合するときのためにコードは残します。
    // p44_chr : {
    //   title : 'クロノロジー状況',
    //   labels: [
    //     {"color":"#faf600","text":"発生"},
    //     {"color":"#ff9900","text":""},
    //     {"color":"#ff2600","text":""},
    //     {"color":"#b40069","text":"多発"}
    //   ]
    // },
    shelter : {
      title : '避難所状況',
      labels: [
        {'color':'#b40069', 'text':'100%以上', "radius": "50%", "border": "thin ridge white"},
        {'color':'#ff2600', 'text':'75%以上', "radius": "50%", "border": "thin ridge white"},
        {'color':'#faf600', 'text':'50%以上', "radius": "50%", "border": "thin ridge white"},
        {'color':'#3aab68', 'text':'25%以上', "radius": "50%", "border": "thin ridge white"},
        {'color':'#40b2e8', 'text':'平常', "radius": "50%", "border": "thin ridge white"},
        {'color':'#f2f2ff', 'text':'上限なし', "radius": "50%", "border": "thin ridge white"},
        {'color':'#4d4d4e', 'text':'避難者なし', "radius": "50%", "border": "thin ridge white"},
        {'color':'#4d4d4e', 'text':'避難所閉鎖', "isCross" : true, "border": "thin ridge white"},
      ]
    }
  }
  
  /**
   * 凡例を取得
   * @param {string} category データのsy類
   * @returns {object} 凡例データ
   */
  export const getMapLegendData = (category) => {
    if(category){
      const labelsArray = MapLegendData[category].labels
      return {
          title: MapLegendData[category].title,
          labels: labelsArray
        }
    }
    return null
  }
  