import { Box,  } from '@mui/material';
import Select, { selectClasses } from '@mui/material/Select';

import { styled } from "@mui/system";

export const DropDown = styled(Select)(() => ({
    fontWeight: 'bold',
    fontSize: '18px',
    color:'white',
    cursor: 'pointer', 
    [`& .${selectClasses.root}`]: {
        backgroundColor: '0.8rem', width: '100%',
    },
  }));
export const HeaderDates = styled(Box)(({ theme }) => (
    {
        letterSpacing: '0.1rem',
        [theme.breakpoints.up("xs")]: {
            fontSize: '7px',
        },
        [theme.breakpoints.up("sm")]: {
            fontSize: '10px',
        },
        [theme.breakpoints.up("md")]: {
            fontSize: '14px',
        },
        [theme.breakpoints.up("lg")]: {
            fontSize: '16px',
        },
        fontVariantNumeric: 'tabular-nums',
    }));

export const HeaderStatusBadge = styled(Box)(({ theme }) => (
    {
        color: 'white',
        textDecoration: 'none',
        fontWeight: 'bold',
        [theme.breakpoints.up("xs")]: {
            fontSize: '7px',
        },
        [theme.breakpoints.up("sm")]: {
            fontSize: '10px',
        },
        [theme.breakpoints.up("md")]: {
            fontSize: '14px',
        },
        [theme.breakpoints.up("lg")]: {
            fontSize: '16px',
        },
        margin: '0.2rem',
        padding: '0.2rem',
        alignItems: 'center',
    }));

export const HeaderBtnBox = styled(Box)(({ theme }) => (
    {
        display: 'inline-block',
        height: '48px',

        '&&& a': {
            color: 'white',
            textDecoration: 'none',
            [theme.breakpoints.up("xs")]: {
                fontSize: '7px',
            },
            [theme.breakpoints.up("sm")]: {
                fontSize: '10px',
            },
            [theme.breakpoints.up("md")]: {
                fontSize: '14px',
            },
            [theme.breakpoints.up("lg")]: {
                fontSize: '16px',
            },
            letterSpacing: 'initial',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
            padding: '0 0.5rem'

        },
        '&:hover': {
            fontWeight: 'bold'
        }
    }));
export const ChoosedHeaderBtnBox = styled(HeaderBtnBox)(() => (
    {
        '&&& a': {
            fontWeight: 'bold',
            pointerEvents: 'none'
        },
    })); 