import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

// Original function
import { get_colorclass } from '../../func/getcolor'
import { GetDataIntervalFiveMin } from '../../func/get_data_swr';
import { dateToMMDDHHmm, dateToStrYYYYMMDDHHmmss } from '../../func/datetoview'

// Item Components
import Meter from '../../items/meter'
import Toggles from '../../items/toggles'
import LoadingMeter from '../../items/loadingmeter'

// Atom component
import ErrorMsg from '../../atoms/errormsg'
import Selecter from '../../atoms/selecter'
import { BASE_LAYER_LIST } from '../../const/general'

/**
 * 雨量ランキング
 * @param {func} updateCode 4次メッシュを更新する関数
 * @param {func} setLatlng 緯度経度を更新する関数
 * @param {func} updateChooseDate 日付を変更する関数
 * @param {int} prfCode 都道府県コード
 * @param {string} disasterId 災害ID
 * @param {string} basePointDate 基準時間
 * @param {bool} isArchive アーカイブか否か
 * @returns {JSX.Element}
 */
export default function InfiniteRank(props) {

    // 基準日を文字列に変換
    const base_point_date=dateToStrYYYYMMDDHHmmss(props.basePointDate)

    // ランキングを取得する
    let url = `/rain/rank/${props.selectCategory.category}/${props.prfCode}/${base_point_date}/`
    if (props.isArchive) {
        // 災害アーカイブテーブルの場合
        url = `/arc/rain/rank/${props.selectCategory.category}/${props.prfCode}/${base_point_date}/`
    }
    const { data, loading, error } = GetDataIntervalFiveMin(url);

    // 子コンポーネントで更新されたとき用関数
    const onclickUpdate = (param) => {

        // console.log(param)
        props.setSelectMshCode(param[0])
        props.setLatlng(param[1])
        props.setSelectBsnCode(param[3])
        // if (!param[3]) {
            props.updateChooseDate(param[2])
        // }
    }
    const HeadItem = () => (
        <TabWrapper>
            <Selecter
                menuArray={BASE_LAYER_LIST}
                menu={props.selectCategory.category}
                updateMenu={props.setSelectCategory}
            />
        </TabWrapper>
    );

    if (loading) {
        return (
            <React.Fragment>
                {HeadItem()}
                <RankWrapper>
                    {
                        [...Array(6)].map((elem, i) => (
                            <LoadingMeter key={`loading-mtr-${i}`} />
                        ))
                    }
                </RankWrapper>
            </React.Fragment>
        )
    }

    const isEmpty = data?.results.length === 0

    if (isEmpty || error) {
        return (
            <React.Fragment>
                {HeadItem()}
                <RankWrapper>
                    <ErrorMsg msg={'データが見つかりませんでした'} />
                </RankWrapper>
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            {HeadItem()}
            <RankWrapper>
                <div>
                    {data.results.map((elem, i) =>
                        <Meter
                            key={`ranks-${i}`}
                            headtitle={elem.txt_name}
                            subtitle={dateToMMDDHHmm(elem.dates)}
                            value={elem.value}
                            colorclass={
                                get_colorclass(
                                    props.selectCategory.category.split('_')[0] !== 'sr'
                                        ? elem.value
                                        : elem.level,
                                    props.selectCategory.category.split('_')[0] !== 'sr'
                                        ? props.selectCategory.category.split('_')[0] : 'sr_msh')}
                            is_btn={true}
                            unit={BASE_LAYER_LIST.find(element => element.category === props.selectCategory.category).unit}
                            updateStateFunc={onclickUpdate}
                            funcProps={[elem.msh_code, [elem.latitude, elem.longitude], elem.dates, elem.bsn_code]}
                            borderColor={elem.level == 4 ? 'bg_white' : null}
                        />
                    )}
                </div>
            </RankWrapper>
        </React.Fragment>
    )
}

// ランクのラッパー
const RankWrapper = styled.div`
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: scroll;
    min-height: 80dvh;
    &::-webkit-scrollbar{
        display: none;
    }
`
const TabWrapper = styled.div`
    margin: 0.25rem 0;
`
