import React from 'react'
import PropTypes from 'prop-types'

// Item component
import BarGraph from '../../items/bargraph'
import LineGraph from '../../items/linegraph'

// Atom component
import ErrorMsg from '../../atoms/errormsg'

// original function
import { getTickList } from '../../func/getticklist'
import { getGraphLine } from '../../func/getgraphline'
import { GetDataIntervalFiveMin } from '../../func/get_data_swr';
import { dateToStrYYYYMMDDHHmmss } from '../../func/datetoview'

GraphControl.propTypes = {
    // タブで選択している種類
    selectCategory: PropTypes.object,
    // 自分の種類，一致すればグラフを表示
    category: PropTypes.string,
    // 選択している場所の緯度経度
    latlng: PropTypes.array,
    // グラフ情報
    info: PropTypes.object,
    // 現在選択してる都道府県コード
    prfCode: PropTypes.number,
    // 災害ID
    disasterId: PropTypes.string,
    // チェックポイント
    basePointDate: PropTypes.string,
}

/**
 * グラフのプロット
 * @param {string} selectCategory タブで選択している値の種類
 * @param {string} category 値自身の種類
 * @param {array} latlng 緯度経度
 * @param {object} info グラフ情報
 * @param {int} prfCode 都道府県コード
 * @param {string} disasterId 災害ID
 * @param {string} basePointDate チェックポイント時間
 * @param {bool} isArchive アーカイブか否か
 * @returns {JSX.Element}
 */
function GraphPlot(props){
    const { latlng, info, prfCode, disasterId, basePointDate } = props

    // 基準日を文字列に変換
    const base_point_date = dateToStrYYYYMMDDHHmmss(basePointDate)

    // グラフデータを取得する
    let url = `/rain/msh/graph/${prfCode}/${info.category}/${props.msh_code}/${base_point_date}/`
    // TODO ${disaster_id}がある場合は別途考慮が必要
    if (props.isArchive) {
        // 災害アーカイブの場合
        url = `/arc/rain/msh/graph/${prfCode}/${info.category}/${props.msh_code}/${base_point_date}/`
    }
    console.log(url)
    const { data, loading, error } = GetDataIntervalFiveMin(url);

    if(loading){
        return(<React.Fragment />)
    }
    if(error){
        return(<ErrorMsg msg={'データが見つかりませんでした'} />)
    }

    // グラフの設定
    const stdLength = data.RainStd.length
    const maxMonthlyValue = stdLength !== 0 ? data.RainStd[stdLength-1] : null
    // 月間最多雨量があるならば，グラフの基準線を追加する
    const domains = info.category === 'sr_msh' ?
                getTickList(data.results, data.RainStd, maxMonthlyValue, false)
                : getTickList(data.results, info.ticks, maxMonthlyValue, false)
    const graphLines = info.category === 'sr_msh' && data.RainStd.length !== 0 ?
                getGraphLine(data.RainStd, ['注意報', '警報', '土砂警(最低)', '特別警報'], info.category, null, '', data.dates)
                : getGraphLine(info.ticks, info.tickLabel, info.category, maxMonthlyValue, '', data.dates)

    if(info.isUnit){
        return(
            <BarGraph
                category={info.category.split('_')[0]}
                data={data.results}
                domains={domains}
                unit={info.unit}
                xaxisKeyList={info.xaxisKeyList}
                dataKey={info.dataKey}
                lines={graphLines}
                lineWidth={3}
            />
        )
    }
    else{
        return(
            <LineGraph
                category={info.category.split('_')[0]}
                data={data.results}
                domains={domains}
                unit={info.unit}
                xaxisKeyList={info.xaxisKeyList}
                dataKey={info.dataKey}
                lines={graphLines}
                lineWidth={3}
                isYTicks={false}
            />
        )
    }
}


export default function GraphControl(props){
    const { selectCategory, category, latlng, info, prfCode, disasterId, basePointDate } = props

    return (
        <div
            role="tabpanel"
            hidden={selectCategory.category !== category}
            style={{height: 'calc(100% - 60px)'}}
            id={`wrapped-tabpanel-${category}`}
            aria-labelledby={`wrapped-tab-${category}`}
        >
            {selectCategory.category === category && (
                <GraphPlot
                    msh_code ={props.msh_code}
                    latlng={latlng}
                    info={info}
                    prfCode={prfCode}
                    disasterId={disasterId}
                    basePointDate={basePointDate}
                    isArchive={props.isArchive}
                    />
            )}
        </div>
    );
}
