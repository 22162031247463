import React from 'react'

import TopPage from '../../templates/toppage'

/**
 * トップ画面(気象警報)
 * @param {int} prfCode 都道府県コード
 * @param {func} setLastUpdateTime 最終更新日時を更新する関数 
 * @returns {JSX.Element} トップ画面テンプレート
 */
export default function Top(props) {

    return (
        <TopPage
            prfCode={props.prfCode}
            setLastUpdateTime={props.setLastUpdateTime}
            />
    )
}
