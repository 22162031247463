import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'

// Contents Component
import BsnBtn from '../../contents/bsnbtn'
import MsSliderView from '../../contents/mssliderview'

MsDashboard.propTypes = {
    // 水系コード
    bsnCode: PropTypes.number,
    // 水系名
    bsnName: PropTypes.string,
    // 水系情報を更新する
    updateBsnStatus: PropTypes.func,
    // 選択している都道府県コード
    prfCode: PropTypes.number,
}

const AppWrapper = styled.div`
    display: flex;
    width: 100vw;
    height: calc(100dvh - 48px);
    flex-direction: column;
`

/**
 * 河川水位スライダーテンプレート
 * @param {number} bsnCode 水系コード
 * @param {string} bsnName 水系名
 * @param {func} updateBsnStatus 水系情報を更新する関数
 * @param {int} prfCode 都道府県コード
 * @param {func} updatePrfCode 選択した都道府県コードを更新する関数 
 * @returns {JSX.Element}
 */
export default function MsDashboard(props) {
    const { bsnCode, bsnName, updateBsnStatus, prfCode } = props
    // 災害IDと都道府県コード，チェックポイント時間があればパラメータから受け取る
    const { disaster_id, checkpoint } = useParams()
    // 災害IDがあれば災害アーカイブと判定
    const isArchive = disaster_id !== undefined && disaster_id !== null
        && disaster_id !== '' && disaster_id !== 'recent'

    return (
        <AppWrapper>
            <BsnBtn
                bsnCode={bsnCode}
                updateBsnStatus={updateBsnStatus}
                prfCode={prfCode}
            />
            <MsSliderView
                bsnCode={bsnCode}
                bsnName={bsnName}
                prfCode={prfCode}
                checkpoint={checkpoint}
                setLastUpdateTime={props.setLastUpdateTime}
                isArchive={isArchive}
                />
        </AppWrapper>
    )
}
