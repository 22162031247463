import React from 'react'
import PropTypes from 'prop-types'

import EastIcon from '@mui/icons-material/East'
import NorthEastIcon from '@mui/icons-material/NorthEast'
import SouthEastIcon from '@mui/icons-material/SouthEast'
import RemoveIcon from '@mui/icons-material/Remove'


ChangeIcon.propTypes = {
    flag: PropTypes.number
}

/**
 * 量の変動を表す矢印
 * @param {number} flag 変動フラグ値 
 * @returns {JSX.Element}
 */
export default function ChangeIcon(props){
    const {flag} = props

    if(flag === 0) return(<SouthEastIcon fontSize='large' />)
    else if(flag === 1) return(<NorthEastIcon fontSize='large' />)
    else if(flag === 10) return(<EastIcon fontSize='large' />)
    else if(flag === 2 || flag === 3) return(<RemoveIcon fontSize='large' />)
    else return(<React.Fragment />)
}
