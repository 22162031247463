import React from 'react'
import styled from 'styled-components'

export const StyleTrack = styled.div`
    position: absolute;
    height: 10px;
    z-index: 1;
    margin-top: 0.85rem;
    background-color: #318AB7;
    border-radius: 5px;
    cursor: pointer;
    left: ${(props) => props.source.percent}%;
    width: ${(props) => (props.target.percent - props.source.percent)}%;
`

/**
 * スライダーのトラック
 * @param {number} source 
 * @param {number} target
 * @param {func} getTrackProps
 * @returns {JSX.Element}
 */
export default function Track({source, target, getTrackProps}){
    return (
        <StyleTrack
            source={source}
            target={target}
            {...getTrackProps()}
        />
    )
}
