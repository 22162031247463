import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

// Contents Component
import RiverGraph from '../rivergraph'
import RiverMap from '../rivermap'

import MtrInfo from '../../items/mtrinfo'

import { dateToStrYYYYMMDDHHmmss } from '../../func/datetoview'
import { GetDataIntervalFiveMin } from '../../func/get_data_swr';

TelMapView.propTypes = {
    // 選択している日付
    chooseDate: PropTypes.number,
    // 選択している都道府県コード
    prfCode: PropTypes.number,
    // 選択している都道府県の緯度経度
    latlng: PropTypes.array,
    // 災害ID
    disasterId: PropTypes.string,
    // チェックポイント時間
    basePointDate: PropTypes.string,
    // アーカイブフラグ
    isArchive: PropTypes.bool,
}

/**
 * 河川水位マップを構成するコンポーネント
 * @param {number} chooseDate 選択した日付
 * @param {int} prfCode 都道府県コード
 * @param {array} latlng 緯度経度
 * @param {string} disasterId 災害ID
 * @param {string} basePointDate チェックポイント時間
 * @param {bool} isArchive アーカイブか否か 
 * @returns {JSX.Element}
 */
export default function TelMapView(props){
    
    const { chooseDate, prfCode, latlng, disasterId='', basePointDate='', checkpoint='', isArchive=false } = props
    
    // 選択中の水位計のID
    const [ telCode, setTelCode ] = useState()
    // 選択中の水位計の種類
    const [ mtrCategory, setMtrCategory ] = useState('tel')

    // 基準日を文字列に変換
    const base_point_date = dateToStrYYYYMMDDHHmmss(basePointDate)
    // console.log('chooseDate')
    // console.log(chooseDate)

    // スライダーで選択した日時を文字列に変換
    const choose_date = dateToStrYYYYMMDDHHmmss(chooseDate)
    // console.log('choose_date')
    // console.log(choose_date)

    let url = `/wtl/map/${prfCode}/${choose_date}/` 
    if (props.isArchive) {
        // 災害アーカイブの場合
        url = `/arc/wtl/map/${prfCode}/${choose_date}/`
    }
    const { data, loading, error } = GetDataIntervalFiveMin(url);


    if(!telCode && isData){
        setTelCode(data.results[0].code)
        setMtrCategory('tel')
    }

    function updateChooseMtr(code, category){
        setTelCode(code)
        setMtrCategory(category)
    }


    if (loading) {
        return (
            <></>
        )
    }
    if (error) {
        return (
            <></>
        )
    }
    // console.log(data)
    const isData = !loading && !error
    const pointsData = isData && data.results.filter(elem => elem.tel_date === chooseDate)
    // console.log('data.results')
    // console.log(data.results)
    // console.log('pointsData')
    // console.log(pointsData)

    let selectedTelData = data.results.filter(elem => elem.tel_code === telCode)[0]

    // 水位計未選択時に先頭の水位計を自動で選択する
    // 最新のデータ内に選択中のTelCodeがあるかを判定して存在しなければ selectedTelData にdata.results[0]
    if (!telCode || !data.results.some(elem => elem.tel_code === telCode)) {
        updateChooseMtr(data.results[0].tel_code, 'tel')
        selectedTelData = data.results.filter(elem => elem.tel_code === data.results[0].tel_code)[0]
    }
    // console.log('selectedTelData2')
    // console.log(selectedTelData)


    // グラフの設定
    const mtrBorder = [
        selectedTelData.green_border,
        selectedTelData.yellow_border,
        selectedTelData.red_border,
        selectedTelData.purple_border,
        selectedTelData.max_border
    ]
    const mtrLabel = [
        '水防団待機',
        '氾濫注意',
        '避難判断',
        '氾濫危険',
        '計画高'
    ]

    return(
        <React.Fragment>
            <ListWrapper>
                <RiverMap
                    latlng={latlng}
                    chooseDate={chooseDate}
                    pointsData={pointsData}
                    firstZoomLebel={10}
                    updateChooseMtr={updateChooseMtr}
                />
            </ListWrapper>
            <GraphWrapper>
            <MtrInfo
                    mtrName={selectedTelData.tel_name}
                    basName={`${selectedTelData.river_system_name}水系`}
                    riverName={selectedTelData.river_name}
                    mtrDetail={selectedTelData.place_info}
                    mtrLat={selectedTelData.latitude}
                    mtrLng={selectedTelData.longitude}
                    mtrAddress={selectedTelData.address}
                    mtrDiffFlag={selectedTelData.diff_flag}
                    mtrValue={selectedTelData.act_value}
                    colorclass={selectedTelData.colorclass}
                    mtrBorder={mtrBorder}
                    mtrUnit={'m'}
                    mtrLabel={mtrLabel}
                    isEmptyMsg={'欠測'}
                    prfCode={prfCode}
                    disasterId={disasterId}
                    checkpoint={checkpoint}
                    isArchive={isArchive}
                />
            <RiverGraph
                    telCode={telCode}
                    mtrCategory={mtrCategory}
                    mtrBorder={mtrBorder}
                    mtrLabel={mtrLabel}
                    basePointDate={base_point_date}
                />
            </GraphWrapper>
        </React.Fragment>
    )

}


// マップラッパー
const ListWrapper = styled.div`
    display: flex;
    width: 50vw;
    height: 100%;
    padding-left: 5vw;
`

// グラフラッパー
const GraphWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 50vw;
    padding-right: 5vw;
    padding-left: 1rem;
    height: auto;
    overflow-y: scroll;
`
