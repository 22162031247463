import { Chip } from '@mui/material';
import styled from '@emotion/styled';

// 共通のチップのスタイル
// 角の丸さ指定
export const RadiusChipBase = styled(Chip)(() => ({
  borderRadius: 2,
  //overflow: 'hidden',
  textOverflow: 'ellipsis',
  //height:'22px'
}));



// 通常サイズのタグチップ
export const NomalChip = styled(RadiusChipBase)(() => ({
  backgroundColor: 'rgb(110, 110, 111)',
  color: 'white',
  fontSize: '1rem',
  margin: '0px 8px 5px 0px',
  height: '30px',
  '&:hover': {
    cursor: 'pointer'
  }
}));

export const HoverChip = styled(NomalChip)(() => ({

  '&:hover': {
    backgroundColor: '#acaebe',
    cursor: 'pointer'
  }
}));

// マップのタイムスライダーに表示するタグチップ
export const MapSliderChip = styled(RadiusChipBase)(() => ({
  backgroundColor: 'rgb(50, 99, 180)',
  color: 'white',
  fontSize: '0.8rem',
  fontWeight: 'bold',
  marginTop: '5px',
  height: '24px'
}));

// マップのタイムスライダーに表示するダッシュボードリンクボタン
export const MapSliderLincBtn = styled(MapSliderChip)(() => ({
  // backgroundColor: 'rgb(236, 204, 68)',
  backgroundColor: 'rgb(242, 225, 75)',
  color: 'rgb(24, 36, 78)',
  border: '1px solid rgb(206, 179, 60)',
  '&:hover': {
    backgroundColor: 'rgb(242, 225, 75, 0.7)',
    // backgroundColor: 'rgba(236, 204, 68, 0.7)',
    cursor: 'pointer'
  }
}));

