import {config} from '../../config.jsx';
import authHeader from '../_helpers/auth-header.jsx';
// import configureFakeBackend from '../_helpers/fake-backend.jsx';

export const userService = {
    login,
    logout,
    getAll
};

export default function login(username, password){

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type' : 'application/json'},
        body: JSON.stringify({username, password})
    };

    return fetch(`${config.apiUrl}/users/authenticate`, requestOptions)
        .then(handleResponse)
        .then(user => {
            // llogin successful if there's a user in the response
            if (user){
                // store user details and basic auth credentials in local storate
                // to keep user logged in between page refreshes
                user.authdata = window.btoa(username + ':' + password);
                localStorage.setItem('user', JSON.stringify(user));
            }

            return user;
        });
}

function logout(){
    // remove user from local storage to log user out
    localStorage.removeItem('user');
}

function getAll(){
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${config.apiUrl}/users`, requestOptions).then(handleResponse);
}

function handleResponse(response){
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if(!response.ok){
            if(response.status === 401){
                // auto logout if 401 response returned from api
                logout();
                // location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
