import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

import {
    MenuList,
    MenuItem,
    ListItemText,
    ListItemIcon,
    Skeleton,
    TextField,
    Stack,
    Box,
    Tooltip,
    Paper
} from "@mui/material";
import ArchiveIcon from '@mui/icons-material/Archive';
import HistoryIcon from '@mui/icons-material/History';
import LensIcon from '@mui/icons-material/Lens';

import { TypographySmallMargin, TypographySmallBlack } from '../../style/typography'
import { BoxFlexStart, BoxFlexStartTop } from '../../style/box'
import { SubmitButton } from '../../style/button'
import { StyleSelecter, ArcMenuItem } from '../../style/select'
import { GetData } from '../../func/get_data_swr';
import { config } from '../../config.jsx'
import { ARC_DISASTER_STATUS } from '../../const/general';

/**
 * アーカイブメニュー
 * @returns {JSX.Element}
 */
export default function ArchiveMenu(props) {

    const { register, handleSubmit, formState: { errors } } = useForm();
    const [open, setOpen] = useState(false);

    // 災害アーカイブ新規登録
    const onSubmit = async data => {
        const now = new Date();
        const utc = new Date(
            Date.UTC(now.getUTCFullYear(),
                now.getUTCMonth(), now.getUTCDate(),
                now.getUTCHours(), now.getUTCMinutes(),
                now.getUTCSeconds(), now.getUTCMilliseconds()));

        const utc_after_thirty = new Date(
            Date.UTC(now.getUTCFullYear(),
                now.getUTCMonth(), now.getUTCDate() + 30,
                now.getUTCHours(), now.getUTCMinutes(),
                now.getUTCSeconds(), now.getUTCMilliseconds()));

        console.log(utc)
        console.log(utc_after_thirty)

        data.system_id = 0
        data.disaster_id = uuidv4()
        data.disaster_kbn = ''
        data.pref_code = props.prfCode
        data.disaster_started_at = utc
        data.disaster_ended_at = null
        data.archive_from = utc
        data.archive_until = utc_after_thirty
        data.archive_progress = 0
        data.contract_id = 'faf5ce49-62a8-dcb6-b094-a726cd7347d2'
        data.created_at = utc
        data.updated_at = utc

        console.log(data)

        axios.post(`${config.restUrl}/disaster/`, data)
            .then(response => {
                console.log(response);
                props.reload();
            })
            .catch(errors => {
                console.log(errors)
            });
    }
    const handleMenuItemClick = (event, index, disaster, status) => {
        props.setSelectedIndex(index);
        props.setSelectedDisasterId(disaster);
        props.setSelectedDisasterStatus(status)
    };
    const defaultMenu = () => (
        <>
            <ArcMenuItem
                key={0}
                sx={{ margin: '8px 0px' }}
                selected={'recent' === props.selectedDisasterId}
                onClick={(event) => handleMenuItemClick(event, 0, 'recent')}>
                <ListItemIcon>
                    <HistoryIcon fontSize="medium" sx={{ color: 'white' }} />
                </ListItemIcon>
                <ListItemText sx={{ color: 'white', textAlign: 'left' }}>直近のアーカイブ</ListItemText>
            </ArcMenuItem>
            <TypographySmallMargin>
                アーカイブされた災害
            </TypographySmallMargin>
        </>
    );
    const archiveNotFound = () => (
        <ArcMenuItem sx={{ margin: '8px 16px' }}>
            <ListItemIcon>
                <ArchiveIcon fontSize='medium' sx={{ color: 'white' }} />
            </ListItemIcon>
            <ListItemText sx={{ color: 'white', textAlign: 'left' }}>データがありません</ListItemText>
        </ArcMenuItem>
    );

    // 災害アーカイブ一覧取得(都道府県コード指定)
    let url = `/get_disaster_list/?pref_code=${props.prfCode}`
    const { data, loading, error } = GetData(url);
    
    if (loading) {
        return (
            <MenuList >
                {defaultMenu()}
                <MenuItem sx={{ margin: '8px 16px' }}>
                    <ListItemIcon>
                        <Skeleton variant="circular" width={20} height={20} sx={{ bgcolor: 'grey.900' }} />
                    </ListItemIcon>
                    <ListItemText ><Skeleton sx={{ bgcolor: 'grey.900' }} /></ListItemText>
                </MenuItem>
            </MenuList>
        )
    }

    if (error) {
        return (
            <MenuList >
                {defaultMenu()}
                {archiveNotFound()}
            </MenuList>
        )
    }

    const viewData = data
    const onProcData = viewData.filter(e => { return e.archive_progress < ARC_DISASTER_STATUS.DONE;})
    console.log(viewData)
    return (
        <Box sx={{
            overflow: 'auto',
            height: 'calc(100dvh-48px)',
            // '& ::-webkit-scrollbar': {
            //     display: "none"
            // },
            // '& :hover': {
            // '::-webkit-scrollbar': {
            //     display: "none"
            // }

        }}>
            <MenuList >
                {defaultMenu()}
                {viewData.length === 0 ?
                    archiveNotFound()
                    :
                    viewData.map((e, i) =>
                        <ArcMenuItem
                            key={e.id}
                            sx={{ margin: '8px 0px', padding: '6px 16px 6px 28px' }}
                            selected={e.disaster_id === props.selectedDisasterId}
                            onClick={(event) => handleMenuItemClick(event, e.id, e.disaster_id, e.archive_progress)}>
                            <ListItemIcon>
                                <ArchiveIcon fontSize='medium' sx={{ color: 'white' }} />
                            </ListItemIcon>
                            <ListItemText sx={{ color: 'white', textAlign: 'left' }}>{e.disaster_name}</ListItemText>
                            {(e.archive_progress === 0 || e.archive_progress === 1) &&
                                <ListItemIcon>
                                    <Tooltip title='災害対応中' placement="right-end">
                                        <LensIcon fontSize='medium' sx={{ color: 'red' }} />
                                    </Tooltip>
                                </ListItemIcon>
                            }
                        </ArcMenuItem>
                    )}
            </MenuList>
            {/* 災害アーカイブ作成は大分県の限定機能として制限 */}
            {props.prfCode === 44 && onProcData.length < 1 &&
                <BoxFlexStart sx={{ margin: 2 }}>
                    <Stack >
                        <BoxFlexStart >
                            <SubmitButton
                                onClick={() => setOpen((prev) => !prev)}>
                                災害作成
                            </SubmitButton>
                        </BoxFlexStart>
                        {open &&
                            <Paper sx={{ m: 2, p: 2 }}>
                                <Stack spacing={1} >
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <BoxFlexStartTop >
                                            <TextField
                                                size='small' label='災害名'
                                                sx={{ mr: 1 }}
                                                {...register('disaster_name',
                                                    { required: '災害名を入力してください' })}
                                                helperText={errors.disaster_name?.message}
                                                error={errors.disaster_name?.message.length > 0}
                                            />
                                            <SubmitButton type="submit">
                                                作成
                                            </SubmitButton>
                                        </BoxFlexStartTop>
                                    </form>
                                    <TypographySmallBlack>{props.latestCheckPoint} より記録します</TypographySmallBlack>
                                </Stack>
                            </Paper>
                        }
                    </Stack>
                </BoxFlexStart>
            }
        </Box>
    )
}

