import React from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import useSWR from 'swr'
import { config } from '../../config.jsx'

import StatusBadge from '../../atoms/statusbadge'


PrfName.propTypes = {
    // 都道府県コード
    prfCode: PropTypes.number,
    // 追加の文言
    addString: PropTypes.string,
}
const fetcher = url => axios.get(url).then(res => res.data)

/**
 * ステータスの表示
 * @param {int} prfCode 都道府県コード
 * @param {string} addString
 * @returns {JSX.Element}
 */
export default function PrfName({prfCode, addString=''}){
    const {data, error} = useSWR(
        () => `${config.restUrl}/col/loc/prf/list/`,
        fetcher,
        {
            onErrorRetry: (error, key, config, revalidate, {retryCount}) => {
                if(retryCount >= 2) return
                    // 5秒後に再試行
                    setTimeout(() => revalidate({ retryCount: retryCount + 1}), 50000)
            }
        }
    )
    const isLoading = !data && !error

    if(isLoading){
        return(<StatusBadge text={'読み込み中'} color={'white'} size={'20px'} />)
    }
    if(error){
        return(<StatusBadge text={'データがありません'} color={'white'} size={'20px'} />)
    }
    return(
    // <StatusBadge text={`${data.results[prfCode-1].prf_name} - ${addString}`} color={'white'} size={'20px'} />
    <></>
)
}
