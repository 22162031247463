import React, { useState } from 'react'

// Template components
import ShelterDashboard from '../../templates/shelterdashboard'


/**
 * 避難所画面
 * @returns {JSX.Element} 避難所テンプレート
 */
export default function Shelter(props){

    // 市町村コード
    const [ctvCode, setCtvCode] = useState(null)
    // 市町村名
    const [ctvName, setCtvName] = useState(null)

    // // ユーザが選択する都道府県の設定(入れないと動かない)
    // // App.jsxに入れるとログイン前に遷移するため動かない
    // const userData = JSON.parse(localStorage.getItem('user'))
    // if(!props.prfCode || !userData['authorizedPref'].includes(props.prfCode)){
    //     props.updatePrfCode(userData['authorizedPref'][0])
    // }

    // 選択している市町村の避難所情報を更新する
    function updateCtvStatus(code, name){
        setCtvCode(code)
        setCtvName(name)
    }

    return(
        <ShelterDashboard
            ctvCode={ctvCode}
            ctvName={ctvName}
            updateCtvStatus={updateCtvStatus}
        />
    )

}
