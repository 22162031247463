import React from 'react'
import Typography from '@mui/material/Typography';

export default function TitleH6Left(props) {
    return (
        <Typography
            variant="h6"
            gutterBottom
            sx={{ color: 'white', 
                fontWeight: 'bold', 
                textAlign:'left', 
                paddingLeft:props.pl
            }}>
            {props.text}
        </Typography>
    )
}
