import moment from 'moment'

// 見せられる形に日付のstringを変更
/**
 * UTCをJSTに変更して日付のフォーマットを変更
 * @param {string} props 日付
 * @returns {string} 日付
 */
export const dateToMMDDHHmm = (props) => {
  if (!props) return ''
  return moment(props).add(9, 'hours').format('M月D日 H:mm')
}

/**
 * 日付のフォーマットを変更
 * @param {string} props 日付
 * @returns {string} 日付
 */
export const JSTToMMDDHHmm = (props) => {
  if (!props) return ''
  return moment(props).format('M月D日 H:mm')
}
export const ShortStrMMDDHHmm = (props) => {
  if (!props) return ''
  return moment(props).format('M/D H:mm')
}

/**
 * UTCをJSTにし，日付のフォーマットをグラフ用に変更
 * @param {string} props 日付
 * @returns {string} 日付
 */
export const dateToMMDDHHmmGraph = (props) => {
  if (!props) return ''
  return moment(props).add(9, 'hours').format('M/DD H:mm')
}

/**
 * UTCをJSTにし，日付のフォーマットをグラフ用に変更
 * @param {string} props 日付
 * @returns {string} 日付
 */
export const dateToMMDDHHmmSlider = (props) => {
  if (!props) return ''
  return moment(props).add(9, 'hours').format('M/DD H:mm')
}

// /**
//  * UTCをJSTにし，日付のフォーマットをスライダー用に変更
//  * @param {string} props 日付
//  * @returns {string} 日付
//  */
// export const dateToMMDDSlider = (props) => {
//   if (!props) return ''
//   return moment(props).add(9, 'hours').format('M/DD')
// }
// /**
//  * UTCをJSTにし，日付のフォーマットをスライダー用に変更
//  * @param {string} props 日付
//  * @returns {string} 日付
//  */
// export const dateToHHmmSlider = (props) => {
//   if (!props) return ''
//   return moment(props).add(9, 'hours').format('H:mm')
// }

/**
 * UTCをJSTにし，日付のフォーマットを変更
 * @param {string} props 日付
 * @returns {string} 日付
 */
export const dateToYYYYMMDD = (props) => {
  if (!props) return ''
  return moment(props).add(9, 'hours').format('YYYY年M月D日')
}

/**
 * 文字列を日付型に変換して返す
 * @param {string} props YYYYMMDDHHmm文字列
 * @returns {Date} 
 */
export const strYYYYMMDDHHmmToDate = (props) => {
  if (!props) return ''
  const date = new Date(
    parseInt(props.substring(0, 4)),
    parseInt(props.substring(4, 6)) - 1,
    parseInt(props.substring(6, 8)),
    parseInt(props.substring(8, 10)),
    parseInt(props.substring(10, 12)),
    parseInt(props.substring(12, 14)))
  return date
}

/**
 * JSTをUTCに変更し、パス形式に変換して返す
 * @param {string} props YYYYMMDDHHmm文字列
 * @returns {string} M月D日 H:mm
 */
export const strYYYYMMDDHHmmToMDHmm = (props) => {
  if (!props) return ''
  const date = strYYYYMMDDHHmmToDate(props)
  if (!date) return ''
  return dateToMMDDHHmm(date)
}

/**
 * UTCをJSTに変更し、パス形式に変換して返す
 * @param {string} props YYYYMMDDHHmm文字列
 * @returns {string} M月D日 H:mm
 */
export const strUTCYYYYMMDDHHmmToJSTYYYYMMDDHHmm = (props) => {
  if (!props) return ''
  const date = strYYYYMMDDHHmmToDate(props)
  if (!date) return ''
  return moment(date).add(9, 'hours').format('YYYYMMDDHHmmss')
}

/**
 * 文字列の日付に指定時間を加算したをUTC日付のパス形式に変換して返す
 * @param {string} str_date YYYYMMDDHHmm文字列
 * @param {int} hours 加算したい時間数
 * @returns {string} YYYY/MM/DD/HHmmのPath
 */
export const strAddHoursToPath = (date, hours) => {
  if (!date) return ''
  return moment(date).add(hours, 'hours').format('YYYY/MM/DD/HHmmss')
}

/**
 * 文字列の日付に指定時間を減算したをUTC日付のパス形式に変換して返す
 * @param {date} date 
 * @param {int} hours 減算したい時間数
 * @returns {string} YYYY/MM/DD/HHmmのPath
 */
export const strSubtractHoursToPath = (date, hours) => {
  if (!date) return ''
  return moment(date).subtract(hours, 'hours').format('YYYY/MM/DD/HHmmss')
}

/**
 * 日付のフォーマットを変更
 * @param {string} props 日付
 * @returns {string} 日付
 */
export const dateToYYYYMMDDHHMM = (props) => {
  if (!props) return ''
  return moment(props).format('YYYY年M月D日 HH:mm')
}

export const dateToYYYYMMDDHHMM4DashboardParam = (props) => {
  if (!props) return ''
  return moment(props).format('YYYY-MM-DD-HH:mm')
}

export const dateToStrYYYYMMDD = (props) => {
  if (!props) return ''
  return moment(props).format('YYYY年M月D日')
}
export const dateToStrHHMM = (props) => {
  if (!props) return ''
  return moment(props).format('HH:mm')
}
export const dateToStrYYYYMMDDHHmmss = (props) => {
  if (!props) return ''
  return moment(props).format('YYYYMMDDHHmmss')
}



export const utcDateToStrYYYYMMDDHHmmss = (props) => {
  if (!props) return ''
  return moment(props).subtract(9, 'hours').format('YYYYMMDDHHmmss')
}




export const descTimeSort = (a, b) => {
  return a < b ? 1 : -1;
}

export const compareDate = (date1, date2) => {
  // date1 が date2 よりも小さい（過去である）
  // 場合に true を返す
  const year1 = date1.getFullYear();
  const month1 = date1.getMonth() + 1;
  const day1 = date1.getDate();

  const year2 = date2.getFullYear();
  const month2 = date2.getMonth() + 1;
  const day2 = date2.getDate();

  if (year1 == year2) {
    if (month1 == month2) {
      if (day1 == day2) {
        return true
      }
      return day1 < day2;
    }
    else {
      return month1 < month2;
    }
  } else {
    return year1 < year2;
  }
}

export const getSliderdateArray = (date) => {
  let dateArray = []
  const formatStr = 'YYYY-MM-DDTHH:mm:ss'

  dateArray.push(moment(date).subtract(6, 'hours').format(formatStr))
  for (let i = 5; i > 0; i--) {
    dateArray.push(moment(date).subtract(i, 'hours').subtract(30, 'minutes').format(formatStr))
    dateArray.push(moment(date).subtract(i, 'hours').format(formatStr))
  }
  dateArray.push(moment(date).subtract(30, 'minutes').format(formatStr))
  dateArray.push(date)
  for (let j = 1; j < 16; j++) {
    dateArray.push(moment(date).add(j, 'hours').format(formatStr))
  }
  return dateArray
}