// {/*
//   ユーザーがログインしている場合はルートコンポーネントをレンダリング
//   そうでない場合はログインページにリダイレクト
// */}

import React from 'react'
import { Outlet, Navigate } from 'react-router-dom'
import { config } from '../../config.jsx'

// 大分県領域 funcType : prefにアクセスできるIDリスト
const ACCESSIBLE_ID = config['accessibleId']

export const PrivateRoute = (props) => {
  // ユーザー情報取得
  const userName = JSON.parse(localStorage.getItem('user'))
  if (userName) {
    const userId = userName['id']
    // コンポーネントの公開タイプを確認する
    const funcType = props.funcType
    if (funcType === 'pref' && !ACCESSIBLE_ID.includes(userId)) {
      return (<Navigate to={{ pathname: '/404' }} />)
    }
    return (<Outlet />)
  }
  // TODO　後日削除予定
  // EDiSONからの遷移の場合、ログイン時の遷移先をアーカイブに固定するための仮の対応
  if (props.target_path === '/from_edison/archive') {
    props.setIsFromEdison(true)
  }
  return (<Navigate to={{ pathname: '/login' }} />)
}
// export const PrivateRoute = ({ component: Component, ...rest }) => {
//   // ユーザー情報取得
//   const userName = JSON.parse(localStorage.getItem('user'))
//   if(userName){
//     const userId = userName['id']
//     // コンポーネントの公開タイプを確認する
//     const funcType = rest['funcType']
//     if(funcType === 'pref' && !ACCESSIBLE_ID.includes(userId)){
//       return(<Navigate to={{pathname: '/404'}} />)
//     }
//     return(<Outlet />)
//   }
//   return(<Navigate to={{pathname: '/login'}} />)
// }

// export const PrivateRoute = ({ component: Component, ...rest}) => (
//   <Route {...rest} render ={props => (
//     localStorage.getItem('user')
//       ? <Component {...props} />
//       : <Navigate to={{pathname: '/login', state: { from: props.location} }} />
//   )} />
// )
