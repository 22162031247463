// 大分
export const SERVICE_NAME = {
    EDISON: 'EDiSON',
    PREIN: 'PREIN',
}
// // 愛媛
// export const DEFAULT_POSITION = {
//   LAT: 33.506399,
//   LNG: 132.544520,
// }

// 大分
export const DEFAULT_POSITION = {
    LAT: 33.2305526,
    LNG: 131.6068285,
}

export const DEFAULT_MAP_ZOOM = 10

// 地図に表示するベクタータイルのリスト
// 1つめは必ず解析雨量レーダーとすること
export const BASE_LAYER_LIST = [
    {
        category: 'h1_msh',
        name: '1時間雨量',
        maxZoomSize: 12,
        is_river: false,
        order: 1,
        unit: 'mm/h',
        ticks: [20, 30, 50, 80],
        domains: [0, 80],
        xaxisKeyList: ['date'],
        dataKey: 'value',
        isUnit: true
    },
    {
        category: 'h24_msh',
        name: '24時間雨量',
        maxZoomSize: 12,
        is_river: false,
        order: 2,
        unit: 'mm',
        ticks: [150, 200, 250, 300],
        domains: [0, 300],
        xaxisKeyList: ['date'],
        dataKey: 'value',
        isUnit: true
    },
    {
        category: 'h48_msh',
        name: '48時間雨量',
        maxZoomSize: 12,
        is_river: false,
        order: 3,
        unit: 'mm',
        ticks: [200, 250, 300, 400],
        domains: [0, 400],
        xaxisKeyList: ['date'],
        dataKey: 'value',
        isUnit: true
    },
    {
        category: 'sr_msh',
        name: '土壌雨量指数',
        maxZoomSize: 12,
        is_river: false,
        order: 4,
        unit: '',
        ticks: [80, 120, 150, 200],
        domains: [0, 200],
        xaxisKeyList: ['date'],
        dataKey: 'value',
        isUnit: false
    },
    {
        category: 'rsk_msh',
        name: '土砂災害発生リスク',
        maxZoomSize: 12,
        is_river: false,
        order: 5,
        unit: '',
        ticks: [300, 600, 1000, 2000],
        tickLabel: ['レベル1', 'レベル2', 'レベル3', 'レベル4'],
        domains: [0, 200],
        xaxisKeyList: ['date'],
        dataKey: 'value',
        isUnit: false
    },
    // {
    //   category: 'rfs_msh',
    //   name: '河川洪水発生リスク',
    //   maxZoomSize: 12,
    //   is_river: false,
    //   ticks: [50, 75, 100, 150],
    //   tickLabel: ['レベル1', 'レベル2', 'レベル3', 'レベル4'],
    //   domains: [0, 200],
    //   xaxisKeyList: ['date'],
    //   dataKey: 'value',
    //   isUnit: false
    // },
    {
        category: 'h1_bsn',
        name: '流域平均1時間雨量',
        maxZoomSize: 12,
        is_river: true,
        order: 6,
        unit: 'mm/h',
        ticks: [20, 30, 50, 80],
        domains: [0, 80],
        xaxisKeyList: ['date'],
        dataKey: 'value',
        isUnit: true
    },
    {
        category: 'h24_bsn',
        name: '流域平均24時間雨量',
        maxZoomSize: 12,
        is_river: true,
        order: 7,
        unit: 'mm',
        ticks: [150, 200, 250, 300],
        domains: [0, 300],
        xaxisKeyList: ['date'],
        dataKey: 'value',
        isUnit: true
    },
    {
        category: 'h48_bsn',
        name: '流域平均48時間雨量',
        maxZoomSize: 12,
        is_river: true,
        order: 8,
        unit: 'mm',
        ticks: [200, 250, 300, 400],
        domains: [0, 400],
        xaxisKeyList: ['date'],
        dataKey: 'value',
        isUnit: true
    },
    {
        category: 'of_bsn',
        name: '流域平均流出量',
        maxZoomSize: 12,
        is_river: true,
        order: 9,
        unit: '',
        ticks: [2, 5, 8, 15],
        domains: [0, 15],
        xaxisKeyList: ['date'],
        dataKey: 'value',
        isUnit: false
    }
]

export const ARC_DISASTER_STATUS = {
    NEW: 0,
    PROGRESS: 1,
    DONE: 2,
    FAIL: 3,
}
export const DASHBOARD_URL = {
    REALTIME: 'https://prein.jp10.hcs.cloud.sap/sap/fpa/ui/tenants/f2cb5/app.html#/analyticapp?shellMode=embed&/aa/E85840046C5A9C8C7A649660C944BCFE/?p_time=',
    ARC_RECENT: 'https://prein.jp10.hcs.cloud.sap/sap/fpa/ui/tenants/f2cb5/app.html#/analyticapp?shellMode=embed&/aa/82598D0531E8A2E420AD08FCE54FABBD/?p_time=',
    ARC_DISASTER: 'https://prein.jp10.hcs.cloud.sap/sap/fpa/ui/tenants/f2cb5/app.html#/analyticapp?shellMode=embed&/aa/E6E9950531EA5527835225EF4F4FBD88/?p_time=2023-07-01T07:00:00&disaster_id=uuid',
}
