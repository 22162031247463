/*
    特定のAPIリクエストをインターセプトし実際のAPI動作を模倣するように
    機能にモンキーパッチを当てる。
    インターセプトされないリクエストはすべて、実際のfetch()関数に渡される。
*/

export default function configureFakeBackend() {
  let users = [
    {
      id: 1,
      username: 'edisonAdmin',
      password: 'Cerd2018',
      authorizedPref: [...Array(47).keys()].map((d) => d + 1),
    },
    {
      id: 2,
      username: 'cerd-risk@oita-u.ac.jp',
      password: 'Oita-bousai1234',
      authorizedPref: [44, 14, 38],
    },
    {
      id: 3,
      username: 'prein-ehime@zynas.co.jp',
      password: 'Ehime2023',
      authorizedPref: [38],
    },
    {
      id: 4,
      username: 'corporateUser',
      password: 'Mw3W2mkt',
      authorizedPref: [40, 41, 42, 43, 44, 45, 46, 47],
    },
    {
      id: 5,
      username: 'prein-miyazaki@inspiration-plus.com',
      password: 'k3JfC7Gv',
      authorizedPref: [45],
    },
    {
      id: 6,
      username: 'prein-oita@inspiration-plus.com',
      password: 'wE294Bbv',
      authorizedPref: [44],
    },
    {
      id: 8,
      username: 'prein-shizuoka-city01@inspiration-plus.com',
      password: 'Shizuoka2024#01',
      authorizedPref: [44],
    },
    {
      id: 9,
      username: 'prein-shizuoka-city02@inspiration-plus.com',
      password: 'Shizuoka2024#02',
      authorizedPref: [44],
    },
    {
      id: 10,
      username: 'prein-shizuoka-city03@inspiration-plus.com',
      password: 'Shizuoka2024#03',
      authorizedPref: [44],
    },
    {
      id: 11,
      username: 'prein-fukuoka-demo01@inspiration-plus.com',
      password: 'fukuoka-demo01',
      authorizedPref: [40],
    },
    {
      id: 12,
      username: 'prein-fukuoka-demo02@inspiration-plus.com',
      password: 'fukuoka-demo02',
      authorizedPref: [40],
    },
    {
      id: 13,
      username: 'prein-fukuoka-demo03@inspiration-plus.com',
      password: 'fukuoka-demo03',
      authorizedPref: [40],
    },
    {
      id: 14,
      username: 'prein-fukuoka-demo04@inspiration-plus.com',
      password: 'fukuoka-demo04',
      authorizedPref: [40],
    },
    {
      id: 15,
      username: 'prein-yamaguchi@inspiration-plus.com',
      password: 'yamaguchi@2024',
      authorizedPref: [35],
    }
  ];

  let realFetch = window.fetch;
  window.fetch = function (url, opts) {
    return new Promise((resolve, reject) => {
      // wrap in timeout to simulate server api call
      setTimeout(() => {

        // authorization
        if (url.endsWith('/users/authenticate') && opts.method === 'POST') {
          // get parameters from post request
          let params = JSON.parse(opts.body);

          // find if any user matches login credentials
          let filteredUsers = users.filter(user => {
            return user.username === params.username && user.password === params.password;
          });

          if (filteredUsers.length) {
            // if login details are valid return user details
            let user = filteredUsers[0];
            let responseJson = {
              id: user.id,
              usename: user.username,
              authorizedPref: user.authorizedPref,
              firstName: user.firstName,
              lastName: user.lastName
            };
            resolve({ ok: true, text: () => Promise.resolve(JSON.stringify(responseJson)) });
          } else {
            // else return error
            reject('Username or password is incorrect');
          }

          return;
        }

        // get users
        if (url.endsWith('/users') && opts.method === 'GET') {
          // check for fake auth token in header and return users if valid, this security
          // is implemented server side in a real application
          if (opts.headers && opts.headers.Authorization === `Basic ${window.btoa('test:test')}`) {
            resolve({ ok: true, text: () => Promise.resolve(JSON.stringify(users)) });
          } else {
            // return 401 not authorization if token is null or invalid
            resolve({ status: 401, text: () => Promise.resolve() });
          }

          return;

        }

        // pass through any requests not handled above
        realFetch(url, opts).then(response => resolve(response));
      }, 500);
    });
  }
}
