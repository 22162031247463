import React, { useEffect } from 'react'
import styled from 'styled-components'

// Atomic Components
import JMAAlert from '../../atoms/jmaalert/index.jsx'
import Title from '../../atoms/title/index.jsx'

// original function
import { getJMAAlertColor } from '../../func/getcolor.jsx'
import { GetDataIntervalFiveMin, GetData } from '../../func/get_data_swr.js';
import { ShortStrMMDDHHmm, dateToStrYYYYMMDDHHmmss, strYYYYMMDDHHmmToMDHmm } from '../../func/datetoview.jsx'


/**
 * トップ画面(気象警報)
 * @param {int} prfCode 都道府県コード
 * @param {string} disasterId 災害ID
 * @param {string} checkpoint チェックポイント時間
 * @returns {JSX.Element}
 */
export default function Top(props) {

    // 県の自治体リストを取得
    const result_area = GetData(`/jma_area_pref/?pref_code=${props.prfCode}`);
    const areaData = result_area.data
    const areaLoading = result_area.loading
    const areaError = result_area.error

    // 指定時刻に発令された警報情報を取得
    const time = new Date()
    let url_checkpoint = dateToStrYYYYMMDDHHmmss(time)
    if (props.checkpoint !== undefined && props.checkpoint !== null && props.checkpoint !== '') {
        url_checkpoint = props.checkpoint
    }
    let url = `/jma/alert/?pref_code=${props.prfCode}&checkpoint_date=${url_checkpoint}`
    if (props.isArchive) {
        // 災害アーカイブの場合
        url = `/arc/jma/alert/?pref_code=${props.prfCode}&checkpoint_date=${url_checkpoint}`
    }
    const { data, loading, error } = GetDataIntervalFiveMin(url);

    useEffect(() => {
        if (!loading && !error && !areaLoading && !areaError) {
            props.setLastUpdateTime(strYYYYMMDDHHmmToMDHmm(props.checkpoint))
        }
    }, [data])

    if (loading || error || areaLoading || areaError) {
        console.log(error)
        return (<Wrapper />)
    }

    // 自治体別の警報情報リストを作成する
    function areaAlertList() {
        let alert_objList = []
        let area_obj = {}
        let alert_obj = {}
        let result_array = []
        console.log(result_array)

        areaData.map((area) => {
            area_obj = {
                'area_name': area.txt_area_name,
            }
            alert_objList = []
            const areaAlertList = data.filter(
                item => item.jma_area_code === area.jma_area_code)
            if (areaAlertList.length > 0) {
                // カラーコード重要度順にソート
                const sortList = areaAlertList.sort(function(a, b){
                    if (a.sint_color_code > b.sint_color_code) return -1;
                    if (a.sint_color_code < b.sint_color_code) return 1;
                    return 0;
                  });
                  sortList.map((item) => {
                    alert_obj = {
                        'alert_name': item.txt_alert_name,
                        'alert_color_code': getJMAAlertColor(item.sint_color_code),
                        'announce_time': ShortStrMMDDHHmm(item.tsm_j_announce_time)
                    }
                    alert_objList.push(alert_obj)
                })
            } else {
                alert_obj = {
                    'alert_name': '発表なし',
                    'announce_time': '',
                    'alert_color_code': 'bg-none'
                }
                alert_objList.push(alert_obj)
            }
            area_obj.alert = alert_objList;
            result_array.push(area_obj)
        })
        console.log(result_array)
        return result_array
    }
    return (
        <Wrapper>
            <Title text='発表されている警報・注意報' />
            <ContentsWrapper>
                <AlertListWrapper>
                    <Table stickyHeader aria-label='jma alert table'>
                        <TableHead>
                            <HeadRow>
                                <TableElm width={'150px'}>
                                    <TableColumns>市町村名</TableColumns>
                                </TableElm>
                                <TableElmLine>
                                    <TableColumns>気象警報</TableColumns>
                                </TableElmLine>
                            </HeadRow>
                        </TableHead>
                        <TableBody>
                            {areaAlertList().map((area, i) => (
                                <tr key={i}>
                                    <TableElm>
                                        <ElmWrapper>{area.area_name}</ElmWrapper>
                                    </TableElm>
                                    <AlertElm>
                                        {area.alert.map((item) => {
                                                return (
                                                    < JMAAlert
                                                        alertname={item.alert_name}
                                                        subtitle={item.announce_time}
                                                        colorclass={item.alert_color_code}
                                                    />
                                                )
                                        })}
                                    </AlertElm>
                                </tr>
                            ))}
                        </TableBody>
                    </Table>
                </AlertListWrapper>
            </ContentsWrapper>
        </Wrapper>
    )
}

const Table = styled.table`
    border-spacing: 0;
    width: 100%;
`
// const HeadTableCell = styled.div`
//     background-color: rgb(21, 36, 81);
// `
const TableHead = styled.thead`
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
`
const TableElm = styled.th`
    border-bottom: thin ridge #6ab0d1;
    padding: 0.25rem 1rem;
    text-align: left;
    width: ${(props) => props.width && props.width};
`
const TableElmLine = styled(TableElm)`
    border-left: thin ridge #6ab0d1;
`
const HeadRow = styled.tr`
    background-color: rgb(21, 36, 81);
`
const TableColumns = styled.div`
    color: white;
    font-weight: bold;
    font-size: 20px;
`
const TableBody = styled.tbody`
`

const Wrapper = styled.div`
    margin: 5dvh 5vw;
    flex-direction: column;
    height: 100%;
    display: flex;
`
const ContentsWrapper = styled.div`
    display: flex;
    height: 100%;
`
const AlertListWrapper = styled.div`
    overflow-y: scroll;
    border-radius: 5px;
    width: 100%;
    height: calc(100% - 15dvh);
    margin-right: 1rem;
`
const TitleWrapper = styled.div`
    font-weight: bold;
    font-size: 36px;
    color: white;
    margin: 1rem 0;
`
const ElmWrapper = styled.div`
    font-weight: bold;
    color: white;
`
const AlertElm = styled(TableElm)`
    display: flex;
    border-left: thin ridge #6ab0d1;
    padding-top: 0;
    padding-bottom: 10px;
    flex-wrap: wrap;
`
