import React from 'react'

/**
 * ログイン画面
 * @returns {JSX.Element} ヘッダーなし画面テンプレート
 */
import NoHeader from '../../templates/noheader'

export default function Login(props) {
    return (<NoHeader
        setPrfCode={props.setPrfCode}
        // TODO　後日削除予定
        // EDiSONからの遷移の場合、ログイン時の遷移先をアーカイブに固定するための仮の対応
        isFromEdison={props.isFromEdison}
    />)
}
