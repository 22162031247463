import React from 'react'
import { Link } from 'react-router-dom'
import { HeaderBtnBox, ChoosedHeaderBtnBox } from '../../style/header'

/**
 * クリックしたら遷移を伴うボタン
 * @param {string} text ボタンに表示する文字
 * @param {string} url 遷移先URL
 * @param {bool} isChoosed 選択中か否か
 * @returns 
 */
export default function LinkBtn(props){
    const { text, url, isChoosed} = props

    return(
        <>
        {isChoosed ?
          <ChoosedHeaderBtnBox >
            <Link to={url}>{text}</Link>
          </ChoosedHeaderBtnBox>
          :
          <HeaderBtnBox >
            <Link to={url}>{text}</Link>
          </HeaderBtnBox>
  
        }
      </>
    )
}
