/**
 * 雨量ベクタータイルの色設定
 * @param {number} value カラークラス値
 * @returns {string} RGB
 */
const getRainStyle = (value) => {
    if(value === 0){
        return 'rgb(242, 242, 255)'
    }
    else if(value === 1){
        return 'rgb(160, 210, 255)'
    }
    else if(value === 2){
        return 'rgb(33, 140, 255)'
    }
    else if(value === 3){
        return 'rgb(0, 65, 255)'
    }
    else if(value === 4){
        return 'rgb(250, 245, 0)'
    }
    else if(value === 5){
        return 'rgb(255, 153, 0)'
    }
    else if(value === 6){
        return 'rgb(255, 40, 0)'
    }
    else if(value === 7){
        return 'rgb(180, 0, 104)'
    }
    return 'rgb(0, 0, 0, 0)'
}

/**
 * 流域平均流出量ベクタータイルの色設定
 * @param {number} value カラークラス値
 * @returns {string} RGB
 */
const getOfStyle = (value) => {
    if( value === 0){
        return 'rgb(160, 210, 255)'
    }
    else if(value === 1){
        return 'rgb(0, 65, 255)'
    }
    else if(value === 2){
        return 'rgb(250, 245, 0)'
    }
    else if(value === 3){
        return 'rgb(255, 153, 0)'
    }
    else if(value === 4){
        return 'rgb(255, 40, 0)'
    }
    else if(value === 5){
        return 'rgb(180, 0, 104)'
    }
    return 'rgb(0, 0, 0, 0)'
}

/**
 * 土壌雨量指数ベクタータイルの色設定
 * @param {number} value カラークラス値
 * @returns {string} RGB
 */
const getSrStyle = (value) => {
    if(value === 0){
      return 'rgb(250, 245, 0)'
    }
    else if(value === 1){
      return 'rgb(255, 40, 0)'
    }
    else if(value === 2){
      return 'rgb(180, 0, 104)'
    }
    else if(value === 3){
      return 'rgba(10, 4, 18, 0.5)'
    }
    return 'rgb(0, 0, 0, 0)'
  }
  
/**
 * 土砂災害発生リスクベクタータイルの色設定
 * @param {number} value カラークラス値
 * @returns {string} RGB
 */
const getRskStyle = (value) => {
    if(value === 0){
        return 'rgb(250, 245, 0)'
    }
    else if(value === 1){
        return 'rgb(255, 153, 0)'
    }
    else if(value === 2){
        return 'rgb(255, 40, 0)'
    }
    else if(value === 3){
        return 'rgb(180, 0, 104)'
    }
    return "rgb(0, 0, 0, 0)"
}

/**
 * タイルのスタイルを設定する関数
 * @param {number} value カラークラス値
 * @param {string} category タイルの種類名
 * @returns {func}
 */
export const getVectorTileStyles = (value, category) => {
    if(category === 'rsk_msh' || category === 'rfs_msh' || category === 'p44_chr'){
        return getRskStyle(value)
    }
    else if(category === 'of_bsn'){
        return getOfStyle(value)
    }
    else if(category === 'sr_msh'){
        return getSrStyle(value)
    }
    else{
        return getRainStyle(value)
    }
}

/**
 * テレメータの色を取得する
 * @param {number} value 水位
 * @returns {string} 16進数色コード
 */
export const getTelVectorStyle = (value) => {
    const StyleArray = [
        '#4d4d4e',
        '#f2f2ff',
        '#40b2e8',
        '#3aab68',
        '#faf600',
        '#ff2600',
        '#b40069',
        '#0a0412'
    ]
    return StyleArray[value]
}
