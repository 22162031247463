import React from 'react'
import styled from 'styled-components'
import axios from 'axios'
import useSWR from 'swr'

import { config } from '../../config.jsx'
// import Selecter from '../../atoms/selecter'
import { StyleSelecter, StyleMenuItem } from '../../style/select'

const fetcher = url => axios.get(url).then(res => res.data)

const SelectWrapper = styled.div`
    width: 200px;
`

/**
 * 都道府県一覧プルダウン
 * @param {int} prfCode 都道府県コード
 * @param {func} setPrfCode 選択した都道府県コードを更新する関数 
 * @returns {JSX.Element}
 */
export default function PrfSelector(props) {
    const { prfCode, setPrfCode } = props
    // ユーザー情報取得
    const userData = JSON.parse(localStorage.getItem('user'))
    const authorizedPref = userData ? userData['authorizedPref'] : []

    const { data, error } = useSWR(
        () => `${config.restUrl}/col/loc/prf/list/`,
        fetcher,
        {
            onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
                if (retryCount >= 2) return
                // 5秒後に再試行
                setTimeout(() => revalidate({ retryCount: retryCount + 1 }), 50000)
            }
        }
    )

    const isLoading = !data && !error

    if (isLoading || error) {
        return (<React.Fragment />)
    }

    // 見ることができる都道府県一覧にフィルタしてプルダウン用に加工する
    const filterData = data.results.filter(e => authorizedPref.includes(e.prf_code))
    const pullDownArray = filterData.map((
        { prf_code: category, prf_name: name }) => ({ category, name })
    )

    // 見ることができる都道府県が１以下なら非表示
    if (pullDownArray.length < 2) {
        return (<React.Fragment />)
    }
    // プルダウンが変更されたときに発火する関数
    const handleChange = (event) => {
        axios.get(config.restUrl + `/col/loc/latlng/${event.target.value}/`)
        .then(response => {
            console.log(' /col/loc/latlng/', response)
            props.setLatlng([response.data.results.latitude, response.data.results.longitude])
        })
        .catch(err => {
            console.log(err);
        })
        setPrfCode(event.target.value)
    }

    return (
        <SelectWrapper>
            <StyleSelecter
                MenuProps={{
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: "left"
                    },
                    PaperProps: {
                        sx: {
                            backgroundColor: "#152451",
                        }
                    }
                }}
                value={props.prfCode}
                name='index'
                onChange={handleChange}
            >
                {pullDownArray.map((elem, i) =>
                    <StyleMenuItem value={elem.category}>{elem.name}</StyleMenuItem>
                )}
            </StyleSelecter>
        </SelectWrapper>
    )
}
