import React from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'

// Contents Component
import RiskHeader from '../../contents/riskheader'
import ShelterMapView from '../../contents/sheltermapview'

const AppWrapper = styled.div`
    display: flex;
    width: 100vw;
    height: calc(100dvh - 48px);
    flex-direction: column;
`

const ContentsWrapper = styled.div`
    display: flex;
    padding: 0 0 1rem 0;
    overflow-y: hidden;
    height: 100%;
`

/**
 * 避難所マップテンプレート
 * @returns {JSX.Element}
 */
export default function ShelMapDashboard(props){
    // // 災害IDと都道府県コード，チェックポイント時間があればパラメータから受け取る
    // const {disaster_id, prf_code, checkpoint} = useParams()

    // if(disaster_id && prf_code && checkpoint){
    //     <React.Fragment>
    //         <RiskHeader 
    //                 prfCode={prf_code} 
    //                 disasterId={disaster_id}
    //                 checkpoint={checkpoint}
    //                 isArchive={true}
    //                 updatePrfCode={props.updatePrfCode} 
    //             />
    //         <AppWrapper>
    //             <ContentsWrapper>
    //                 <ShelterMapView />
    //             </ContentsWrapper>
    //         </AppWrapper>
    //     </React.Fragment>
    // }

    return(
        // <React.Fragment>
        //     <RiskHeader prfCode={props.prfCode} updatePrfCode={props.updatePrfCode} />
            <AppWrapper>
                <ContentsWrapper>
                    <ShelterMapView />
                </ContentsWrapper>
            </AppWrapper>
        // </React.Fragment>
    )
}
