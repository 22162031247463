import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

// Item Component
import LoadingMeter from '../../items/loadingmeter'
import ErrorMsg from '../../atoms/errormsg/index.jsx'
import TitleH6Left from '../../atoms/title_h6_left'

// Contents component
import MsList from '../mslist'
import MeterSlider from '../meterslider'

import { GetDataIntervalFiveMin } from '../../func/get_data_swr';
import { JSTToMMDDHHmm, strUTCYYYYMMDDHHmmToJSTYYYYMMDDHHmm } from '../../func/datetoview'
import { padding } from '@mui/system'

/**
 * 水位スライダーを構成するコンポーネント
 * @param {number} bsnCode 水系コード
 * @param {string} bsnName 水系名
 * @param {int} prfCode 都道府県コード
 * @param {string} checkpoint チェックポイント時間
 * @returns {JSX.Element}
 */
export default function MsSliderView(props) {

    // スライダーでどの時間を選択しているか
    const [chooseDate, setChooseDate] = useState('')

    // チェックポイントがある場合、チェックポイントはUTCなのでJSTの文字列に変換してAPIコール
    let base_point_date = undefined
    if (props.checkpoint !== undefined) {
        base_point_date = strUTCYYYYMMDDHHmmToJSTYYYYMMDDHHmm(props.checkpoint)
    }
    let url = `/wtl/slider/date/${props.prfCode}/${base_point_date}/`

    const { data, loading, error } = GetDataIntervalFiveMin(url);

    // // // データが取得できたかを確認
    // // const isData = !loading && !error

    useEffect(() => {
        if (!loading && !error && data !== undefined && data.newest_date !== undefined) {
            props.setLastUpdateTime(JSTToMMDDHHmm(data.newest_date))
        }
    }, [data])

    // Loading
    if (loading) {
        return (
            <React.Fragment>
                <LoadingWrapper>読み込み中</LoadingWrapper>
                <TitleH6Left
                    text={`${props.bsnName}水系水位(パーセント)`}
                    pl='5vw' />
                <ListWrapper>
                    {
                        [...Array(15)].map((elem, i) => (
                            <LoadingMeter key={`loading-mtr-${i}`} />
                        ))
                    }
                </ListWrapper>
            </React.Fragment>
        )
    }

    if (error) {
        return (
            <>
                <LoadingWrapper>
                    データが見つかりませんでした
                </LoadingWrapper>
                <ErrorListWrapper>
                    <ErrorMsg msg={'データが見つかりませんでした'} />
                </ErrorListWrapper>
            </>
        )
    }

    return (
        <React.Fragment>
            <MeterSlider
                prfCode={props.prfCode}
                setChooseDate={setChooseDate}
                data={data}
            />
            <TitleH6Left
                text={`${props.bsnName}水系水位(パーセント)`}
                pl='5vw' />
            <MsList
                bsnCode={props.bsnCode}
                bsnName={props.bsnName}
                chooseDate={chooseDate}
                basePointDate={data.newest_date}
                prfCode={props.prfCode}
                isArchive={props.isArchive}
            />
        </React.Fragment>
    )
}

const LoadingWrapper = styled.div`
    width: 100vw;
    height: 174px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 36px;
    color: white;
`

const ListWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 1rem 5vw;
    overflow-y: scroll;
    &&& .meter{
        margin: 0.3rem;
        padding: 0.5rem;
        min-width: 180px;
        max-width: 240px;
        min-height: 82px;
    }
`

const ErrorListWrapper = styled(ListWrapper)`
    height: 100%;
`
