import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

// Atomic component
import LegendElem from '../../atoms/legendelem'

MapLegend.propTypes = {
    // 凡例のタイトル
    title: PropTypes.string,
    // 凡例リスト 色とテキスト
    labels: PropTypes.array,
    // クロノロジー表示するユーザーか
    isChrUser: PropTypes.bool,
}

const LegendWrapper = styled.div`
    z-index: 500;
    position: absolute;
    right: 0.5rem;
    bottom: 1.5rem;
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    background-color: #061027c4;
    color: white;
    border-radius: 4px;
`
const LegendTitle = styled.div`
    font-weight: bold;
    font-size: 16px;
`
const LegendTable = styled.div`
    display: flex;
    flex-direction: column;
`

/**
 * 地図上に表示する凡例
 * @param {string} title 凡例のタイトル
 * @param {array} labels 凡例のテキストと色
 * @param {bool} isChrUser クロノロジーを表示してもいいユーザか否か
 * @returns {JSX.Element}
 */
export default function MapLegend(props){
    const { title, labels, isChrUser } = props

    if(!labels && !title){
        return(
            <React.Fragment />
        )
    }
    return(
        <LegendWrapper>
            <LegendTitle>{title}</LegendTitle>
            <LegendTable>
                {
                    labels.map(item => (
                        <LegendElem
                            color={item.color}
                            text={item.text}
                            radius={item.radius}
                            border={item.border}
                            isCross={item.isCross}
                            isChrUser={isChrUser}
                        />
                    ))
                }
            </LegendTable>
        </LegendWrapper>
    )
}
