import React, { useState, useEffect } from "react";
import { interval } from 'rxjs';
import { Grid, IconButton } from '@mui/material';
import {
  PlayArrow,
  Stop,
  ChevronRight,
  ChevronLeft,
} from '@mui/icons-material'

import {
  BoxMapSlider,
  MapSlider,
  StackMapSlider
} from '../../style/map_components'
import { BoxCenterStart, BoxFlexStart, BoxFlexEnd } from '../../style/box'
import { TypographyBoldNomal } from '../../style/typography'
import {
  MapSliderLincBtn,
  MapSliderChip
} from '../../style/chip'
import { dateToMMDDHHmm, dateToMMDDHHmmSlider } from '../../func/datetoview.jsx'
import { DASHBOARD_URL } from '../../const/general';
import { padding } from "@mui/system";

export default function MapTimeSlider(props) {

  const [isPlay, setIsPlay] = useState(false)
  const [isForward, setIsForward] = useState(false)
  const [isBack, setIsBack] = useState(false)
  const [sliderDateTime, setSliderDateTime] = useState('')
  const [marks, setMarks] = useState([])

  // スライダーの更新間隔は1秒
  const intervals = 1 * 1000

  // アイコンボタンのスタイル
  const ableButtonStyle = { color: '#fff', width: '2rem', height: '2rem' }
  const disableButtonStyle = { color: '#666', width: '2rem', height: '2rem' }

  // スライダー表示のスタイル
  const sliderForecastStyle = () => {
    if (props.is_forecast) {
      return { backgroundColor: '#1f6fa9c4' }
    }
  }

  // 再生・停止
  const handlePlayPause = () => {
    setIsPlay(!isPlay);
  }

  // タイル取得用日付文字列の変更
  const setTileDate = (value) => {
    // スライダー上部のツールチップに日付を表示
    setSliderDateTime(`${dateToMMDDHHmm(props.dateArray[value])}`)
  }

  // タイル取得用日付文字列の変更
  const getDashBoardUrl = () => {
    if (props.disasterId === '') {
      // リアルタイム用
      return `${DASHBOARD_URL.REALTIME}${props.dateArray[props.chooseNum]}`
    } else if (props.disasterId === 'recent') {
      // 直近アーカイブ用
      return `${DASHBOARD_URL.ARC_RECENT}${props.dateArray[props.chooseNum]}`
    } else {
      // 災害アーカイブ用
      return `${DASHBOARD_URL.ARC_DISASTER}${props.dateArray[props.chooseNum]}&disaster_id=${props.disasterId}`
    }
  }

  // マーカー設定
  const setSliderMarks = () => {
    let mark_array = []
    props.dateArray.map((item, i) => {
      if (item === props.newestDate) {
        mark_array.push({
          value: i,
          label: '現在',
        })
      } else {
        mark_array.push({
          value: i,
          label: '',
        })
      }
    })
    setMarks(mark_array)
  }

  const handleChange = (e) => {
    props.updateChooseNum(e.target.value)
  }

  const handleForward = () => {
    props.updateChooseNum(props.chooseNum + 1);
  }

  const handleBack = () => {
    props.updateChooseNum(props.chooseNum - 1);
  }

  // データが更新された時
  useEffect(() => {
    // 日付の更新
    setTileDate(props.chooseNum)
    // マーカー設定
    setSliderMarks()
  }, [props.dateArray])

  // 再生中の挙動
  useEffect(() => {
    if (isPlay) {
      const timeoutId = interval(intervals).subscribe(() => {
        if (props.chooseNum < props.sliderMax) {
          props.updateChooseNum(props.chooseNum + 1);
          setTileDate(props.chooseNum + 1)
        } else {
          props.updateChooseNum(0)
          setTileDate(0)
        }
      })
      return () => {
        timeoutId.unsubscribe()
      }
    }
  }, [isPlay, props.chooseNum])

  // スライダーの位置が動いたときの挙動
  useEffect(() => {
    setTileDate(props.chooseNum)
    // 左右ボタンが端に行っていた場合、誤操作を防ぐために
    if (!isBack && (props.chooseNum !== 0)) setIsBack(true)
    else if (isBack && (props.chooseNum === 0)) setIsBack(false)
    if (!isForward && (props.chooseNum !== props.sliderMax)) setIsForward(true)
    else if (isForward && (props.chooseNum === props.sliderMax)) setIsForward(false)
  }, [props.chooseNum])

  return (
    <StackMapSlider sx={sliderForecastStyle}>
      <Grid container spacing={0} sx={{ padding: '0 16px' }}>
        <Grid item xs={3}>
          {props.is_forecast &&
            <BoxFlexStart>
              <MapSliderChip label='予報' />
            </ BoxFlexStart>
          }
        </Grid>
        <Grid item xs={6}>
          <BoxCenterStart>
            <IconButton sx={{ padding: '0 5px' }} disabled={!isBack} onClick={handleBack}>
              <ChevronLeft sx={isBack ? ableButtonStyle : disableButtonStyle} />
            </IconButton>
            <TypographyBoldNomal>{sliderDateTime}</TypographyBoldNomal>
            <IconButton sx={{ padding: '0 5px' }} disabled={!isForward} onClick={handleForward}>
              <ChevronRight sx={isForward ? ableButtonStyle : disableButtonStyle} />
            </IconButton>
          </BoxCenterStart>
        </Grid>
        <Grid item xs={3}>
          {/* ダッシュボードへのリンクは大分県の限定機能として制限 */}
          {/* EDISON-938 【リスク分析】EDISON-805　のダッシュボードリンクを
          一旦コメントアウトして災害アーカイブ機能リリース 2024/09/18 */}
          {/* {props.prfCode === 44 &&
            <BoxFlexEnd>
              <a target="_blank"
                href={getDashBoardUrl()}>
                <MapSliderLincBtn label='ダッシュボード' />
              </a>
            </BoxFlexEnd>
          } */}
        </Grid>
      </Grid>
      <BoxMapSlider >
        <IconButton sx={{ padding: '0 5px' }} onClick={handlePlayPause}>
          {isPlay ?
            <Stop sx={ableButtonStyle} />
            :
            <PlayArrow sx={ableButtonStyle} />
          }
        </IconButton>
        <MapSlider
          is_forecast={props.is_forecast}
          value={props.chooseNum}
          aria-label="Default"
          valueLabelDisplay="off"
          min={0}
          max={props.sliderMax}
          step={1}
          onChange={handleChange}
          marks={marks}
        />
      </BoxMapSlider>
    </StackMapSlider>
  );
}
