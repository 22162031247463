import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { config } from '../../config.jsx'
import { TypographyNomal } from '../../style/typography';
import { Stack } from '@mui/material'

// Contents Component
import PrfSelector from '../prfselecter/index.jsx'
import PrfName from '../prfname/index.jsx'

// Atomic Components
import DateClock from '../../atoms/dateclock/index.jsx'
import LinkBtn from '../../atoms/linkbtn/index.jsx'
import StatusBadge from '../../atoms/statusbadge/index.jsx'

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    height: 48px;
    width: 90vw;
    padding: 0 5vw;
    border-bottom: thin ridge #15335f;
    justify-content: space-between;
    background-color: ${(props) => props.backColor};
`
const BtnWrapper = styled.div`
    display: flex;
`


ArcHeader.propTypes = {
    // 現在選択している都道府県コード
    prfCode: PropTypes.number,
    // 都道府県を更新する関数
    updatePrfCode: PropTypes.func,
    // アーカイブか否か
    isArchive: PropTypes.bool,
    // 災害ID
    disasterId: PropTypes.string,
    // チェックポイント時間
    checkpoint: PropTypes.string,
}

// メニューボタンリスト
const btnArray = [
    {
        category: 'top',
        text: 'トップ',
        url: '/',
        isOpen: true,
    },
    {
        category: 'risk',
        text: 'リスク分析',
        url: '/risk',
        isOpen: true,
    },
    {
        category: 'river',
        text: '水位分析',
        url: '/river',
        isOpen: true,
    },
    {
        category: 'telmap',
        text: '水位地図',
        url: '/telmap',
        isOpen: true,
    },
    {
        category: 'msriv',
        text: '水位変化',
        url: '/msriv',
        isOpen: true,
    },
    // {
    //     category: 'shelter',
    //     text: '避難所',
    //     url: '/shelter',
    //     isOpen: false,
    // },
    // {
    //     category: 'shelmap',
    //     text: '避難所地図',
    //     url: '/shelmap',
    //     isOpen: false,
    // },
    // {
    //     category: 'evac',
    //     text: '避難情報',
    //     url: '/evac',
    //     isOpen: false,
    // },
    {
        category: 'archive',
        text: 'アーカイブ',
        url: '/archive',
        isOpen: true,
    }
]

// 大分県領域 funcType : prefにアクセスできるIDリスト
const ACCESSIBLE_ID = config['accessibleId']

/**
 * ヘッダー
 * @param {int} prfCode 都道府県コード
 * @param {func} updatePrfCode 選択した都道府県コードを更新する関数 
 * @param {string} disasterId 災害ID
 * @param {string} checkpoint チェックポイント時間
 * @param {bool} isArchive アーカイブか否か
 * @returns {JSX.Element}
 */
export default function ArcHeader(props) {
    const location = useLocation()
    // 災害ID，チェックポイント時間があればパラメータから受け取る
    const { disaster_id, checkpoint } = useParams()

    // ユーザー情報取得
    const userName = JSON.parse(localStorage.getItem('user'))
    const isPrefUser = userName ? ACCESSIBLE_ID.includes(userName['id']) : false

    return (
        <Wrapper
            backColor={'#c00000cc'}
        >
            <BtnWrapper>
                {/*  TODO　後日削除予定
                    EDiSONからの遷移の場合、ヘッダーメニューをアーカイブのみするための仮の対応 */}
                {!props.isFromEdison ?
                    btnArray.map((elem, i) => (
                        (isPrefUser || elem.isOpen) &&
                        <LinkBtn
                            key={i.toString()}
                            text={elem.text === 'アーカイブ' ? 'アーカイブ終了' : elem.text}
                            url={elem.category === 'archive' ? `${elem.url}`
                                : elem.category === 'top' ? `/archive/${disaster_id}/${checkpoint}`
                                    : `${elem.url}/${disaster_id}/${props.prfCode}/${checkpoint}`}
                            isChoosed={
                                elem.category === 'archive' ? elem.url === location.pathname
                                    : elem.category === 'top' ? location.pathname === `/archive/${disaster_id}/${checkpoint}`
                                        : location.pathname === `${elem.url}/${disaster_id}/${props.prfCode}/${checkpoint}`}
                            choosedBackColod={'#f56161cc'}
                        />
                    ))
                    :
                    btnArray.map((elem, i) => {
                        if ((isPrefUser || elem.isOpen)) {
                            if (elem.category !== 'archive') {
                                return (
                                    <LinkBtn
                                        key={i.toString()}
                                        text={elem.text}
                                        url={elem.category === 'top' ? `/archive/${disaster_id}/${checkpoint}`
                                            : `${elem.url}/${disaster_id}/${props.prfCode}/${checkpoint}`}
                                        isChoosed={elem.category === 'top' ? location.pathname === `/archive/${disaster_id}/${checkpoint}`
                                            : location.pathname === `${elem.url}/${disaster_id}/${props.prfCode}/${checkpoint}`}
                                        choosedBackColod={'#f56161cc'}
                                    />)
                            } else {
                                return (
                                    <LinkBtn
                                        key={i.toString()}
                                        text='アーカイブ終了'
                                        url='/from_edison/archive'
                                        isChoosed={false}
                                        choosedBackColod={'#f56161cc'}
                                    />)
                            }
                        }
                    })
                }
                {!props.isFromEdison &&
                    <LinkBtn
                        key={'logout'}
                        text={'ログアウト'}
                        url={'/login'}
                        isChoosed={false}
                        choosedBackColod={'#f56161cc'}
                    />
                }
            </BtnWrapper>
            {!props.isFromEdison &&
                <PrfName prfCode={props.prfCode} addString={'アーカイブ'} />
            }
            {/* <DateClock /> */}
            {props.lastUpdateTime !== null && props.lastUpdateTime !== '' &&
                <Stack>
                    < TypographyNomal >アーカイブ日時</TypographyNomal>
                    < TypographyNomal >{props.lastUpdateTime}</TypographyNomal>
                </Stack>
            }
        </Wrapper>
    )
}
