import React from 'react';
import {createRoot} from 'react-dom/client';
import './index.css';
import Apps from './App';
import configureFakeBackend from './_basicauth/_helpers/fake-backend'

configureFakeBackend()

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<Apps />)

// ReactDOM.render(
//     <React.StrictMode>
//     </React.StrictMode>,
//     document.getElementById('root')
// );
