import React from 'react'

import EvacDashboard from '../../templates/evacdashboard'

/**
 * 避難情報画面
 * @returns {JSX.Element} 避難所テンプレート
 */
export default function Evac(props){
    // // ユーザが選択する都道府県の設定(入れないと動かない)
    // // App.jsxに入れるとログイン前に遷移するため動かない
    // const userData = JSON.parse(localStorage.getItem('user'))
    // if(!props.prfCode || !userData['authorizedPref'].includes(props.prfCode)){
    //     props.updatePrfCode(userData['authorizedPref'][0])
    // }
    return(<EvacDashboard />)
}
