import React from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'

// Contents Component
import Top from '../../contents/top'

/**
 * トップ画面テンプレート
 * @param {int} prfCode 都道府県コード
 * @param {func} updatePrfCode 選択した都道府県コードを更新する関数 
 * @returns {JSX.Element}
 */
export default function TopPage(props) {
    // 災害ID，チェックポイント時間があればパラメータから受け取る
    const { disaster_id, checkpoint } = useParams()
    // 災害IDがあれば災害アーカイブと判定
    const isArchive = disaster_id !== undefined && disaster_id !== null
        && disaster_id !== '' && disaster_id !== 'recent'

    return (
        <ContentsWrapper>
            <LeftContents>
                <Top
                    prfCode={props.prfCode}
                    setLastUpdateTime={props.setLastUpdateTime}
                    disasterId={disaster_id}
                    checkpoint={checkpoint}
                    isArchive={isArchive}
                />
            </LeftContents>
            <RightContents />
        </ContentsWrapper>
    )
}
const ContentsWrapper = styled.div`
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
`
const LeftContents = styled.div`
    width: 100vw;
    height: calc(100dvh - 64px);
    display: flex;
    flex-direction: column;
    justify-content: center;
`
const RightContents = styled.div`
    width: 0vw;
`
