import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
    MapContainer,
    ZoomControl,
    TileLayer,
    useMapEvents,
    LayersControl,
    Marker
} from 'react-leaflet'
import { CRS } from 'leaflet'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit'
import IconButton from '@mui/material/IconButton'

// Item Component
import MapLegend from '../../items/maplegend'

// Atom Component
import TelVector from '../../atoms/telvector'
import { svgCircleIcon } from '../../atoms/svgcircleicon'
import MapToolTip from '../../atoms/maptooltip'

// Original function
import {getMapLegendData} from '../../func/getmaplegenddata'
import { getTelVectorStyle } from '../../func/getvectortilestyles'

RiverMap.propTypes = {
    // 選択している緯度緯度
    latlng: PropTypes.array,
    // 選択している日付
    chooseDate : PropTypes.number,
    // テレメータのいち情報が入る配列
    pointsData : PropTypes.array,
    // 最初のズームレベル
    firstZoomLebel : PropTypes.number,
    // 表示するベクタータイルのリスト
    vectorlist : PropTypes.array,
    // 選択中のテレメータを変更する
    updateChooseMtr : PropTypes.func,
}

const baseStyles = {
    zIndex : 2
};

/// Map Events Handler
/**
 * マップイベントハンドラー
 * @param {func} updateCurrentZoomLevel ズームレベル変更関数 
 * @returns 
 */
function MyMapEventHandler({updateCurrentZoomLevel}){
    const map = useMapEvents({
        zoomend: (e) => {
            updateCurrentZoomLevel(map.getZoom())
        }
    })
    return null
}

/**
 * 河川水位計マップ
 * @param {array} latlng 選択している緯度経度
 * @param {number} chooseDate 選択している日付
 * @param {array} pointsData テレメータのマッピングデータ
 * @param {number} firstZoomLebel マップのデフォルトズームレベル
 * @param {array} vectorlist 表示するベクタータイルリスト
 * @param {func} updateChooseMtr 選択中のテレメータを変更する関数 
 * @returns {JSX.Element}
 */
export default function RiverMap(props){
    const {
        latlng,
        chooseDate,
        pointsData,
        firstZoomLebel,
        updateChooseMtr
    } = props

    // Mapのstateを宣言
    const [ map, setMap ] = useState(null)
    // Mapのズームレベルをステート管理
    const [currentZoomLevel, setCurrentZoomLevel] = useState(firstZoomLebel)
    // 全画面表示切り替え
    const fullscreenHandle = useFullScreenHandle()

    // Then click for Map full screen button, switching full screen
    function switchingFullScreen(e){
        fullscreenHandle.active ?
        fullscreenHandle.exit() :
        fullscreenHandle.enter()
    }

    // ズームレベルが変化したときにアップデートする関数
    function updateCurrentZoomLevel(e){
        setCurrentZoomLevel(e)
    }

    useEffect(() => {
        if(map && latlng) map.setView(latlng)
    }, [latlng])

    // 最初の位置情報がないときはローディング
    if(!latlng){
        return(
            <StyleFullScreen
                handle={fullscreenHandle}
                enabled={true}
            >
                <MapWrapper>
                    <StyleIconBtn
                        className='fullscreeen-button'
                        aria-label='full screen switch'
                        component='span'
                        onClick={switchingFullScreen}
                    >
                        { fullscreenHandle.active
                            ? <FullscreenExitIcon />
                            : <FullscreenIcon />
                        }
                    </StyleIconBtn>
                </MapWrapper>
            </StyleFullScreen>
        )
    }

    return(
        <StyleFullScreen
            handle={fullscreenHandle}
            enabled={true}
        >
            <MapWrapper>
                <StyleMapContainer
                    center={latlng}
                    zoom={firstZoomLebel}
                    maxZoom={15}
                    crs={CRS.EPSG3857}
                    whenCreated={setMap}
                    zoomControl={false}
                    prefCanvas={true}
                    ref={setMap}
                >
                <ZoomControl position='topleft' />
                <TileLayer
                    attribution='出典：<a href="https://maps.gsi.go.jp/development/ichiran.html">国土地理院</a>'
                    url='https://cyberjapandata.gsi.go.jp/xyz/pale/{z}/{x}/{y}.png'
                    className='baseLayers'
                    style={baseStyles}
                />
                <TelVector
                    chooseDate={chooseDate}
                    category={'tel_riv'}
                    is_river={true}
                    maxZoomSize={12}
                />
                {/* <LayersControl position="topright">

                </LayersControl> */}
                <MyMapEventHandler
                    updateCurrentZoomLevel={updateCurrentZoomLevel}
                />
                {
                    pointsData && pointsData.map((elem, i) => (
                    <Marker
                        key={`marker-${i}`}
                        position={[elem.latitude, elem.longitude]}
                        icon={svgCircleIcon(getTelVectorStyle(elem.colorclass))}
                        eventHandlers={{
                        click: () => {
                            updateChooseMtr(elem.tel_code, 'tel')
                        }
                        }}
                    >
                        <MapToolTip
                        label={`${elem.tel_name} - ${elem.river_name}`}
                        value={elem.value !== null ? elem.value : '欠測'}
                        unit={elem.colorclass === 1 ? 'm' : elem.colorclass === 0 ? '' : '%'}
                        colorclass={elem.colorclass}
                        />
                    </Marker>
                    ))
                }
                <MapLegend {...getMapLegendData('tel')} />
                </StyleMapContainer>
                <StyleIconBtn
                    className='fullscreeen-button'
                    aria-label='full screen switch'
                    component='span'
                    onClick={switchingFullScreen}
                >
                    { fullscreenHandle.active
                        ? <FullscreenExitIcon />
                        : <FullscreenIcon />
                    }
                </StyleIconBtn>
            </MapWrapper>
        </StyleFullScreen>
    )

}


// Mapコンポーネントのラッパー
const MapWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 5px;
`

// Map Overlay Sytle
const StyleFullScreen = styled(FullScreen)`
    width: 100%;
    height: 100%;
    &.fullscreen-enabled>div{
        height: 100%;
    }
`
const StyleMapContainer = styled(MapContainer)`
    width: 100%;
    height: 100%;
    &&& .baseLayers{
        -webkit-filter: grayscale(100%);
        /* filter: invert(100%) grayscale(100%); */
        filter: grayscale(100%);
        z-index: 1 !important;
    }
    &&& .leaflet-control-layers-overlays, .leaflet-control-layers-base{
        text-align: left;
    }
    &&& .leaflet-control-zoom{
        margin-top: 10px !important;
    }
`

// IconButtonのデザイン
const StyleIconBtn = styled(IconButton)`
    &&&.MuiButtonBase-root{
        position: absolute;
        z-index: 500;
        background-color: white;
        border-radius: 5px;
        top: 10px;
        right: 10px;
        border: 2px solid rgba(0, 0, 0, 0.3);
        width: 45px;
        height: 45px;
        padding: 0;
        &:hover{
        background-color: #acb4c5;
        }
    }
    &>svg{
        width: 36px;
        height: 36px;
        color: black;
    }
`
