import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'

// Contents Component
import RiskHeader from '../../contents/riskheader'
import CtvBtn from '../../contents/ctvbtn'
import ShelterBoard from '../../contents/shelterboard'

ShelterDashboard.propTypes = {
    // 市町村コード
    ctvCode: PropTypes.number,
    // 市町村名
    ctvName: PropTypes.string,
    // 市町村情報を更新する
    updateCtvStatus: PropTypes.func,
    // 都道府県コード
    prfCode: PropTypes.number,
    // 都道府県コードを更新する
    updatePrfCode: PropTypes.func,
}

const AppWrapper = styled.div`
    display: flex;
    width: 100vw;
    height: calc(100dvh - 48px);
    flex-direction: column;
`

const ContentsWrapper = styled.div`
    display: flex;
    padding: 0 0 1rem 0;
    overflow-y: hidden;
    height: 100%;
`

/**
 * 避難所テンプレート
 * @param {number} ctvCode 市町村コード
 * @param {string} ctvName 市町村名
 * @param {func} updateCtvStatus 選択中の市町村を更新する 
 * @param {int} prfCode 都道府県コード
 * @param {func} updatePrfCode 選択した都道府県コードを更新する関数 
 * @returns {JSX.Element}
 */
export default function ShelterDashboard(props){
    const { ctvCode, ctvName, updateCtvStatus } = props

    // if(disaster_id && prf_code && checkpoint){
    //     return(
    //         <React.Fragment>
    //             <RiskHeader 
    //                 prfCode={prf_code} 
    //                 disasterId={disaster_id}
    //                 checkpoint={checkpoint}
    //                 isArchive={true}
    //                 updatePrfCode={updatePrfCode} 
    //             />
    //             <AppWrapper>
    //                 <CtvBtn
    //                     ctvCode={ctvCode}
    //                     updateCtvStatus={updateCtvStatus}
    //                 />
    //                 <ContentsWrapper>
    //                 <ShelterBoard
    //                     ctvCode={ctvCode}
    //                     ctvName={ctvName}
    //                 />
    //                 </ContentsWrapper>
    //             </AppWrapper>
    //         </React.Fragment>
    //     )
    // }


    return(
        // <React.Fragment>
        //     <RiskHeader prfCode={prfCode} updatePrfCode={updatePrfCode} />
            <AppWrapper>
                <CtvBtn
                    ctvCode={ctvCode}
                    updateCtvStatus={updateCtvStatus}
                />
                <ContentsWrapper>
                <ShelterBoard
                    ctvCode={ctvCode}
                    ctvName={ctvName}
                />
                </ContentsWrapper>
            </AppWrapper>
        // </React.Fragment>
    )
}
