import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { Grid, Skeleton } from '@mui/material';

import { GetDataIntervalFiveMin } from '../../func/get_data_swr';
import usePrevious from '../../func/usePrevious.js';
import { dateToMMDDHHmm, getSliderdateArray, compareDate } from '../../func/datetoview.jsx'
import { BASE_LAYER_LIST } from '../../const/general'
import { config } from '../../config.jsx'

// Template components
import RiskDashboard from '../../templates/riskdashboard/index.jsx'
import LoadingMeter from '../../items/loadingmeter'
import ErrorMsg from '../../atoms/errormsg'

/**
 * リスク分析画面
 * @param {array} latlng 緯度経度
 * @param {int} prfCode 都道府県コード
 * @param {func} setLatlng 選択した緯度経度を更新する関数
 * @returns {JSX.Element} リスク分析テンプレート
 */
export default function Meter(props) {

    // 現在選択している時間
    const [chooseDate, setChooseDate] = useState('')
    // 選択している時間のインデックス
    const [chooseNum, setChooseNum] = useState(null)
    const prevChooseNum = usePrevious(chooseNum);
    // 選択している時間が予報時間か
    const [isForecast, setIsForecast] = useState(false)
    // ランキングから選択された場所のメッシュコード
    const [selectMshCode, setSelectMshCode] = useState(null)
    // ランキングから選択された水系コード
    const [selectBsnCode, setSelectBsnCode] = useState(null)
    // 選択されたカテゴリー（１時間雨量など）
    const [selectCategory, setSelectCategory] = useState(BASE_LAYER_LIST[0])
    // 河川水位からのリンクのパラメータがある場合，そちらを初期値とする
    const { lat, lng } = useParams()
    // 災害IDと都道府県コード，チェックポイント時間があればパラメータから受け取る
    const { disaster_id, prf_code, checkpoint } = useParams()
    // 災害IDがあれば災害アーカイブと判定
    const isArchive = disaster_id !== undefined && disaster_id !== null
        && disaster_id !== '' && disaster_id !== 'recent'


    useEffect(() => {
        if (lat && lng) {
            // リンクパラメータがある場合は中心座標に設定
            props.setLatlng([lat, lng])
        } else {
            // リンクパラメータがない場合は対象都道府県の座標を中心に設定
            axios.get(config.restUrl + `/col/loc/latlng/${props.prfCode}/`)
                .then(response => {
                    console.log(' /col/loc/latlng/', response)
                    props.setLatlng([response.data.results.latitude, response.data.results.longitude])
                })
                .catch(err => {
                    console.log(err);
                })
        }
    }, [lat, lng])

    // 気象データ最終更新日時取得
    const result_newest_date = GetDataIntervalFiveMin(`/get_newest_date/?pref_code=${props.prfCode}`);
    const newestDateData = result_newest_date.data
    const newestDateLoading = result_newest_date.loading
    const newestDateError = result_newest_date.error

    // const result_area = GetData(`/jma_area_pref/?pref_code=${props.prfCode}`);
    // const areaData = result_area.data
    // const areaLoading = result_area.loading
    // const areaError = result_area.error
    let url = `/rain/slider/date/${props.prfCode}/${checkpoint}/`
    if (isArchive) {
        // 災害アーカイブの場合
        url = `/arc/rain/slider/date/${props.prfCode}/${checkpoint}/`
        // url = `/arc/slider_date/?pref_code=${props.prfCode}&checkpoint_date=${checkpoint}`
    }
    const { data, loading, error } = GetDataIntervalFiveMin(url);

    // データが取得できたかを確認する
    const isLoadingComplete = data && !error && newestDateData && !newestDateError
    // const newestDate = isLoadingComplete ? newestDateData[0].tsm_report_date : ''


    // let newestDate = ''
    // let basePointDate = ''
    // let dateArray = []
    // if (disaster_id !== undefined && disaster_id !== null
    //     && disaster_id !== '' && disaster_id !== 'recent') {
    //     // disaster_id がある場合
    //     newestDate = isLoadingComplete ? newestDateData[0].tsm_report_date : ''
    //     basePointDate = isLoadingComplete ? data[12].tsm_u_date : ''
    //     dateArray = isLoadingComplete ? data.map(item => item.tsm_u_date) : []
    // } else {
    const newestDate = isLoadingComplete ? data.newest_date[0] : ''
    const basePointDate = isLoadingComplete ? data.base_point_date[0] : ''
    const dateArray = isLoadingComplete ? data.result : []
    // }

    const loadingItem = (size) => (
        <>
            {
                [...Array(size)].map((elem, i) => (
                    <LoadingMeter key={`loading-one-${i}`} />
                ))
            }
        </>
    );

    // データが更新されたときに，もともと選択していた時刻で更新を行う
    useEffect(() => {
        if (dateArray.length !== 0) {
            props.setLastUpdateTime(dateToMMDDHHmm(basePointDate))
            updateChooseNum(chooseNum === null ||
                chooseNum < 0 ? dateArray.indexOf(basePointDate) : chooseNum)
        }
    }, [data])

    // 選択された日付が変更されたときに呼び出す関数
    function updateChooseDate(props) {
        if (dateArray.length !== 0) {
            setChooseDate(props)
            setChooseNum(dateArray.indexOf(props))
            // 解析雨量で最新の時間と比較し，インデックスが大きければ予報フラグをtrueに
            if (new Date(newestDate) < new Date(props)) {
                setIsForecast(true)
            }
            else {
                setIsForecast(false)
            }
        }
    }


    // 選択されたインデックスが変わったとき
    function updateChooseNum(props) {
        setChooseNum(props)
        setChooseDate(dateArray[props])

        if (new Date(newestDate) < new Date(dateArray[props])) {
            setIsForecast(true)
        }
        else {
            setIsForecast(false)
        }
    }

    // Loading
    if (loading) {
        return (
            <Grid container>
                <Grid item md={2}>
                    {loadingItem(7)}
                </Grid>
                <Grid item md={8}>
                    {/* <Skeleton variant="rectangular" width="70%" height="70%" sx={{ bgcolor: 'rgb(77, 77, 78)' }} /> */}
                    {/* <Skeleton variant="rectangular" width={500} height={300} sx={{ bgcolor: 'rgb(77, 77, 78)' }} /> */}
                </Grid>
                <Grid item md={2}>
                    {loadingItem(7)}
                </Grid>
            </Grid>
        )
    }
    // Loading
    if (error) {
        return (
            <>
                <ErrorMsg msg={'データ取得に失敗しました'} />
            </>
        )
    }

    console.log('newestDateData')
    console.log(newestDateData)
    console.log('data')
    console.log(data)
    console.log('newestDate')
    console.log(newestDate)
    console.log('basePointDate')
    console.log(basePointDate)
    // console.log('dateArray')
    // console.log(dateArray)
    // console.log('newestDate')
    // console.log(chooseDate)
    // console.log('chooseNum')
    // console.log(chooseNum)

    return (
        <RiskDashboard
            selectCategory={selectCategory}
            setSelectCategory={setSelectCategory}
            selectMshCode={selectMshCode}
            selectBsnCode={selectBsnCode}
            latlng={props.latlng}
            chooseDate={dateArray[chooseNum === null || chooseNum < 0 ? dateArray.indexOf(newestDate) : chooseNum]}
            chooseNum={chooseNum}
            newestDate={newestDate}
            basePointDate={basePointDate}
            dateArray={dateArray}
            isForecast={isForecast}
            setSelectMshCode={setSelectMshCode}
            setSelectBsnCode={setSelectBsnCode}
            setLatlng={props.setLatlng}
            updateChooseDate={updateChooseDate}
            updateChooseNum={updateChooseNum}
            prfCode={props.prfCode}
            isArchive={isArchive}
            prevChooseNum={prevChooseNum}
        />
    )
}
