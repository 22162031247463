import React, { useState, useEffect } from "react";
import {
  useMap,
  useMapEvents,
} from 'react-leaflet'
import * as protomapsL from "protomaps-leaflet";
import { config } from '../../config.jsx'
import { BASE_LAYER_LIST } from '../../const/general'
import { getVectorTileStyles } from '../../func/getvectortilestyles'
import { strAddHoursToPath, dateToStrYYYYMMDDHHmmss, strSubtractHoursToPath } from '../../func/datetoview.jsx'

/**
 * マップ用のイベントハンドラ
 * @param {func} setLatlng 緯度経度を更新する関数
 * @param {func} updateCategories 選択中の種類を変更する関数
 * @param {func} updatePopupLatlng ポップアップの出す場所を変更する関数 
 * @returns null
 */
function MapEventHandler(props) {

  const CHOOSE_NUM_BREAK_POINT_1 = 12
  const CHOOSE_NUM_BREAK_POINT_2 = 24
  const CHOOSE_NUM_BREAK_POINT_3 = 36

  // タイルレイヤー格納用
  const [tileLayer, setTileLayer] = useState(null);

  // マップ定義
  const map = useMap();

  // マップイベント定義
  const mapEvents = useMapEvents({
    click: (e) => {
      props.setLatlng([e.latlng.lat, e.latlng.lng])
      props.setSelectMshCode(null)
    },
    baselayerchange: (e) => {
      let layerName = e.name
      props.setSelectCategory(BASE_LAYER_LIST.find(p => p.name === layerName))
    },
    zoomend: (e) => {
      tileAddMap()
    }
  })

  // スライダー移動による指定時間（choose_num）のみ着色
  const tileColor = (item) => {

    let choose = getArcChoosePoint()
    if (item.props.choose_num === choose) {
      return getVectorTileStyles(
        item.props.sint_value_cls_num,
        props.selectCategory.category)
    } else {
      return "rgb(0, 0, 0, 0)"
    }
  }

  const chkChooseNum = (prevChooseNum, chooseNum) => {

    const newestPoint = props.dateArray.indexOf(props.newestDate)
    const basePoint = props.dateArray.indexOf(props.basePointDate)

    // リアルタイムであれば考慮しなくていい
    if (newestPoint === basePoint) {
      return -1
    }

    const choose_num_before_checkpoint = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
    let choose_num_after_checkpoint1 = []
    let choose_num_after_checkpoint2 = []
    let choose_num_after_checkpoint3 = []
    let choose_num_forecast = []

    if (newestPoint > 0) {
      if (newestPoint > CHOOSE_NUM_BREAK_POINT_3) {
        choose_num_after_checkpoint1 = [13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24]
        choose_num_after_checkpoint2 = [25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36]
        for (let i = CHOOSE_NUM_BREAK_POINT_3 + 1; i < newestPoint; i++) {
          choose_num_after_checkpoint3.push(i)
        }
      } else if (newestPoint > CHOOSE_NUM_BREAK_POINT_2) {
        choose_num_after_checkpoint1 = [13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24]
        for (let i = CHOOSE_NUM_BREAK_POINT_2 + 1; i < newestPoint; i++) {
          choose_num_after_checkpoint2.push(i)
        }
      } else if (newestPoint > CHOOSE_NUM_BREAK_POINT_1) {
        for (let i = CHOOSE_NUM_BREAK_POINT_1 + 1; i < newestPoint; i++) {
          choose_num_after_checkpoint1.push(i)
        }
      }

      for (let i = newestPoint; i < props.dateArray.length; i++) {
        choose_num_forecast.push(i)
      }
    } else {
      choose_num_after_checkpoint1 = [13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24]
      choose_num_after_checkpoint2 = [25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36]
      choose_num_after_checkpoint3 = [37, 38, 39, 40, 41, 42]
    }

    let prevPosition = 0
    if (choose_num_before_checkpoint.includes(prevChooseNum)) {
      prevPosition = 1
    } else if (choose_num_after_checkpoint1.includes(prevChooseNum)) {
      prevPosition = 2
    } else if (choose_num_after_checkpoint2.includes(prevChooseNum)) {
      prevPosition = 3
    } else if (choose_num_after_checkpoint3.includes(prevChooseNum)) {
      prevPosition = 4
    } else if (choose_num_forecast.includes(prevChooseNum)) {
      prevPosition = 5
    }
    let newPosition = 0
    if (choose_num_before_checkpoint.includes(chooseNum)) {
      newPosition = 1
    } else if (choose_num_after_checkpoint1.includes(chooseNum)) {
      newPosition = 2
    } else if (choose_num_after_checkpoint2.includes(chooseNum)) {
      newPosition = 3
    } else if (choose_num_after_checkpoint3.includes(chooseNum)) {
      newPosition = 4
    } else if (choose_num_forecast.includes(chooseNum)) {
      newPosition = 5
    }
    if (prevPosition === newPosition) {
      return -1
    }
    return newPosition
  }

  // 選択カテゴリによるタイルスタイルの指定
  const paintRule = () => {
    if (props.selectCategory.is_river) {
      return RIVER_RULE;
    }
    return RAIN_RULE;
  }
  const getArcChoosePoint = () => {
    const newestPoint = props.dateArray.indexOf(props.newestDate)
    const basePoint = props.dateArray.indexOf(props.basePointDate)
    // console.log('newestPoint')
    // console.log(newestPoint)
    // console.log('basePoint')
    // console.log(basePoint)

    // リアルタイムであればchooseNumを書き換える必要はなし
    if (newestPoint === basePoint) {
      return props.chooseNum
    }
    if (newestPoint > 0) {
      // スライダー上に最終更新日時がある場合
      if (newestPoint <= CHOOSE_NUM_BREAK_POINT_2) {
        // |------------12---最新---24------36---|
        if (props.chooseNum <= CHOOSE_NUM_BREAK_POINT_1) {
          // |------選択------12---最新---24------36---|
          // この時タイルはチェックポイントのタイル
          // chooseNumが基準ポイントである12より小さい場合chooseNumを書き換える必要はなし
          // console.log('|------選択------12---最新---24------36---|')
          // console.log('props.chooseNum')
          // console.log(props.chooseNum)
          return props.chooseNum

        } else {
          // この時タイルはlatestのタイル
          // newestPointはlatestのタイルのchooseNum１２に該当する
          if (props.chooseNum <= newestPoint) {
            // |------------12-選択--最新---24------36---|
            // console.log('|------------12-選択--最新---24------36---|')
            // console.log('props.chooseNum - 12')
            // console.log('props.chooseNum')
            // console.log(props.chooseNum)
            // console.log('newestPoint')
            // console.log(newestPoint)
            // console.log('basePoint')
            // console.log(basePoint)
            // console.log('12-(newestPoint-basePoint)+(props.chooseNum-basePoint)')
            // console.log(12 - (newestPoint - basePoint) + (props.chooseNum - basePoint))
            return 12 - (newestPoint - basePoint) + (props.chooseNum - basePoint)
          } else {
            // |------------12---最新--選択-24------36---|
            const deff = props.chooseNum - newestPoint
            // console.log('|------------12---最新--選択-24------36---|')
            // console.log('12 + deff')
            // console.log(props.chooseNum)
            // console.log('12 + deff')
            // console.log(12 + deff)
            return 12 + deff
            // return ((props.chooseNum - DEFAULT_CHOOSE_NUM) * 2) - def
          }
        }
      }
      if (CHOOSE_NUM_BREAK_POINT_2 < newestPoint && newestPoint <= CHOOSE_NUM_BREAK_POINT_3) {
        // |------------12------24---最新---36---|
        if (props.chooseNum <= CHOOSE_NUM_BREAK_POINT_1) {
          // |------選択------12------24---最新---36---|
          // この時タイルはチェックポイントのタイル
          // chooseNumが基準ポイントである12より小さい場合chooseNumを書き換える必要はなし
          // console.log('|------選択------12------24---最新---36---|')
          // console.log('props.chooseNum')
          // console.log(props.chooseNum)
          return props.chooseNum
        }
        if (CHOOSE_NUM_BREAK_POINT_1 < props.chooseNum && props.chooseNum <= CHOOSE_NUM_BREAK_POINT_2) {
          // |------------12-選択-----24-----最新-36---|
          // この時タイルはチェックポイント+6Hのタイル
          // 現在のchooseNumから基準ポイント分差し引く
          // console.log('|------------12-選択-----24-----最新-36---|')
          // console.log('props.chooseNum - 12')
          // console.log(props.chooseNum)
          return props.chooseNum - CHOOSE_NUM_BREAK_POINT_1
        }
        if (CHOOSE_NUM_BREAK_POINT_2 < props.chooseNum) {
          // この時タイルはlatestのタイル
          // newestPointはlatestのタイルのchooseNum１２に該当する
          if (props.chooseNum <= newestPoint) {
            // |------------12------24--選択---最新-36---|
            // console.log('|------------12------24--選択---最新-36---|')
            // console.log('12-(newestPoint-basePoint)+(props.chooseNum-basePoint)')
            // console.log(12 - (newestPoint - basePoint) + (props.chooseNum - basePoint))
            return 12 - (newestPoint - basePoint) + (props.chooseNum - basePoint)
          } else {
            // |------------12---最新--選択-24------36---|
            const deff = props.chooseNum - newestPoint
            // console.log('|------------12------24---最新--選択-36---|')
            // console.log('12 + deff')
            // console.log(12 + deff)
            return 12 + deff
          }
        }
      }
      if (CHOOSE_NUM_BREAK_POINT_3 < newestPoint) {
        // |------------12------24------36-最新--|
        if (props.chooseNum <= CHOOSE_NUM_BREAK_POINT_1) {
          // |------選択------12------24------36-最新--|
          // この時タイルはチェックポイントのタイル
          // chooseNumが基準ポイントである12より小さい場合chooseNumを書き換える必要はなし
          // console.log('|------選択------12------24------36-最新--|')
          // console.log('props.chooseNum')
          // console.log(props.chooseNum)
          return props.chooseNum
        }
        if (CHOOSE_NUM_BREAK_POINT_1 < props.chooseNum && props.chooseNum <= CHOOSE_NUM_BREAK_POINT_2) {
          // |------------12---選択---24------36-最新--|
          // この時タイルはチェックポイント+6Hのタイル
          // 現在のchooseNumから基準ポイント分差し引く
          // console.log('|------------12---選択---24------36-最新--|')
          // console.log('props.chooseNum-12')
          // console.log(props.chooseNum)
          return props.chooseNum - CHOOSE_NUM_BREAK_POINT_1
        }
        if (CHOOSE_NUM_BREAK_POINT_2 < props.chooseNum && props.chooseNum <= CHOOSE_NUM_BREAK_POINT_3) {
          // |------------12------24-選択-----36--最新-|
          // この時タイルはチェックポイント+12Hのタイル
          // 現在のchooseNumから基準ポイント分差し引く
          // console.log('|------------12------24-選択-----36--最新-|')
          // console.log('props.chooseNum-24')
          // console.log(props.chooseNum)
          return props.chooseNum - CHOOSE_NUM_BREAK_POINT_2
        }
        if (CHOOSE_NUM_BREAK_POINT_3 < props.chooseNum) {

          // この時タイルはlatestのタイル
          // newestPointはlatestのタイルのchooseNum１２に該当する
          if (props.chooseNum <= newestPoint) {
            // |------------12------24------36-選択-最新-|
            // console.log('|------------12------24------36-選択-最新-|')
            // console.log('12-(newestPoint-basePoint)+(props.chooseNum-basePoint)')
            // console.log(12 - (newestPoint - basePoint) + (props.chooseNum - basePoint))
            return 12 - (newestPoint - basePoint) + (props.chooseNum - basePoint)
          } else {
            // |------------12------24------36-最新-選択-|
            const deff = props.chooseNum - newestPoint
            // console.log('|------------12------24------36-最新-選択-|')
            // console.log('12 + deff')
            // console.log(12 + deff)
            return 12 + deff
          }
        }
      }

    } else {
      if (props.chooseNum <= CHOOSE_NUM_BREAK_POINT_1) {
        // |------選択------12------24------36---|
        // chooseNumが基準ポイントである12より小さい場合chooseNumを書き換える必要はなし
        return props.chooseNum

      } else if (CHOOSE_NUM_BREAK_POINT_1 < props.chooseNum && props.chooseNum <= CHOOSE_NUM_BREAK_POINT_2) {
        // |------------12---選択---24------36---|
        // この時タイルはチェックポイント+6Hのタイル
        // 現在のchooseNumから基準ポイント分差し引く
        return props.chooseNum - CHOOSE_NUM_BREAK_POINT_1

      } else if (CHOOSE_NUM_BREAK_POINT_2 < props.chooseNum && props.chooseNum <= CHOOSE_NUM_BREAK_POINT_3) {
        // |------------12------24---選択---36---|
        // この時タイルはチェックポイント+12Hのタイル
        // 現在のchooseNumから基準ポイント分差し引く
        return props.chooseNum - CHOOSE_NUM_BREAK_POINT_2

      } else if (props.chooseNum > CHOOSE_NUM_BREAK_POINT_3) {
        // |------------12------24------36--選択-|
        // この時タイルはチェックポイント+18Hのタイル
        // 現在のchooseNumから基準ポイント分差し引く
        return props.chooseNum - CHOOSE_NUM_BREAK_POINT_3
      }
    }
  }

  const getArcTileBaseKey = () => {

    const LATEST_PATH = 'latest/'
    const ALL_PATH = 'all/'
    const newestPoint = props.dateArray.indexOf(props.newestDate)
    const basePoint = props.dateArray.indexOf(props.basePointDate)

    // リアルタイムであればlatest
    if (newestPoint === basePoint) {
      return `${LATEST_PATH}${dateToStrYYYYMMDDHHmmss(props.newestDate)}`
    }

    if (newestPoint > 0) {
      if (newestPoint <= CHOOSE_NUM_BREAK_POINT_2) {
        // |------選択------12---最新---18------24---|
        if (props.chooseNum <= CHOOSE_NUM_BREAK_POINT_1) {
          // |------------12---最新---18------24---|
          // 選択しているチェックポイントのタイル

          // console.log('all')
          // console.log('props.chooseNum1')
          // console.log(props.chooseNum)
          return `${ALL_PATH}${strAddHoursToPath(props.basePointDate, 0)}`
        } else {
          // |------------12-選択--最新---18------24---|
          // console.log('latest')
          // console.log('props.chooseNum2')
          // console.log(props.chooseNum)
          return `${LATEST_PATH}${dateToStrYYYYMMDDHHmmss(props.newestDate)}`
        }
      }
      if (CHOOSE_NUM_BREAK_POINT_2 < newestPoint && newestPoint <= CHOOSE_NUM_BREAK_POINT_3) {
        // |------------12------18---最新---24---|
        if (props.chooseNum <= CHOOSE_NUM_BREAK_POINT_1) {
          // |------選択------12------18---最新---24---|
          // 選択しているチェックポイントのタイル
          return `${ALL_PATH}${strAddHoursToPath(props.basePointDate, 0)}`
        }
        if (CHOOSE_NUM_BREAK_POINT_1 < props.chooseNum && props.chooseNum <= CHOOSE_NUM_BREAK_POINT_2) {
          // |------------12--選択----18---最新---24---|
          // 選択しているチェックポイントの6時間後のタイル
          return `${ALL_PATH}${strAddHoursToPath(props.basePointDate, 6)}`
        }
        if (CHOOSE_NUM_BREAK_POINT_2 < props.chooseNum) {
          // |------------12------18-選択--最新---24---|
          return `${LATEST_PATH}${dateToStrYYYYMMDDHHmmss(props.newestDate)}`
        }

      }
      if (CHOOSE_NUM_BREAK_POINT_3 < newestPoint) {
        // |------------12------18------24-最新--|
        if (props.chooseNum <= CHOOSE_NUM_BREAK_POINT_1) {
          // |------選択------12------18------24-最新--|
          // 選択しているチェックポイントのタイル
          return `${ALL_PATH}${strAddHoursToPath(props.basePointDate, 0)}`
        }
        if (CHOOSE_NUM_BREAK_POINT_1 < props.chooseNum && props.chooseNum <= CHOOSE_NUM_BREAK_POINT_2) {
          // |------------12---選択---18------24-最新--|
          // 選択しているチェックポイントの6時間後のタイル
          return `${ALL_PATH}${strAddHoursToPath(props.basePointDate, 6)}`
        }
        if (CHOOSE_NUM_BREAK_POINT_2 < props.chooseNum && props.chooseNum <= CHOOSE_NUM_BREAK_POINT_3) {
          // |------------12------18-選択-----24-最新--|
          // 選択しているチェックポイントの12時間後のタイル
          return `${ALL_PATH}${strAddHoursToPath(props.basePointDate, 12)}`
        }
        if (CHOOSE_NUM_BREAK_POINT_3 < props.chooseNum) {
          // |------------12------18-----選択-24-最新--|
          return `${LATEST_PATH}${dateToStrYYYYMMDDHHmmss(props.newestDate)}`
        }
      }

    } else {
      if (props.chooseNum <= CHOOSE_NUM_BREAK_POINT_1) {
        // |------選択------12------18------24---|
        // 選択しているチェックポイントのタイル
        return `${ALL_PATH}${strAddHoursToPath(props.basePointDate, 0)}`
      } else if (CHOOSE_NUM_BREAK_POINT_1 < props.chooseNum && props.chooseNum <= CHOOSE_NUM_BREAK_POINT_2) {
        // |------------12---選択---18------24---|
        // 選択しているチェックポイントの6時間後のタイル
        return `${ALL_PATH}${strAddHoursToPath(props.basePointDate, 6)}`
      } else if (CHOOSE_NUM_BREAK_POINT_2 < props.chooseNum && props.chooseNum <= CHOOSE_NUM_BREAK_POINT_3) {
        // |------------12------18---選択---24---|
        // 選択しているチェックポイントの12時間後のタイル
        return `${ALL_PATH}${strAddHoursToPath(props.basePointDate, 12)}`

      } else if (props.chooseNum > CHOOSE_NUM_BREAK_POINT_3) {
        // |------------12------18------24--選択-|
        // TODO basePointDate+18H と newestDate　の差が7HいないであればLatest
        // 選択しているチェックポイントの18時間後のタイル
        return `${ALL_PATH}${strAddHoursToPath(props.basePointDate, 18)}`
      }
    }
  }

  // タイルを取得してマップに表示
  const tileAddMap = () => {
    // 呼び出し元に応じてS3の参照キーを取得
    let base_key = getArcTileBaseKey()
    if (props.isSimulation) {
      base_key = `simulation/${props.simulationId}/${props.basePointDate}`
    }

    // カテゴリーに応じてS3の参照キーを取得
    let pref_key = ''
    if (props.selectCategory.is_river) {
      pref_key = `/49`
    } else if (props.selectCategory.category !== 'h1_msh') {
      pref_key = `/${props.prfCode}`
    }
    console.log('base_key')
    console.log(base_key)

    const url = `${config.pmtilesUrl}/${base_key}${pref_key}/${props.selectCategory.category}/{z}/{x}/{y}.mvt`
    const layer = protomapsL.leafletLayer({
      url: `${config.pmtilesUrl}/${base_key}${pref_key}/${props.selectCategory.category}/{z}/{x}/{y}.mvt`,
      paintRules: paintRule()
    })

    // if (tileLayer !== undefined && tileLayer !== null) {
      // console.log('removeLayer')
      map.eachLayer(function (lyr) {
        // console.log('111')
        // console.log(lyr)
        if (lyr.options.url && !lyr.options.url.indexOf(config.pmtilesUrl)) {
          // PMTileを全てクリア
          // console.log('222')
          map.removeLayer(lyr)
        }
      })
    // }
    map.addLayer(layer)
    setTileLayer(layer)
  }

  // タイルスタイルのみ更新
  const tileUpdate = () => {
    if (tileLayer !== undefined && tileLayer !== null) {
      tileLayer.paintRules = paintRule()
      tileLayer.rerenderTiles()
    }
  }

  // 河川タイル用のスタイル定義
  const RIVER_RULE = [
    {
      dataLayer: props.selectCategory.category,
      symbolizer: new protomapsL.LineSymbolizer({
        color: (zoom, item) => {
          return tileColor(item)
        }
        , width: (zoom, item) => {
          return zoom / 5 * zoom / 4 * (item.props.sint_value_cls_num + 1) / 6
        }
        , opacity: 0.8
      }),
      minzoom: 0,
      maxzoom: 14,
    },
  ];

  // 雨量タイル用のスタイル定義
  const RAIN_RULE = [
    {
      dataLayer: props.selectCategory.category,
      symbolizer: new protomapsL.PolygonSymbolizer({
        fill: (zoom, item) => {
          return tileColor(item)
        },
        opacity: 0.65
      }),
      minzoom: 0,
      maxzoom: 14,
    },
  ];

  // ベースレイヤー切り替えによるカテゴリ変更、
  useEffect(() => {
    tileAddMap()
  }, [props.selectCategory, props.newestDate, props.prfCode])

  // スライダー移動による時間（choose_num）変更でタイルスタイルのみ変更
  useEffect(() => {
    // prevChooseNum chooseNum がどのポイントに属するか、配列を準備して比較判定する
    if (props.prevChooseNum !== null
      && chkChooseNum(props.prevChooseNum, props.chooseNum) > -1) {
      tileAddMap()
      return
    }
    tileUpdate()
  }, [props.chooseNum])

  // mapの中心を移動
  useEffect(() => {
    map.flyTo(props.latlng)
  }, [props.latlng])

  return null
}
export default MapEventHandler;