import React from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'

// Contents Component
import RiskHeader from '../../contents/riskheader'
import EvacMap from '../../contents/evacmap'

/**
 * 避難所テンプレート
 * @param {int} prfCode 都道府県コード
 * @param {func} updatePrfCode 選択した都道府県コードを更新する関数 
 * @returns {JSX.Element}
 */
export default function EvacDashboard(props){
    // // 災害IDと都道府県コード，チェックポイント時間があればパラメータから受け取る
    // const {disaster_id, prf_code, checkpoint} = useParams()

    // if(disaster_id && prf_code && checkpoint){
    //     return(
    //         <React.Fragment>
    //             <RiskHeader 
    //                 prfCode={prf_code} 
    //                 disasterId={disaster_id}
    //                 checkpoint={checkpoint}
    //                 isArchive={true}
    //                 updatePrfCode={props.updatePrfCode} 
    //             />
    //             <ContentsWrapper>
    //                 <EvacMap />
    //             </ContentsWrapper>
    //         </React.Fragment>
    //     )
    // }

    return(
        // <React.Fragment>
        //     <RiskHeader prfCode={props.prfCode} updatePrfCode={props.updatePrfCode} />
            <ContentsWrapper>
                <EvacMap />
            </ContentsWrapper>
        // </React.Fragment>
    )
}

const ContentsWrapper = styled.div`
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: calc(100dvh - 48px);
`
