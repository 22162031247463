import React, { useState } from 'react'

// Template components
import MsDashboard from '../../templates/msdashboard'

/**
 * 河川水位スライダー画面
 * @param {int} prfCode 都道府県コード
 * @param {func} updatePrfCode 選択した都道府県コードを更新する関数 
 * @returns {JSX.Element} 河川水位スライダーテンプレート
 */
export default function MeterSlider(props){
    // 水系番号
    const [bsnCode, setBsnCode] = useState(890918)
    // 水系名
    const [bsnName, setBsnName] = useState('大野川')

    // 選択している水系の情報を更新する
    function updateBsnStatus(code, name){
        setBsnCode(code)
        setBsnName(name)
    }
    
    return(
        <MsDashboard
            bsnCode={bsnCode}
            bsnName={bsnName}
            updateBsnStatus={updateBsnStatus}
            prfCode={props.prfCode}
            setLastUpdateTime={props.setLastUpdateTime}
        />
    )
}
