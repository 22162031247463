import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'
import { config } from '../../config.jsx'

// Contents Component
import PrfSelector from '../prfselecter'

// Atomic Components
import DateClock from '../../atoms/dateclock'
import LinkBtn from '../../atoms/linkbtn'
import StatusBadge from '../../atoms/statusbadge'
import { TypographyNomal } from '../../style/typography';
import { Stack } from '@mui/material'

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    height: 48px;
    width: 90vw;
    padding: 0 5vw;
    border-bottom: thin ridge #15335f;
    justify-content: space-between;
    background-color: ${(props) => props.backColor};
`
const BtnWrapper = styled.div`
    display: flex;
`

RiskHeader.propTypes = {
    // 現在選択している都道府県コード
    prfCode: PropTypes.number,
    // 都道府県を更新する関数
    setPrfCode: PropTypes.func,
}

// メニューボタンリスト
const btnArray = [
    {
        category: 'top',
        text: 'トップ',
        url: '/',
        isOpen: true,
    },
    {
        category: 'risk',
        text: 'リスク分析',
        url: '/risk',
        isOpen: true,
    },
    {
        category: 'river',
        text: '水位分析',
        url: '/river',
        isOpen: true,
    },
    {
        category: 'telmap',
        text: '水位地図',
        url: '/telmap',
        isOpen: true,
    },
    {
        category: 'msriv',
        text: '水位変化',
        url: '/msriv',
        isOpen: true,
    },
    // {
    //     category: 'shelter',
    //     text: '避難所',
    //     url: '/shelter',
    //     isOpen: false,
    // },
    // {
    //     category: 'shelmap',
    //     text: '避難所地図',
    //     url: '/shelmap',
    //     isOpen: false,
    // },
    // {
    //     category: 'evac',
    //     text: '避難情報',
    //     url: '/evac',
    //     isOpen: false,
    // },
    {
        category: 'archive',
        text: 'アーカイブ',
        url: '/archive',
        isOpen: true,
    }
]

// 大分県領域 funcType : prefにアクセスできるIDリスト
const ACCESSIBLE_ID = config['accessibleId']

/**
 * ヘッダー
 * @param {int} prfCode 都道府県コード
 * @param {func} setPrfCode 選択した都道府県コードを更新する関数 
 * @returns {JSX.Element}
 */
export default function RiskHeader(props) {
    const location = useLocation()

    const header = (isLoading, isDisasterRespound) => (
        <Wrapper
            backColor={isDisasterRespound ? '#c00000cc' : '#061027'}
        >
            <BtnWrapper>
                {
                    btnArray.map((elem, i) => (
                        (isPrefUser || elem.isOpen) &&
                        <LinkBtn
                            key={i.toString()}
                            text={elem.text}
                            url={elem.url}
                            isChoosed={elem.url === location.pathname}
                            choosedBackColod={'#223C6D'}
                        />
                    ))
                }
                <LinkBtn
                    key={'logout'}
                    text={'ログアウト'}
                    url={'/login'}
                    isChoosed={false}
                    choosedBackColod={'#223C6D'}
                />
            </BtnWrapper>
            {
                !isLoading &&
                <StatusBadge
                    text={isDisasterRespound ? '災害対応中' : '平常運用中'}
                    color={'white'}
                    size={'20px'}
                />
            }
            <PrfSelector
                prfCode={props.prfCode}
                setPrfCode={props.setPrfCode}
                setLatlng={props.setLatlng}
            />
            {/* <DateClock /> */}
            {props.lastUpdateTime !== null && props.lastUpdateTime !== '' &&
                <Stack>
                    < TypographyNomal >最終更新日時</TypographyNomal>
                    < TypographyNomal >{props.lastUpdateTime}</TypographyNomal>
                </Stack>
            }
        </Wrapper >
    )

    // // 大塚　以下のコメントアウトはEDiSONと統合する際に有効になります。削除しないでください。
    // ユーザー情報取得
    const userName = JSON.parse(localStorage.getItem('user'))
    const isPrefUser = userName ? ACCESSIBLE_ID.includes(userName['id']) : false
    // if (isPrefUser) {
    //     const { data, loading, error } = GetDataIntervalFiveMin(`/api/pref/is/disaster/respound/`);

    //     const isLoading = !data && !error
    //     if (isLoading || error) {
    //         return header(isLoading, false)
    //     }

    //     const isDisasterRespound = data.results
    //     return header(false, isDisasterRespound)
    // } else {
    return header(true, false)
    // }
}
