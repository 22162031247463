import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import axios from 'axios'
import useSWR from 'swr'
import { config } from '../../config.jsx'

// Atom Component
import ColorTitle from '../../atoms/colortitle'
import TitleH6Left from '../../atoms/title_h6_left'

// Item Component
import Meter from '../../items/meter'
import LoadingMeter from '../../items/loadingmeter'

// function
import { JSTToMMDDHHmm } from '../../func/datetoview'

EvacList.propTypes = {
    // 選択中の市町村コード
    ctvCode: PropTypes.number,
    // 選択中の避難所名
    ctvName: PropTypes.string,
}

const fetcher = url => axios.get(url).then(res => res.data)

/**
 * 避難所リスト
 * @param {number} ctvCode 市町村コード
 * @param {string} ctvName 避難所名
 * @returns {JSX.Element}
 */
export default function EvacList(props){
    const { ctvCode, ctvName } = props

    const { data, error } = useSWR(
        () => ctvCode &&
            `${config.restUrl}/pref/evac/detail/${ctvCode}/`,
            fetcher,
            {
                onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
                    // 再試行は2回まで
                    if(retryCount >= 2) return
                    // 5秒後に再試行
                    setTimeout(() => revalidate({ retryCount : retryCount + 1 }), 50000)
                },
                refreshInterval: 300000
            }
    )

    const isLoading = !data && !error

    if(isLoading){
        return(
            <ListWrapper>
                <TitleH6Left text={`${ctvName}発令中避難情報`} />
                {
                    [...Array(6)].map((elem, i) => (
                        <LoadingMeter key={`loading-mtr-${i}`} />
                    ))
                }
                {
                    [...Array(6)].map((elem, i) => (
                        <LoadingMeter key={`loading-mtr-${i}`} />
                    ))
                }
        </ListWrapper>
        )
    }

    // 各避難所レベルのリストを作成
    const list5lv = data.results.filter(elem => elem.alert_level === 5)
    const list4lv = data.results.filter(elem => elem.alert_level === 4)
    const list3lv = data.results.filter(elem => elem.alert_level === 3)

    return(
        <ListWrapper>
                <TitleH6Left text={`${ctvName}発令中避難情報`} />
                {
                list5lv.length !== 0 &&
                <React.Fragment>
                    <ColorTitle
                        text={'緊急安全確保'}
                        colorclass={'bg-purple'}
                    />
                    {
                        list5lv.map((elm, i) => (
                            <Meter
                                key={`evac_5lv_${i}`}
                                headtitle={elm.area_name}
                                subtitle={elm.issu_date ? JSTToMMDDHHmm(elm.issu_date) : ''}
                                value={`${elm.int_hdcnt_num.toLocaleString()}人 - ${elm.int_hhs_num.toLocaleString()}世帯`}
                                colorclass={'bg-purple'}
                                is_btn={false}
                                isChoosed={false}
                                unit={''}
                            />
                        ))
                    }
                </React.Fragment>
            }
            {
                list4lv.length !== 0 &&
                <React.Fragment>
                    <ColorTitle
                        text={'避難指示'}
                        colorclass={'bg-red'}
                    />
                    {
                        list4lv.map((elm, i) => (
                            <Meter
                                key={`evac_4lv_${i}`}
                                headtitle={elm.area_name}
                                subtitle={elm.issu_date ? JSTToMMDDHHmm(elm.issu_date) : ''}
                                value={`${elm.int_hdcnt_num.toLocaleString()}人 - ${elm.int_hhs_num.toLocaleString()}世帯`}
                                colorclass={'bg-red'}
                                is_btn={false}
                                isChoosed={false}
                                unit={''}
                            />
                        ))
                    }
                </React.Fragment>
            }
            {
                list3lv.length !== 0 &&
                <React.Fragment>
                    <ColorTitle
                        text={'高齢者等避難'}
                        colorclass={'bg-yellow'}
                    />
                    {
                        list3lv.map((elm, i) => (
                            <Meter
                                key={`evac_3lv_${i}`}
                                headtitle={elm.area_name}
                                subtitle={elm.issu_date ? JSTToMMDDHHmm(elm.issu_date) : ''}
                                value={`${elm.int_hdcnt_num.toLocaleString()}人 - ${elm.int_hhs_num.toLocaleString()}世帯`}
                                colorclass={'bg-yellow'}
                                is_btn={false}
                                isChoosed={false}
                                unit={''}
                            />
                        ))
                    }
                </React.Fragment>
            }
        </ListWrapper>
    )


}
const ListWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    overflow-y: scroll;
    &&& .meter{
        margin: 0.3rem 0.5rem 0.3rem 0rem;
        justify-content: center;
        padding: 0.5rem;
        min-width: 180px;
        max-width: 450px;
        min-height: 82px;
    }
`
