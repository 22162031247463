import { Button } from '@mui/material';
import { styled } from "@mui/system";
// xs, extra-small: 0px
// sm, small: 600px
// md, medium: 900px
// lg, large: 1200px
// xl, extra-large: 1536px

// キャンセルボタン共通スタイル
export const CancelButton = styled(Button)(({ theme }) => ({
    width: '40%',
}));

// Submitボタン共通スタイル
export const SubmitButton = styled(Button)(({ theme }) => ({
    //width: '40%',
    color: 'white',
    fontSize: '1rem',
    fontWeight: 'bold',
  
    backgroundColor: '#318ab7',
    '&:hover': {
        backgroundColor: '#435e7a'
    }
}));

