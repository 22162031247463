import React from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import useSWR from 'swr'
import { config } from '../../config.jsx'

// Item Component
import AreaGraph from '../../items/areagraph'
import MtrInfo from '../../items/mtrinfo'

// Atom Component
import ErrorMsg from '../../atoms/errormsg'

// user defined function
import { getTickList } from '../../func/getticklist'
import { getGraphLine } from '../../func/getgraphline'

ShelterGraph.propTypes = {
    // 選択中の避難所名
    shelterName : PropTypes.string,
    // 選択中の避難所タイプ
    shetlerType : PropTypes.string,
}

const fetcher = url => axios.get(url).then(res => res.data)

/**
 * 避難所グラフ
 * @param {string} shelterName 避難所名
 * @param {string} shelterType 避難所種類
 * @returns {JSX.Element}
 */
export default function ShelterGraph(props){
    const { shelterName, shelterType } = props

    const { data, error } = useSWR(
        () => shelterName && shelterType &&
            `${config.restUrl}/pref/shelter/graph/${shelterName}/${shelterType}/`,
            fetcher,
            {
                onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
                    // 再試行は5回まで
                    if(retryCount >= 2) return
                    // 5秒後に再試行
                    setTimeout(() => revalidate({ retryCount: retryCount + 1}), 50000)
                },
                refreshInterval: 300000
            }
    )

    // ロード, エラー時の設定
    const isLoading = !data && !error
    // const isData = !isLoading && !error

    if(isLoading){
        return(<React.Fragment />)
    }
    if(error){
        return(<ErrorMsg text={'データが見つかりませんでした'} />)
    }

    // グラフの設定
    const mtrBorder = [
        null, null, null, data.shelter_capacity_num, null
    ]
    const mtrLabel = [
        null, null, null, '最大収容可能人数', null
    ]

    const results = data.results
    // ドメインとグラフのライン設定
    const domains = getTickList(results, mtrBorder, false)
    const graphLines = getGraphLine(mtrBorder)

    return(
        <React.Fragment>
            <MtrInfo
                mtrName={data.shelter_name}
                basName={data.city_name}
                riverName={data.community_name}
                mtrDetail={data.shelter_info}
                mtrLat={data.latitude}
                mtrLng={data.longitude}
                mtrAddress={data.address}
                mtrDiffFlag={data.change_flag}
                mtrValue={data.hdcnt_num}
                colorclass={data.colorclass}
                mtrBorder={mtrBorder}
                mtrUnit={'人'}
                mtrLabel={mtrLabel}
                mtrViewer={`${data.hdcnt_num}人 - ${data.hhs_num}世帯`}
                isEmptyMsg={data.sort_name === '閉鎖' ? '閉鎖' : '避難者なし'}
            />
            <AreaGraph
                data={results}
                domains={domains}
                unit={'人'}
                mejer={'mejer'}
                xaxisKeyList={['date']}
                dataKey={'value'}
                lines={graphLines}
                lineWidth={4}
                category={'tel'}
                borderArray={mtrBorder}
            />
        </React.Fragment>
    )
}
