import L from 'leaflet'

/**
 * 丸いSVGアイコン
 * @param {string} props 塗りつぶしの色設定 
 * @returns {JSX.Element}
 */
export const svgCircleIcon = (props) =>{
    const SvgIcons = L.divIcon({
        html: `
            <svg
                width="50"
                height="50"
                viewBox="0 0 100 100"
                version="1.1"
                preserveAspectRatio="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <circle
                cx="30"
                cy="30"
                r="25"
                fill=${props}
                stroke="white"
                stroke-width="3"
                />
            </svg>
        `,
        className: 'Icons',
        iconAnchor: [10, 10],
    })
    return SvgIcons
}
