import React from 'react'
import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'

LegendElem.propTypes = {
    // アイコンの色
    color: PropTypes.string,
    // 表示する文字
    text: PropTypes.string,
    // 角の設定
    radius: PropTypes.string,
    // 境界線の設定
    border: PropTypes.string,
    // ばってんラインを入れるか
    isCross: PropTypes.bool,
    // クロノロジーを表示するユーザーか
    icChrUser: PropTypes.bool,
}

const Row = styled.div`
    display: flex;
    flex-direction: initial;
    align-items: center;
    padding: 0.25rem;
`
const Icon = styled.i`
    width: 18px;
    height: 18px;
    background-color: ${(props) => props.color};
    border-radius: ${(props) => props.radius};
    border: ${(props) => props.border};
    ${(props) => props.isCross && css`
        & {
        background: linear-gradient(to top right,
            rgba(0,0,0,0) 0%,
            rgba(0,0,0,0) calc(50% - 2px),
            rgba(255,38,0,1) 50%,
            rgba(0,0,0,0) calc(50% + 2px),
            rgba(0,0,0,0) 100%);
        }
    `}
`
const Text = styled.span`
    margin-left: 1rem;
    font-size: 16px;
    letter-spacing: 0.05em;
`

/**
 * 凡例の要素
 * @param {string} color アイコンの色
 * @param {string} text 凡例の文字
 * @param {string} radius 凡例の角の丸み設定
 * @param {string} border 境界線の設定
 * @param {bool} isCross アイコンにばつを入れるか
 * @param {bool} isChrUser クロノロジーを表示してもいいユーザか 
 * @returns {JSX.Element}
 */
export default function LegendElem(props){
    const { color, text, radius, border, isCross, isChrUser } = props
    if(text === 'クロノロジー' && !isChrUser){
        return(<React.Fragment />)
    }
    return(
        <Row>
            <Icon color={color} radius={radius} border={border} isCross={isCross} />
            <Text>{text}</Text>
        </Row>
    )
}
