import { MenuItem,  Select } from '@mui/material';

import { styled } from "@mui/system";

export const StyleSelecter = styled(Select)(() => ({
    fontWeight: 'bold',
    fontSize: '18px',
    color: 'white',
    '&.MuiInputBase-root': {
        backgroundColor: '#152451',
        height: '40px',
        width: '100%',
        border: '2px solid rgba(53, 84, 145, 1)'
    },
    '& .MuiSvgIcon-root': {
        fontSize:'3rem',
        color: 'white'
    },
}));

export const StyleMenuItem = styled(MenuItem)(() => ({
    backgroundColor: '#152451 !important',
    fontWeight: 'bold',
    color: 'white',

    '&:hover': {
        backgroundColor: '#223C6D !important'
    },
    '& .MuiMenuItem-gutters': {
        '&.Mui-selected': {
            backgroundColor: '#152451',
        },
    }
}));

export const ArcMenuItem = styled(MenuItem)`
  &.Mui-selected {
    background-color: #152451;
  }

  &.Mui-selected:hover{ 
    background-color: #223C6D;
  }

  &:hover {
    background-color: #223C6D;
  }
`;