export const config = {

  //// 本番用ドメイン
  apiUrl: 'https://risk.prein.jp',
  restUrl: 'https://risk.prein.jp/api/v1',
  pmtilesUrl: 'https://d1fvo0r01dmjj6.cloudfront.net',

  // //// テスト用ドメイン
  // apiUrl: 'https://d1eqvzqektw8rf.cloudfront.net',
  // restUrl: 'https://d1eqvzqektw8rf.cloudfront.net/api/v1',
  // pmtilesUrl: 'https://d1fvo0r01dmjj6.cloudfront.net',

  accessibleId: [1, 2],
}
