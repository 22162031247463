import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

// Atom Commponent
import SwitchBtn from '../../atoms/switchbtn'
import ErrorMsg from '../../atoms/errormsg'

import { GetData } from '../../func/get_data_swr';

BsnBtn.propTypes = {
    // 水系コード
    bsnCode: PropTypes.number,
    // 都道府県コード
    prfCode: PropTypes.number,
    // 水系情報を更新する
    updateBsnStatus: PropTypes.func,
}

const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0.5rem 5vw;
`

/**
 * 水系選択ボタン
 * @param {number} bsnCode 水系コード
 * @param {int} prfCode 都道府県コード
 * @param {func} updateBsnStatus 選択中の水系情報を変更する関数 
 * @returns {JSX.Element}
 */
export default function BsnBtn(props){
    const { bsnCode, updateBsnStatus, prfCode } = props

    const { data, error } = GetData(`/wtl/bsn/${prfCode}/`);

    const isLoading = !data && !error
    // Loading
    if(isLoading){
        return(<React.Fragment />)
    }

    if(error){
        return(<ErrorMsg text={'データが見つかりませんでした'} />)
    }

    // 流域コードリスト
    const bsnCodeList = data.results.map(function(obj){
        return obj.code
    })

    const onClickBtn = (props) => {
        updateBsnStatus(props[0], props[1])
    }

    if(!bsnCode || !bsnCodeList.includes(bsnCode)){
        updateBsnStatus(data.results[0].code, data.results[0].name)
    }

    return(
        <Wrapper>
            {
                data.results.map((elem, i) => (
                    <SwitchBtn
                        key={elem.code}
                        text={elem.name}
                        isChoosed={elem.code === bsnCode}
                        updateChooseData={onClickBtn}
                        propArray={[elem.code, elem.name]}
                    />
                ))
            }
        </Wrapper>
    )

}
