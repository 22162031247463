import React from 'react'
import PropTypes from 'prop-types'
import Skeleton from '@mui/material/Skeleton';

// Item Component
import AreaGraph from '../../items/areagraph'

// Atom Component
import ErrorMsg from '../../atoms/errormsg'

// user defined function
import { getTickList } from '../../func/getticklist'
import { getGraphLine } from '../../func/getgraphline'
import { GetDataIntervalFiveMin } from '../../func/get_data_swr';

RiverGraph.propTypes = {
    // 選択中の水位計コード
    telCode: PropTypes.number,
    // 選択中の水位計の種類識別子
    mtrCategory: PropTypes.string,
    // 都道府県コード
    prfCode: PropTypes.number,
    // 災害ID
    disasterId: PropTypes.string,
    // チェックポイント時間
    checkpoint: PropTypes.string,
    // アーカイブフラグ
    isArchive: PropTypes.bool,
}


/**
 * 河川水位グラフ
 * @param {number} telCode 水位計コード
 * @param {string} mtrCategory 水位計の種類識別名
 * @param {int} prfCode 都道府県コード
 * @param {string} disasterId 災害ID
 * @param {string} checkpoint チェックポイント時間
 * @param {bool} isArchive アーカイブか否か 
 * @returns {JSX.Element}
 */
export default function RiverGraph(props) {
    const { telCode, mtrCategory, mtrBorder, mtrLabel, basePointDate, isArchive } = props

    let url = `/wtl/graph/${telCode}/${props.basePointDate}/`
    if (props.isArchive) {
        // 災害アーカイブの場合
        url = `/arc/wtl/graph/${props.prfCode}/${telCode}/${props.basePointDate}/`
    }
    // TODO ${disaster_id}がある場合は別途考慮が必要
    const { data, loading, error } = GetDataIntervalFiveMin(url);

    if (loading) {
        return (<Skeleton variant="rectangular" width={'100%'} height={'100%'} sx={{ bgcolor: 'rgb(77, 77, 78)' }} />)
    }
    if (error) {
        return (<ErrorMsg text={'データが見つかりませんでした'} />)
    }

    // ドメインとグラフのライン設定
    const domains = getTickList(data, mtrBorder, null, true)
    const graphLines = getGraphLine(mtrBorder, mtrLabel, 'tel')

    // console.log(data)
    return (
        <AreaGraph
            data={data}
            domains={domains}
            unit={'m'}
            mejer={'mejer'}
            xaxisKeyList={['date']}
            dataKey={'value'}
            lines={graphLines}
            lineWidth={4}
            category={mtrCategory}
            borderArray={mtrBorder}
        />
    )
}
