import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Switch from '@mui/material/Switch'

Toggles.propTypes = {
    // true or false,
    isPast: PropTypes.bool,
    // なんのボタン化を説明する文字
    text: PropTypes.string,
    // switchが変更されたときに発火する関数
    setIsPast: PropTypes.func,
}

const Wrapper = styled.div`
    display: flex;
    margin-bottom: 0.25rem;
    border-radius: 5px;
    background-color: #152451;
`

const SwitchLabel = styled.div`
    color: ${(props) => props.isPast ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.5)'};
    text-align: left;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 0.5rem;
    font-size: 18px;
    font-weight: bold;
`

/**
 * トグルスイッチ
 * @param {bool} isPast true or false
 * @param {string} text ボタンの説明
 * @param {func} setIsPast トグルが押されたときに状態を更新する関数 
 * @returns 
 */
export default function Toggles(props){
    const {isPast, text, setIsPast} = props

    const onChanges = (event) => {
        setIsPast(!isPast)
    }

    return(
        <Wrapper>
            <Switch onChange={onChanges}/>
            <SwitchLabel isPast={isPast}>
                {text}
            </SwitchLabel>
        </Wrapper>
    )
}
