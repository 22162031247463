import React from 'react'

// Template components
import ShelMapDashboard from '../../templates/shelmapdashboard'

/**
 * 避難所マップ画面
 * @param {int} prfCode 都道府県コード
 * @param {func} updatePrfCode 選択した都道府県コードを更新する関数 
 * @returns {JSX.Element} 避難所マップテンプレート
 */
export default function ShelterMap(props){
    // // ユーザが選択する都道府県の設定(入れないと動かない)
    // // App.jsxに入れるとログイン前に遷移するため動かない
    // const userData = JSON.parse(localStorage.getItem('user'))
    // if(!props.prfCode || !userData['authorizedPref'].includes(props.prfCode)){
    //     props.updatePrfCode(userData['authorizedPref'][0])
    // }
    return(
        <ShelMapDashboard prfCode={props.prfCode} />
    )
}
