import React from 'react'

/**
 * 折れ線グラフの点のデザイン
 * @param {*} cx
 * @param {*} cy
 * @param {double} value
 * @param {int} level
 * @returns {JSX.Element}
 */
export default function GraphDot(props){
    const { cx, cy, value, level } = props;
    // 土壌雨量指数の色
    const srColor = ['#318AB7', '#b7b122', '#c02518', '#880e58', '#0a0412'];
    return (
        <circle 
            cx={cx}
            cy={cy}
            r={7} 
            stroke='#fff'
            strokeWidth={1}
            fill={level !== null ? srColor[level] : '#318AB7'} 
        />
    );
}
