import React from 'react'
import styled from 'styled-components'

// Item Component
import Meter from '../../items/meter'
import LoadingMeter from '../../items/loadingmeter'

// Atom Component
import ErrorMsg from '../../atoms/errormsg'

// user defined function
import { get_colorclass_from_class } from '../../func/getcolor'
import { GetDataIntervalFiveMin } from '../../func/get_data_swr';
import { dateToStrYYYYMMDDHHmmss } from '../../func/datetoview'

/**
 * 水位スライダーのリスト
 * @param {number} bsnCode 水系コード
 * @param {string} bsnName 水系名
 * @param {number} chooseDate 選択した日付
 * @param {string} checkpoint チェックポイント時間
 * @returns {JSX.Element}
 */
export default function MsList(props) {

    const { bsnCode, bsnName, chooseDate, basePointDate } = props

    // 基準日を文字列に変換
    const base_point_date = dateToStrYYYYMMDDHHmmss(basePointDate)

    // 水位計情報を取得
    let url = `/wtl/slider/tel/${bsnCode}/${base_point_date}/`
    if (props.isArchive) {
        // 災害アーカイブの場合
        url = `/arc/wtl/slider/tel/${props.prfCode}/${bsnCode}/${base_point_date}/`
    }

    // // TODO ${disaster_id}がある場合は別途考慮が必要
    const { data, loading, error } = GetDataIntervalFiveMin(url);

    // データが取得できたかを確認
    const isData = !loading && !error

    if (loading) {
        return (
            <ListWrapper>
                {
                    [...Array(15)].map((elem, i) => (
                        <LoadingMeter key={`loading-mtr-${i}`} />
                    ))
                }
            </ListWrapper>
        )
    }

    if (error) {
        return (
            <ErrorListWrapper>
                <ErrorMsg msg={'データが見つかりませんでした'} />
            </ErrorListWrapper>
        )
    }

    // if (isData) {
    // データの中から同じ時間のみをフィルター
    const viewData = data.results.filter(elem => elem.bints === chooseDate)

    // console.log('data')
    // console.log(data)
    // console.log('viewData')
    // console.log(viewData)
    return (
        <>
            <ListWrapper>
                {
                    viewData.map((elem, i) =>
                        <Meter
                            key={i}
                            headtitle={`${elem.name} - ${elem.river_name}`}
                            subtitle={elem.dates}
                            value={elem.value !== null ? elem.value : '欠測'}
                            colorclass={get_colorclass_from_class(elem.colorclass)}
                            is_btn={true}
                            isChoosed={false}
                            unit={elem.colorclass === 1 ? 'm' : elem.colorclass === 0 ? '' : '%'}
                            changes={elem.diff_flag}
                        />
                    )
                }
            </ListWrapper>
        </>
    )
    // }

}

const ListWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 0 5vw;
    overflow-y: scroll;
    &&& .meter{
        margin: 0.3rem;
        padding: 0.5rem;
        min-width: 180px;
        max-width: 240px;
        min-height: 82px;
    }
`

const ErrorListWrapper = styled(ListWrapper)`
    height: 100%;
`
