import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import {interval} from 'rxjs'

const Wrapper = styled.div`
    margin: 0 0.5rem;
    color: white;
    text-align: center;
    justify-content: flex-end;
`
const StyleDates = styled.div`
    letter-spacing: 0.1rem;
    font-size: 18px;
    font-variant-numeric: tabular-nums;
`

/**
 * 時計
 * @returns {JSX.Element}
 */
export default function DateClock(){
    const [date, setDate] = useState(new Date())
    useEffect(() => {
        const subscription = interval(1000).subscribe(() => {
                setDate(new Date())
            })
            return () => {
                subscription.unsubscribe()
            }
    }, [])

    const TransformClockJpn = (str) => {
        let dates = new Date(str);
        // let str_year = String(dates.getFullYear());
        let str_month = String(dates.getMonth() + 1);
        let str_date = String(dates.getDate());
        let str_hour = String(dates.getHours()).padStart(2, '0');
        let str_minute = String(dates.getMinutes()).padStart(2, '0');
        let str_second = String(dates.getSeconds()).padStart(2, '0');
        return (str_month + '月' + str_date + '日 ' + str_hour + ' : ' + str_minute + ' : ' + str_second);
    }

    return(
        <Wrapper>
            <StyleDates>{TransformClockJpn(date)}</StyleDates>
        </Wrapper>
    )
}
