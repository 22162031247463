import React from 'react'
import Typography from '@mui/material/Typography';

export default function Title(props) {
    return (
        <Typography
            variant="h4"
            gutterBottom
            sx={{ color: 'white', fontWeight: 'bold', paddingTop:2}}>
            {props.text}
        </Typography>
    )
}
