import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

// Atomic Component
import Card from '../card'
// user defined function
import { dateToMMDDHHmm } from '../../func/datetoview'
import { get_colorclass, get_colorclass_from_mtr } from '../../func/getcolor'

const StyleHead = styled.div`
    font-weight: bold;
    border-bottom: thin solid;
    border-color: rgba(255, 255, 255, 0.75);
`
const StyleValue = styled.div`
    margin-top: 0.5rem;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
`

GraphToolTip.propTypes = {
    active: PropTypes.bool,
    payload: PropTypes.array,
    category: PropTypes.string,
    brdr: PropTypes.array,
}

/**
 * グラフをホバーしたときに表示されるチップ
 * @param {bool} active
 * @param {array} payload
 * @param {*} label
 * @param {string} unit 単位
 * @param {string} category データの種類名
 * @param {array} brdr グラフの基準線リスト
 * @returns {JSX.Element}
 */
export default function GraphToolTip(props){
    const {active, payload, unit, category, brdr} = props
    if(active && payload && payload.length){
        const elem = payload[0].payload
        const head = category === 'tel' || category === 'crs' ?
            elem.date + ' ' + elem.time
            : dateToMMDDHHmm(elem.date)
        const colorclass = category === 'tel' || category === 'crs' ?
            get_colorclass_from_mtr(brdr, elem.value)
            : category === 'sr' ? get_colorclass(elem.level, 'sr_msh')
            : get_colorclass(elem.value, category)
        return(
            <Card
                colorclass={colorclass}
                is_btn={false}
            >
                <StyleHead>
                    {head} {elem.is_predict && '予測'}{elem.is_forecast && '予報'}
                </StyleHead>
                <StyleValue>{elem.value} {unit}</StyleValue>
            </Card>
        )
    }

    return null
}
