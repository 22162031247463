import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { styled as muiStyled } from '@mui/material/styles'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import AppBar from '@mui/material/AppBar'

import GraphControl from '../graphcontrol'
import { BASE_LAYER_LIST } from '../../const/general'

RainGraph.propTypes = {
    // 選択箇所の緯度経度
    latlng: PropTypes.array,
    // 現在選択している都道府県コード
    prfCode: PropTypes.number,
    // 災害ID
    disasterId: PropTypes.string,
    // チェックポイント
    basePointDate: PropTypes.string,
}

const GraphWrapper = styled.div`
    height: calc(40dvh - 35px);
`

const StyleTabs = muiStyled(Tabs)(({ theme }) => ({
    minHeight: '32px',
    height: '32px',
    backgroundColor: '#152451',
    color: '#FFFFFF',
    arignItems: 'center',
    paddingTop:'0.3rem',
}))
const StyleTab = muiStyled(Tab)(({ theme }) => ({
    minHeight: '32px',
    height: '32px',
    backgroundColor: '#152451',
    color: '#FFFFFF',
    arignItems: 'center',
    fontSize: '16px',
    fontWeight: 'bold',
    "&:hover": {
        backgroundColor: '#223C6D',
    },
}))


function allyProps(index) {
    return {
        id: `graph-tap-${index}`,
        'aria-controls': `graph-tap-${index}`,
    };
}

/**
 * 雨量グラフ
 * @param {array} latlng 緯度経度
 * @param {int} prfCode 都道府県コード
 * @param {string} disasterId 災害ID
 * @param {string} checkpoint チェックポイント時間
 * @param {bool} isArchive アーカイブか否か
 * @returns {JSX.Element}
 */
export default function RainGraph(props) {
    const { latlng, prfCode, disasterId = '', basePointDate = '' } = props
    const handleChange = (event, newValue) => {
        props.setSelectCategory(BASE_LAYER_LIST.find(p => p.category === newValue))
    }

    return (
        <GraphWrapper>
            <AppBar position='static'>
                <StyleTabs
                    value={props.selectCategory.category}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable force tabs"
                    TabIndicatorProps={{
                        style: {
                            backgroundColor: "#318AB7"
                        }
                    }}
                >
                    {
                        BASE_LAYER_LIST.map((elem, i) =>
                            <StyleTab
                                key={i.toString()}
                                value={elem.category}
                                label={elem.name}
                                {...allyProps(elem.category)}
                            />
                        )
                    }
                </StyleTabs>
            </AppBar>
            {
                BASE_LAYER_LIST.map((elem, i) =>
                    <GraphControl
                        key={elem.category}
                        msh_code ={props.staticData.msh_code}
                        selectCategory={props.selectCategory}
                        category={elem.category}
                        latlng={latlng}
                        info={elem}
                        prfCode={prfCode}
                        disasterId={disasterId}
                        basePointDate={basePointDate}
                        isArchive={props.isArchive}
                        />
                )
            }
        </GraphWrapper>
    )

}

