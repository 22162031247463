import React from 'react'
import styled from 'styled-components'

// Item component
import Meter from '../../items/meter'
import LoadingMeter from '../../items/loadingmeter'

// Atom component
import MeterTitle from '../../atoms/metertitle'
import ErrorMsg from '../../atoms/errormsg'

// original function
import { dateToMMDDHHmm, dateToStrYYYYMMDDHHmmss } from '../../func/datetoview'
import { get_colorclass } from '../../func/getcolor'
import { GetDataIntervalFiveMin } from '../../func/get_data_swr';

const Wrapper = styled.div`
    position: fixed;
    width: calc(20vw - 1rem);
    overflow-y : scroll;
    height: calc(100% - 48px);
    color: white;
    top: 48px;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar{
        display:none;
    }
`

/**
 * 選択した地点の雨量情報表示
 * @param {array} latlng 緯度経度
 * @param {string} chooseDate 選択されている日付
 * @param {int} prfCode 都道府県コード
 * @param {string} disasterId 災害ID
 * @param {string} basePointDate 基準時間
 * @param {bool} isArchive アーカイブか否か
* @returns {JSX.Element}
 */
export default function MeshMeter(props) {

    // 基準日を文字列に変換
    const base_point_date = dateToStrYYYYMMDDHHmmss(props.basePointDate)

    // 雨量情報を取得する
    let url_msh = `/rain/msh/info/mshcode/${props.prfCode}/${props.staticData.msh_code}/${base_point_date}/`
    if (props.isArchive) {
        // 災害アーカイブの場合
        url_msh = `/arc/rain/msh/info/mshcode/${props.prfCode}/${props.staticData.msh_code}/${base_point_date}/`
    }

    console.log(url_msh)

    const result_msh = GetDataIntervalFiveMin(url_msh);
    const result_data_msh = result_msh.data
    const result_loading_msh = result_msh.loading
    const result_error_msh = result_msh.error
    const isData_msh = !result_loading_msh && !result_error_msh

    // 流域平均雨量情報を取得する
    let url_bsn = ``
    if (props.selectBsnCode !== undefined && props.selectBsnCode !== null && props.selectBsnCode !== 0) {
        url_bsn = `/rain/bsn/info/mshcode/${props.prfCode}/${props.staticData.msh_code}/${props.selectBsnCode}/${base_point_date}/`
        if (props.isArchive) {
            // 災害アーカイブの場合
            url_bsn = `/arc/rain/bsn/info/mshcode/${props.prfCode}/${props.staticData.msh_code}/${props.selectBsnCode}/${base_point_date}/`
        }
    } else {
        url_bsn = `/rain/bsn/info/mshcode/${props.prfCode}/${props.staticData.msh_code}/${base_point_date}/`
        if (props.isArchive) {
            // 災害アーカイブの場合
            url_bsn = `/arc/rain/bsn/info/mshcode/${props.prfCode}/${props.staticData.msh_code}/${base_point_date}/`
        }
    }

    const result_bsn = GetDataIntervalFiveMin(url_bsn);
    const result_data_bsn = result_bsn.data
    const result_loading_bsn = result_bsn.loading
    const result_error_bsn = result_bsn.error
    const isData_bsn = !result_loading_bsn && !result_error_bsn

    // 市町村平均雨量情報を取得する
    let url_ctv = `/rain/ctv/info/mshcode/${props.prfCode}/${props.staticData.msh_code}/${base_point_date}/`
    if (props.isArchive) {
        // 災害アーカイブの場合
        url_ctv = `/arc/rain/ctv/info/mshcode/${props.prfCode}/${props.staticData.msh_code}/${base_point_date}/`
    }

    const result_ctv = GetDataIntervalFiveMin(url_ctv);
    const result_data_ctv = result_ctv.data
    const result_loading_ctv = result_ctv.loading
    const result_error_ctv = result_ctv.error
    const isData_ctv = !result_loading_ctv && !result_error_ctv

    const loadingItem = (size) => (
        <>
            {
                [...Array(size)].map((elem, i) => (
                    <LoadingMeter key={`loading-one-${i}`} />
                ))
            }
        </>
    );

    // Loading
    if (result_loading_msh || result_loading_bsn || result_loading_ctv) {
        return (
            <Wrapper>
                <MeterTitle children={props.staticData.txt_address_name} />
                {loadingItem(5)}

                {
                    props.staticData.bsn_code &&
                    <MeterTitle
                        children={`${props.staticData.txt_river_system_name}水系${props.staticData.txt_river_name}`}
                    />
                }
                {loadingItem(4)}

                <MeterTitle
                    children={`${props.staticData.ctv_name}平均`}
                />
                {loadingItem(4)}

                <MeterTitle children={'交通インフラ'} />
                <Meter
                    headtitle={'鉄道'}
                    subtitle=' '
                    value={props.staticData.txt_rail_name}
                    colorclass={'bg-none'}
                    is_btn={false}
                    unit={''}
                />
                <Meter
                    headtitle={'主要道路'}
                    subtitle=' '
                    value={props.staticData.txt_road_name}
                    colorclass={'bg-none'}
                    is_btn={false}
                    unit={''}
                />
                <MeterTitle
                    children={`更新 : ${dateToMMDDHHmm(props.staticData.newest_date)}`}
                />
            </Wrapper>
        )
    }

    const viewDataMsh = isData_msh && result_data_msh.results.filter(elem => elem.date === props.chooseDate)[0]
    const viewDataBsn = isData_bsn && result_data_bsn.results.filter(elem => elem.date === props.chooseDate)[0]
    const viewDataCtv = isData_ctv && result_data_ctv.results.filter(elem => elem.date === props.chooseDate)[0]

    const subtitleText = viewDataMsh && dateToMMDDHHmm(viewDataMsh.date)
    console.log(result_data_msh.results)
    // console.log(result_data_bsn.results)
    // console.log(result_data_ctv.results)
    // console.log(props.chooseDate)
    // console.log(viewDataMsh)
    // console.log('viewDataBsn')
    // console.log(viewDataBsn)
    // // console.log(viewDataCtv)
    // console.log('bsn_code')
    // console.log(props.staticData)

    return (
        <Wrapper>
            <MeterTitle children={props.staticData.txt_address_name} />
            {viewDataMsh !== undefined &&
                <>
                    <Meter
                        headtitle={'1時間雨量'}
                        subtitle={subtitleText}
                        value={viewDataMsh.h1_msh}
                        colorclass={get_colorclass(viewDataMsh.h1_msh, 'h1')}
                        is_btn={false}
                        unit={'mm/h'}
                    />
                    <Meter
                        headtitle={'24時間雨量'}
                        subtitle={subtitleText}
                        value={viewDataMsh.h24_msh}
                        colorclass={get_colorclass(viewDataMsh.h24_msh, 'h24')}
                        is_btn={false}
                        unit={'mm'}
                    />
                    <Meter
                        headtitle={'48時間雨量'}
                        subtitle={subtitleText}
                        value={viewDataMsh.h48_msh}
                        colorclass={get_colorclass(viewDataMsh.h48_msh, 'h48')}
                        is_btn={false}
                        unit={'mm'}
                    />
                    <Meter
                        headtitle={'土壌雨量指数'}
                        subtitle={subtitleText}
                        value={viewDataMsh.sr_msh}
                        colorclass={get_colorclass(viewDataMsh.sr_level, 'sr_msh')}
                        is_btn={false}
                        unit={''}
                        borderColor={viewDataMsh.sr_level == 4 ? 'bg_white' : null}
                    />
                    <Meter
                        headtitle={'土砂災害発生リスク'}
                        subtitle={subtitleText}
                        value={viewDataMsh.rsk_msh}
                        colorclass={get_colorclass(viewDataMsh.rsk_msh, 'rsk')}
                        is_btn={false}
                        unit={''}
                    />
                </>
            }
            {/*
                viewDataMsh.rfs_msh !== null && <Meter
                headtitle='河川洪水発生リスク'
                subtitle={subtitleText}
                value={viewDataMsh.rfs_msh}
                colorclass={get_colorclass(viewDataMsh.rfs_msh, 'rfs')}
                is_btn={false}
                unit={''}
                />
            */}
            {
                props.staticData.bsn_code && viewDataBsn !== undefined && <React.Fragment>
                    <MeterTitle
                        children={`${props.staticData.txt_river_system_name}水系${props.staticData.txt_river_name}`}
                    />
                    <Meter
                        headtitle={'1時間雨量'}
                        subtitle={subtitleText}
                        value={viewDataBsn.h1_bsn}
                        colorclass={get_colorclass(viewDataBsn.h1_bsn, 'h1')}
                        is_btn={false}
                        unit={'mm/h'}
                    />
                    <Meter
                        headtitle={'24時間雨量'}
                        subtitle={subtitleText}
                        value={viewDataBsn.h24_bsn}
                        colorclass={get_colorclass(viewDataBsn.h24_bsn, 'h24')}
                        is_btn={false}
                        unit={'mm'}
                    />
                    <Meter
                        headtitle={'48時間雨量'}
                        subtitle={subtitleText}
                        value={viewDataBsn.h48_bsn}
                        colorclass={get_colorclass(viewDataBsn.h48_bsn, 'h48')}
                        is_btn={false}
                        unit={'mm'}
                    />
                    <Meter
                        headtitle={'流出量'}
                        subtitle={subtitleText}
                        value={viewDataBsn.of_bsn}
                        colorclass={get_colorclass(viewDataBsn.of_bsn, 'of')}
                        is_btn={false}
                        unit={''}
                    />
                </React.Fragment>
            }
            <MeterTitle
                children={`${props.staticData.ctv_name}平均`}
            />
            {viewDataCtv !== undefined &&
                <>

                    <Meter
                        headtitle={'1時間雨量'}
                        subtitle={subtitleText}
                        value={viewDataCtv.h1_ctv}
                        colorclass={get_colorclass(viewDataCtv.h1_ctv, 'h1')}
                        is_btn={false}
                        unit={'mm/h'}
                    />
                    <Meter
                        headtitle={'24時間雨量'}
                        subtitle={subtitleText}
                        value={viewDataCtv.h24_ctv}
                        colorclass={get_colorclass(viewDataCtv.h24_ctv, 'h24')}
                        is_btn={false}
                        unit={'mm'}
                    />
                    <Meter
                        headtitle={'48時間雨量'}
                        subtitle={subtitleText}
                        value={viewDataCtv.h48_ctv}
                        colorclass={get_colorclass(viewDataCtv.h48_ctv, 'h48')}
                        is_btn={false}
                        unit={'mm'}
                    />
                    <Meter
                        headtitle={'土壌雨量指数'}
                        subtitle={subtitleText}
                        value={viewDataCtv.sr_ctv}
                        colorclass={get_colorclass(viewDataCtv.sr_ctv, 'sr')}
                        is_btn={false}
                        unit={''}
                    />
                </>
            }

            <MeterTitle children={'交通インフラ'} />
            <Meter
                headtitle={'鉄道'}
                subtitle=' '
                value={props.staticData.txt_rail_name}
                colorclass={'bg-none'}
                is_btn={false}
                unit={''}
            />
            <Meter
                headtitle={'主要道路'}
                subtitle=' '
                value={props.staticData.txt_road_name}
                colorclass={'bg-none'}
                is_btn={false}
                unit={''}
            />
            {/* <MeterTitle
                children={`更新 : ${dateToMMDDHHmm(props.staticData.newest_date)}`}
            /> */}
        </Wrapper>
    )

}
