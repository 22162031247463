import React, {useState, useEffect} from 'react';
import {interval} from 'rxjs';
import './404.css'

import RiskHeader from '../../contents/riskheader'

const error_messages =[
    'This system is EDiSON (Earth Disaster Intelligent System and Operational Network).',
    'EDiSON is a system that accumulates and analyzes a wide variety of data to represent the risks of global disasters and provide education.',
    'Among them, the "Risk Assessment" system, which evaluates disaster risks by analyzing weather, geography, economy, and population distribution, is currently under development.',
    'As a result, this system is not available for viewing.',
    'We apologize for the inconvenience and ask for your patience.',
    'In the meantime, in order to protect ourselves from the disasters that may befall us, it is important for us to prepare ourselves for disasters on a regular basis.',
    'Until we meet one day.',
    'By EDiSON developer team.'
];

/**
 * 404ページ
 * @returns {JSX.Element}
 */
function NotFound(){
    const [msg, setMsg] = useState('');
    const [widCount, setWidCount] = useState(0);
    const [lenCount, setLenCount] = useState(0);
    // const [test , setTest] = useState('');
    useEffect(() => {
        const timeoutId = interval(25).subscribe(() => {
            if(lenCount < error_messages.length){
                // console.log(widCount, lenCount);
                if(widCount === error_messages[lenCount].length){
                    setLenCount(lenCount + 1);
                    setWidCount(0);
                    // setBeforeMsg(beforeMsg + error_messages[lenCount]);
                    // console.log(error_messages[lenCount + 1][0]);
                }
                else{
                    setWidCount(widCount + 1);
                    // console.log(error_messages[lenCount][widCount]);
                }
                if(error_messages[lenCount][widCount] !== undefined) setMsg(msg + error_messages[lenCount][widCount])
                else setMsg(msg + '\n');
            }
        });
        return () => {
            timeoutId.unsubscribe();
        }
    }, [msg, widCount])
    return(
        <React.Fragment>
            <RiskHeader />
            <div className='templates_contents'>
                <div className='templates_div'>
                    <div className='templates_msg'>
                        404 Not Found
                    </div>
                </div>
                <div className='terminal_div'>
                    <div className='terminal_msg'>
                        {msg}<span className='terminal-'>_</span>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default NotFound;
