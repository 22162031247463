const COLOR_MAP = {
    'h1':[
        [0.1, 'bg-none'],
        [1, 'bg-b1'],
        [5, 'bg-b2'],
        [10, 'bg-b3'],
        [20, 'bg-b4'],
        [30, 'bg-yellow'],
        [50, 'bg-orange'],
        [80, 'bg-red']
    ],
    'h24':[
        [1, 'bg-none'],
        [50, 'bg-b1'],
        [80, 'bg-b2'],
        [100, 'bg-b3'],
        [150, 'bg-b4'],
        [200, 'bg-yellow'],
        [250, 'bg-orange'],
        [300, 'bg-red']
    ],
    'h48':[
        [1, 'bg-none'],
        [50, 'bg-b1'],
        [100, 'bg-b2'],
        [150, 'bg-b3'],
        [200, 'bg-b4'],
        [250, 'bg-yellow'],
        [300, 'bg-orange'],
        [400, 'bg-red']
    ],
    'sr':[
        [20, 'bg-none'],
        [60, 'bg-b2'],
        [80, 'bg-b4'],
        [120, 'bg-yellow'],
        [150, 'bg-orange'],
        [200, 'bg-red']
    ],
    'rsk':[
        [300.1, 'bg-none'],
        [600, 'bg-yellow'],
        [1000, 'bg-orange'],
        [2000, 'bg-red']
    ],
    'rfs':[
        [50.01, 'bg-none'],
        [75, 'bg-yellow'],
        [100, 'bg-orange'],
        [150, 'bg-red']
    ],
    'of':[
        [0.1, 'bg-none'],
        [1, 'bg-b2'],
        [2, 'bg-b4'],
        [5, 'bg-yellow'],
        [8, 'bg-orange'],
        [15, 'bg-red']
    ]
}
const COLOR_MAP_SR = ['bg-none', 'bg-yellow', 'bg-red', 'bg-purple', 'bg-black']

const COLOR_CLASS_LIST = [
    'bg-none',
    'bg-b1',
    'bg-lsub',
    'bg-green',
    'bg-yellow',
    'bg-red',
    'bg-purple',
    'bg-black'
]

/**
 * 値に応じてカラークラス名を返す
 * @param {number} value 値
 * @param {string} category 値の種類名
 * @returns {string} カラークラス名
 */
export const get_colorclass = (value, category) => {
    if(category !== 'sr_msh') {
        const settings_list = COLOR_MAP[category]
        if(settings_list === undefined) return 'bg-none'
        for(const items of settings_list){
            if(value < items[0]){
                return items[1]
            }
        }
    } else {
        if(COLOR_MAP_SR[value] !== undefined) return COLOR_MAP_SR[value]
    }
    return 'bg-purple'
}


/**
 * カラークラス番号からカラークラス名を返す
 * @param {number} classnames カラークラス番号 
 * @returns {string} カラークラス名
 */
export const get_colorclass_from_class = (classnames) => {
    return COLOR_CLASS_LIST[classnames]
}

/**
 * 河川水位のカラークラスを取得
 * @param {array} brdr 水位ボーダーリスト
 * @param {number} value 水位 
 * @returns {string} カラークラス名
 */
export const get_colorclass_from_mtr = (brdr, value) => {
    if(!value) return COLOR_CLASS_LIST[0]
    else if(brdr.every((elem) => !elem)) return COLOR_CLASS_LIST[1]
    else if(brdr[4] && value >= brdr[4]) return COLOR_CLASS_LIST[7]
    else if(brdr[3] && value >= brdr[3]) return COLOR_CLASS_LIST[6]
    else if(brdr[2] && value >= brdr[2]) return COLOR_CLASS_LIST[5]
    else if(brdr[1] && value >= brdr[1]) return COLOR_CLASS_LIST[4]
    else if(brdr[0] && value >= brdr[0]) return COLOR_CLASS_LIST[3]
    else return COLOR_CLASS_LIST[2]
}

/**
 * カラークラス名からrgbを返す
 * @param {string} props カラークラス名
 * @returns {string} RGB
 */
export const getCardColor = (props) => {
    if (props === 'bg-b1') {
        return `rgb(172, 174, 190)`
    } else if (props === 'bg-b2') {
        return `rgb(114, 152, 191)`
    } else if (props === 'bg-b3') {
        return `rgb(26, 103, 190)`
    } else if (props === 'bg-b4') {
        return `rgb(4, 51, 191)`
    } else if (props === 'bg-lsub') {
        return `rgb(49, 138, 183)`
    } else if (props === 'bg-green') {
        return `rgb(45, 132, 87)`
    } else if (props === 'bg-yellow') {
        return `rgb(183, 177, 34)`
    } else if (props === 'bg-orange') {
        return `rgb(181, 113, 27)`
    } else if (props === 'bg-red') {
        return `rgb(192, 37, 24)`
    } else if (props === 'bg-purple') {
        return `rgb(136, 14, 88)`
    } else if (props === 'bg-black') {
        return `rgb(10, 4, 18)`
    } else {
        return `rgb(110, 110, 111)`
    }
}

/**
 * カラークラス名からrgbを返す
 * @param {string} props カラークラス名
 * @returns {string} RGB
 */
export const getCardBorderColor = (props) => {
    if (props === 'bg-white') {
        return `rgb(256, 256, 256)`
    } else {
        return `rgb(256, 256, 256)`
    }
}

/**
 * 気象警報のカラークラス名を取得する
 * @param {number} props カラークラス番号 
 * @returns {string} カラークラス名
 */
export const getJMAAlertColor = (props) => {
    const JMA_COLOR_LIST = [
        'bg-yellow', 'bg-red', 'bg-purple'
    ]
    return JMA_COLOR_LIST[props]
}

/**
 * 避難情報カラー取得
 * @param {number} props カラークラス番号 
 * @returns {string} カラークラス名
 */
export const getEvacColor = (props) => {
    const EvacColorList = [
        '',
        '',
        'bg-yellow',
        'bg-red',
        'bg-purple'
    ]
    return EvacColorList[props - 1]
}

/**
 * クロノロジーのカラークラス取得
 * @param {number} props カラークラス番号 
 * @returns {string} カラークラス名
 */
export const getChrColor = (props) => {
    const chrColorList = [
        '#faf600',
        '#ff2600',
        '#b40069'
    ]
    return chrColorList[props]
}
