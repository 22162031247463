import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

MeterTitle.propTypes = {
  children: PropTypes.string
}

const StyleMeterTitle = styled.div`
  margin-top: 1rem;
  text-align: center;
  border-bottom: thin ridge;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.05em;
`

/**
 * メーターカードのタイトル
 * @param {string} children タイトルテキスト 
 * @returns {JSX.Element}
 */
export default function MeterTitle(props) {
  const { children } = props

  return(
    <StyleMeterTitle>
      {children}
    </StyleMeterTitle>
  )

}
